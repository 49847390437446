import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import api from '../../services/api';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';

import { Button, Container, Content } from './styles';

interface TagTranslation {
  languageId: number;
  languageName: string;
  text: string;
  translation: string;
  user: string;
  lastUpdate: string;
  tagTranslationId: number;
}

const TagTranslations: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<TagTranslation[]>([]);
  const [tableColumnExtensions] = useState([
    // { columnFilteringEnabled: false },
    { columnName: 'languageId', width: 120 },
    { columnName: 'user', width: 120 },
    { columnName: 'languageName', width: 120 },
    // { columnName: 'translation', width: 300 },
    { columnName: 'lastUpdate', width: 220 },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('tagtranslations').then((response: any) => {
      const logList = response?.data.map((log: TagTranslation) => {
        const aux = log;
        aux.lastUpdate = moment(aux.lastUpdate)?.format('DD.MM.YYYY HH:mm:ss');
        return aux;
      });

      setLogs(logList);
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Categories Translations</h1>

      <Link
        // hidden={state?.isImportTranslations}
        to={{
          pathname: `/tagtranslations/new`,
        }}
      >
        <Button variant="contained" type="button">
          Upload Translations
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={logs}
          checkboxSelection={false}
          hasFilterRow={true}
          tableColumnExtensions={tableColumnExtensions}
          idName={'tagTranslationId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default TagTranslations;
