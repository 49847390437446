import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import FormCustomers from '../Form';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';

interface Customer {
  name: string;
  employee: SelectProps;
  industry: SelectProps;
  sector: SelectProps;
  countries: SelectProps[];
  asset: SelectProps;
}

interface SelectProps {
  value: number;
  label: string;
}

const CreateCustomer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: Customer) => {
      try {
        const company =
          formRef.current?.getFieldRef('company').select?.state?.selectValue[0];

        const sector =
          formRef.current?.getFieldRef('sector').select?.state?.selectValue[0];

        const region =
          formRef.current?.getFieldRef('region').select?.state?.selectValue[0];

        const employee =
          formRef.current?.getFieldRef('employee').select?.state
            ?.selectValue[0];

        const revenue =
          formRef.current?.getFieldRef('revenue').select?.state?.selectValue[0];

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Customer name is required'),
          company: Yup.string().required('Company is required'),
          sector: Yup.string().required('Sector is required'),
          region: Yup.string().required('Region is required'),
          employee: Yup.string().required('Number of employees is required'),
          revenue: Yup.string().required('Revenues is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newCustomer = {
          ...data,
          company,
          sector,
          region,
          employee,
          revenue,
        };

        const response = await api.post('/customers', newCustomer);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The customer was created successfully!',
        });

        history.push('/customers', {
          customerId: response.data.customerId,
          name: response.data.name,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating customer, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <h1>New Customer</h1>
      <FormCustomers
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
      ></FormCustomers>
    </Container>
  );
};
export default CreateCustomer;
