import { FormControl, InputLabel } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import SelectFilter from 'react-select';
import moment from 'moment';
import Button from '../../../components/Button';

import api from '../../../services/api';

import {
  Container,
  Content,
  BoxContent,
  BoxCard,
  BoxRow,
  BoxCol,
  FilterSelect,
} from './styles';
import BackButton from '../../../components/BackButton';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

interface ParamTypes {
  projectId: string;
}

interface Columns {
  name: string;
  title: string;
}

interface Result {
  resultId: number;
  surveyId: number;
  surveyName: string;
  lastUpdate: string;
  // hour: string;
  surveyUserGroup: string;
  progress: string;
  segments: string[];
  accessName: string;
  languageName: string;
}

interface Participation {
  totalPeopleStartedSurvey: number;
  totalPeople30OrMore: number;
  totalPeople0OrMore: number;
  results: Result[];
  file?: FileData[];
}

interface FileData {
  lessThan30: number;
  moreThan30: number;
  total: number;
  surveyName: string;
  surveySubGroup: string;
  segments: string;
  accessName: string;
  accessId: number;
  languageName: string;
}

const columnsExcel: Columns[] = [
  { name: 'accessId', title: 'Access ID' },
  { name: 'accessName', title: 'Link' },
  { name: 'surveyName', title: 'Survey' },
  { name: 'surveySubGroup', title: 'User Groups' },
  { name: 'segments', title: 'Segments' },
  { name: 'languageName', title: 'Language' },
  {
    name: 'lessThan30',
    title: 'Less than 30%',
  },
  { name: 'moreThan30', title: '30 % or more' },
  {
    name: 'total',
    title: 'Total',
  },
];

const ProjectParticipation: React.FC = () => {
  const { projectId } = useParams<ParamTypes>();
  const columns: Columns[] = columnsExcel;

  const [participations, setParticipations] = useState<Participation>();
  const [participationsStore, setParticipationsStore] =
    useState<Participation>();
  const [selectSurveyOptions, setSelectSurveyOptions] = useState<string[]>([]);
  const [selectAccessOptions, setSelectAccessOptions] = useState<string[]>([]);
  const [selectUserGroupOptions, setSelectUserGroupOptions] = useState<
    string[]
  >([]);
  const [selectSegmentsOptions, setSelectSegmentsOptions] = useState<string[]>(
    [],
  );
  const [selectLanguageOptions, setSelectLanguageOptions] = useState<string[]>(
    [],
  );
  const [selectedSurvey, setSelectedSurvey] = useState<string>('all');
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>('all');
  const [selectedSegments, setSelectedSegments] = useState<string>('all');
  const [selectedAccess, setSelectedAccess] = useState<string>('all');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('all');

  const [dataExcel, setDataExcel] = useState<FileData[]>();
  const [title, setTitle] = useState<string>('');

  const [date, setDate] = useState<string>('');

  // get result data
  useEffect(() => {
    api.get(`/results/project/${projectId}`).then(response => {
      const p: Participation = response.data;

      const resultsListAux = p.results.map((obj: any) => {
        return { ...obj, date: new Date(obj.lastUpdate) };
      });

      const sortedDesc = resultsListAux.sort(
        (objA, objB) => objB.date.getTime() - objA.date.getTime(),
      );
      // p.results
      const sortedResults = sortedDesc.map((x: Result) => {
        const y: Result = x;
        y.lastUpdate = new Date(x.lastUpdate).toLocaleString();
        return y;
      });

      const sortedParticipation = { ...p, results: sortedResults };

      setParticipations(sortedParticipation);
      setParticipationsStore(sortedParticipation);
      setTitle(response.data.projectName);

      const dataListExcel: FileData[] =
        sortedParticipation?.file?.map((x: FileData) => {
          return {
            accessId: x.accessId,
            accessName: x.accessName,
            surveyName: x.surveyName,
            surveySubGroup: x.surveySubGroup,
            segments: x.segments,
            languageName: x.languageName,
            lessThan30: x.lessThan30,
            moreThan30: x.moreThan30,
            total: x.total,
          };
        }) ?? [];

      const dateToset = moment().format('DD-MM-YYYY hh:mm:ss');
      setDate(dateToset);

      const sortedDataListExcel = dataListExcel.sort((obj1, obj2) => {
        if (obj1.accessId === obj2.accessId) {
          return obj1.languageName.localeCompare(obj2.languageName);
        }
        return obj1.accessId - obj2.accessId;
      });

      setDataExcel(sortedDataListExcel);
    });
  }, [projectId]);

  // set filter fields data
  useEffect(() => {
    const surveyNameList = participations?.results.flatMap(x => {
      return x.surveyName;
    });
    const uniqueSurveyName = [...new Set(surveyNameList)];
    uniqueSurveyName.push('all');
    setSelectSurveyOptions(uniqueSurveyName);

    const accessNameList = participations?.results.flatMap(x => {
      return x.accessName;
    });
    const uniqueAccessName = [...new Set(accessNameList)];
    uniqueAccessName.sort((a: any, b: any) => (a > b ? 1 : -1));
    uniqueAccessName.push('all');
    uniqueAccessName.filter(item => item !== '');
    setSelectAccessOptions(uniqueAccessName);

    const userGroupList = participations?.results.flatMap(x => {
      return x.surveyUserGroup;
    });
    const uniqueUserGroup = [...new Set(userGroupList)];
    uniqueUserGroup.push('all');
    setSelectUserGroupOptions(uniqueUserGroup);

    const segmentsList = participations?.results.flatMap(x => {
      return x.segments;
    });
    const uniqueSegments = [...new Set(segmentsList)];
    uniqueSegments.push('all');
    setSelectSegmentsOptions(uniqueSegments);

    const languageNameList = participations?.results.flatMap(x => {
      return x.languageName;
    });
    const uniqueLanguage = [...new Set(languageNameList)];
    uniqueLanguage.push('all');
    setSelectLanguageOptions(uniqueLanguage);
  }, [date, participations?.results]);

  // filter results when the user select some filter
  useEffect(() => {
    let resultsFiltered = participationsStore?.results;

    if (selectedSegments !== 'all') {
      resultsFiltered = resultsFiltered?.filter(x =>
        x?.segments?.includes(selectedSegments),
      );
    }

    if (selectedSurvey !== 'all') {
      resultsFiltered = resultsFiltered?.filter(
        x => x?.surveyName === selectedSurvey,
      );
    }

    if (selectedAccess !== 'all') {
      resultsFiltered = resultsFiltered?.filter(
        x => x?.accessName === selectedAccess,
      );
    }

    if (selectedUserGroup !== 'all') {
      resultsFiltered = resultsFiltered?.filter(
        x => x.surveyUserGroup === selectedUserGroup,
      );
    }

    if (selectedLanguage !== 'all') {
      resultsFiltered = resultsFiltered?.filter(
        x => x.languageName === selectedLanguage,
      );
    }

    if (resultsFiltered) {
      const participationfiltered: Participation = {
        // totalResults: resultsFiltered.length,
        totalPeopleStartedSurvey: resultsFiltered.filter(
          x =>
            Number(x.progress.replace(/[^0-9]/g, '')) > 0 &&
            Number(x.progress.replace(/[^0-9]/g, '')) < 30,
        ).length,
        totalPeople30OrMore: resultsFiltered.filter(
          x => Number(x.progress.replace(/[^0-9]/g, '')) >= 30,
        ).length,
        totalPeople0OrMore: resultsFiltered.filter(
          x => Number(x.progress.replace(/[^0-9]/g, '')) > 0,
        ).length,
        results: resultsFiltered,
      };

      setParticipations(participationfiltered);
    }
  }, [
    participationsStore?.results,
    selectedAccess,
    selectedLanguage,
    selectedSegments,
    selectedSurvey,
    selectedUserGroup,
  ]);

  const handleSelectSurvey = useCallback(value => {
    setSelectedSurvey(value.label);
  }, []);

  const handleSelectAccess = useCallback(value => {
    setSelectedAccess(value.label);
  }, []);

  const handleSelectUserGroup = useCallback(value => {
    setSelectedUserGroup(value.label);
  }, []);

  const handleSelectSegments = useCallback(value => {
    setSelectedSegments(value.label);
  }, []);

  const handleSelectLanguage = useCallback(value => {
    setSelectedLanguage(value.label);
  }, []);

  const handleSelectFilters = useCallback(() => {
    setSelectedSurvey('all');
    setSelectedAccess('all');
    setSelectedUserGroup('all');
    setSelectedSegments('all');
    setSelectedLanguage('all');
  }, []);

  const handleDownloadClick = useCallback(() => {
    const dateToset = moment().format('DD-MM-YYYY hh:mm:ss');
    setDate(dateToset);
  }, []);
  return (
    <>
      {title !== '' && <h1 style={{ textAlign: 'center' }}>{title}</h1>}
      {participations && (
        <Container>
          <BackButton url={`/projects/${projectId}/dashboard`} />
          <div>
            <Button
              width="10em"
              height="2.5em"
              marginBottom="0"
              marginTop="10px"
              marginLeft="15px"
              onClick={handleSelectFilters}
            >
              Reset Filters
            </Button>
          </div>
          <Content>
            <FilterSelect>
              <InputLabel style={{ paddingBottom: '10px' }} id="select-survey">
                Survey
              </InputLabel>
              <FormControl fullWidth>
                <SelectFilter
                  labelId="demo-survey"
                  id="demo-simple-select"
                  value={{ value: 0, label: selectedSurvey }}
                  options={selectSurveyOptions
                    .filter(item => item !== '')
                    .map((element, i) => {
                      return {
                        value: i,
                        label: element,
                      };
                    })}
                  onChange={handleSelectSurvey}
                ></SelectFilter>
              </FormControl>
            </FilterSelect>

            <FilterSelect>
              <InputLabel style={{ paddingBottom: '10px' }} id="select-access">
                Link
              </InputLabel>
              <FormControl fullWidth>
                <SelectFilter
                  labelId="demo-access"
                  id="demo-simple-select-access"
                  value={{ value: 0, label: selectedAccess }}
                  onChange={handleSelectAccess}
                  options={selectAccessOptions
                    .filter(item => item !== '')
                    .map((element, i) => {
                      return {
                        value: i,
                        label: element,
                      };
                    })}
                ></SelectFilter>
              </FormControl>
            </FilterSelect>

            <FilterSelect>
              <InputLabel
                style={{ paddingBottom: '10px' }}
                id="select-userGroup"
              >
                UserGroup
              </InputLabel>
              <FormControl fullWidth>
                <SelectFilter
                  id="demo-simple-select-userGroup"
                  value={{ value: 0, label: selectedUserGroup }}
                  onChange={handleSelectUserGroup}
                  options={selectUserGroupOptions
                    .filter(item => item !== '')
                    .map((element, i) => {
                      return {
                        value: i,
                        label: element,
                      };
                    })}
                ></SelectFilter>
              </FormControl>
            </FilterSelect>

            <FilterSelect>
              <InputLabel
                style={{ paddingBottom: '10px' }}
                id="select-segments"
              >
                Segments
              </InputLabel>
              <FormControl fullWidth>
                <SelectFilter
                  id="demo-simple-select-segments"
                  value={{ value: 0, label: selectedSegments }}
                  onChange={handleSelectSegments}
                  options={selectSegmentsOptions
                    .filter(item => item !== '')
                    .map((element, i) => {
                      return {
                        value: i,
                        label: element,
                      };
                    })}
                ></SelectFilter>
              </FormControl>
            </FilterSelect>

            <FilterSelect>
              <InputLabel
                style={{ paddingBottom: '10px' }}
                id="select-languages"
              >
                Language
              </InputLabel>
              <FormControl fullWidth>
                <SelectFilter
                  id="demo-simple-select-languages"
                  value={{ value: 0, label: selectedLanguage }}
                  onChange={handleSelectLanguage}
                  options={selectLanguageOptions
                    .filter(item => item !== '')
                    .map((element, i) => {
                      return {
                        value: i,
                        label: element,
                      };
                    })}
                ></SelectFilter>
              </FormControl>
            </FilterSelect>
          </Content>
          <h1>Participations</h1>
          <Content>
            {/* <BoxCard color={'#E8EFF9'}>
              <h3>{participations?.totalResults}</h3>
              <span>Total results</span>
            </BoxCard> */}

            <BoxCard color={'#FFEFE7'}>
              <h3>{participations?.totalPeopleStartedSurvey}</h3>
              <span>Total people who started the survey</span>
            </BoxCard>

            <BoxCard color={'#fffdd0'}>
              <h3>{participations?.totalPeople30OrMore}</h3>
              <span>Total people with 30% or more</span>
            </BoxCard>

            <BoxCard color={'#EBFDEF'}>
              <h3>{participations?.totalPeople0OrMore}</h3>
              <span>Total</span>
            </BoxCard>
          </Content>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ExcelFile
              element={
                <Button
                  marginRight="35px"
                  width="150px"
                  height="40px"
                  onClick={() => handleDownloadClick()}
                >
                  Download
                </Button>
              }
              filename={`${title} - Participants - ${date}}`}
            >
              <ExcelSheet data={dataExcel} name="participations">
                {columns.map(column => {
                  return (
                    <ExcelColumn
                      key={column.title.trim()}
                      label={column.title.trim()}
                      value={column.name.trim()}
                    />
                  );
                })}
              </ExcelSheet>
            </ExcelFile>
          </div>
          <br />
          <br />

          <h1>Last Respondents</h1>

          <BoxContent>
            <BoxRow>
              <BoxCol>IdResult</BoxCol>
              <BoxCol>Survey</BoxCol>
              <BoxCol>Link</BoxCol>
              <BoxCol>Language</BoxCol>
              <BoxCol>User Group</BoxCol>
              <BoxCol>Segments</BoxCol>
              <BoxCol>Date</BoxCol>
              {/* <BoxCol>Hour</BoxCol> */}
              <BoxCol>Progress</BoxCol>
            </BoxRow>

            {participations?.results
              .filter(x => Number(x.progress.replace(/[^0-9]/g, '')) > 0)
              .slice(0, 10)
              .map(result => (
                <BoxRow key={result.resultId}>
                  <BoxCol>{result.resultId}</BoxCol>
                  <BoxCol>{result.surveyName}</BoxCol>
                  <BoxCol>{result.accessName}</BoxCol>
                  <BoxCol>{result.languageName}</BoxCol>
                  <BoxCol>{result.surveyUserGroup}</BoxCol>
                  <BoxCol>{result.segments}</BoxCol>
                  <BoxCol>{result.lastUpdate}</BoxCol>
                  {/* <BoxCol>{result.hour}</BoxCol> */}
                  <BoxCol>{result.progress}</BoxCol>
                </BoxRow>
              ))}
          </BoxContent>
        </Container>
      )}
    </>
  );
};

export default ProjectParticipation;
