import styled from 'styled-components';
import { Button as Btn } from '@material-ui/core';

export const Container = styled.div`
  /* height: 297mm; */

  .sv-container-modern {
    font-size: 12px;
    width: 260mm;

    .sv-action__content {
      display: none;
    }

    .sv-action-bar
      sv-action-bar--default-size-mode
      sv-footer
      sv-body__footer
      sv-clearfix {
      display: none;
    }

    .btn btn-primary {
      margin-bottom: 50px !important;
    }
    overflow: hidden;
  }

  .sv-page sv-body__page {
    width: 260mm;
    fontsize: 12px;
  }

  width: 260mm;
  fontsize: 12px;
  background: #fff;
  overflow: hidden;

  .sv-action__content {
    display: none;
  }

  margin: 5mm 5mm 5mm 5mm;

  .btn btn-primary {
    margin-bottom: 20px;
  }
`;

export const Content = styled.div``;

export const Button = styled(Btn)`
  margin: 20px 0 15px 15px !important;
  background-color: #00a65a !important;
  color: #fff !important;
`;
