import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import SearchAccess from '../../Accesses/Search';

interface AccessGroup {
  name: string;
  accessIds: number[];
}

const CreateAccessGroup: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [name, setName] = useState<string>();

  const handleSubmit = useCallback(
    async (accessGroup: AccessGroup) => {
      try {
        api.post(`/accessesGroup`, accessGroup).then(response => {
          if (response.data) {
            addToast({
              type: 'success',
              title: 'Success',
              description: 'The group was saved successfully!',
            });

            history.push('/accessGroup');
          }
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing access group, please try  try again',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <h1>New Access Group</h1>

      <h2>Name</h2>
      <input
        name="name"
        type="text"
        defaultValue={name}
        placeholder="Type group name"
        onChange={e => setName(e.target.value)}
        style={{
          width: '100%',
          padding: 5,
          resize: 'none',
        }}
      ></input>
      <h2>Access</h2>

      <SearchAccess
        select={true}
        name={name}
        isEdit={false}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateAccessGroup;
