import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  height?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  shadow?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  width,
  height,
  marginRight,
  marginTop,
  marginLeft,
  marginBottom,
  ...rest
}) => (
  <Container
    type="button"
    width={width}
    height={height}
    marginRight={marginRight}
    marginTop={marginTop}
    marginLeft={marginLeft}
    marginBottom={marginBottom}
    {...rest}
  >
    {children}
  </Container>
);

export default Button;
