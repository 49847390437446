/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { EditOutlined } from '@material-ui/icons';
import { Button, Container, Content, DeleteIcon } from './styles';
import Table from '../../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../../components/DevExpressTable/ActionsColumn';
import BackButton from '../../../components/BackButton';

import { columns } from './columns';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface Logo {
  id: number;
  filePath: string;
}
interface SurveyTranslation {
  surveyTranslationId: number;
  languageId: number;
  surveyId: number;
  name: string;
  translation: any;
  surveyTitle: string;
  surveyName: string;
  logo: Logo;
}

interface State {
  surveyId: number;
  survey: any;
  isLocked: boolean;
}

const SurveyTranslations: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<State>();
  const { addToast } = useToast();
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const [surveyTranslations, setSurveyTranslations] = useState<
    SurveyTranslation[]
  >([]);
  const [removed, setRemoved] = useState(false);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'surveyTranslationId', width: 100 },
    { columnName: 'languageId', width: 150 },
    { columnName: 'languageName', width: 180 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      if (state.isLocked) {
        addToast({
          type: 'error',
          title: 'Survey Locked',
          description: `This survey is locked, changes are not allowed!`,
        });
        return;
      }

      history.push({
        pathname: `/surveys/surveyTranslations/edit/${row.surveyTranslationId}`,
        state: {
          surveyId: state && state?.surveyId,
          survey: state && state?.survey,
          surveyTranslationId: row?.surveyTranslationId,
          languageId: row?.languageId,
        },
      });
    },
    [addToast, history, state],
  );

  const handleClickDelete = useCallback(
    (row: any) => {
      try {
        if (state.isLocked) {
          addToast({
            type: 'error',
            title: 'Survey Locked',
            description: `This survey is locked, changes are not allowed!`,
          });
          return;
        }

        if (
          // eslint-disable-next-line no-restricted-globals, no-alert
          !confirm(
            `Do you really want to delete survey translation for the language: ${row.languageName} ?`,
          )
        ) {
          return;
        }
        api
          .delete(`/surveyTranslations/${row.surveyTranslationId}`)
          .then(async response => {
            const responseSurvey = await api.get(`/surveys/${state?.surveyId}`);
            const surveyJson = responseSurvey.data;

            const survey = {
              ...surveyJson,
              languages: surveyJson?.languages?.filter(
                (item: number) => item != row.languageId,
              ),
            };

            await api.put(`/surveys/${state?.surveyId}`, survey);

            setRemoved(true);
            if (row.surveyTranslationId !== undefined) {
              row?.api?.updateRows([
                {
                  surveyTranslationId: row.surveyTranslationId,
                  _action: 'delete',
                },
              ]);
            }
            addToast({
              type: 'success',
              title: 'Success',
              description: 'The survey translation was deleted successfully!',
            });
          });
      } catch (err) {
        if (err) {
          addToast({
            type: 'error',
            title: 'Updating Error',
            description: `An error occurred while deleting this translaton, please try again.`,
          });
        }
      }
    },
    [addToast, state.isLocked, state?.surveyId],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditOutlined />,
    },
    {
      columnName: 'delete',
      label: '',
      onClick: handleClickDelete,
      icon: <DeleteIcon />,
    },
  ]);

  useEffect(() => {
    if (removed) {
      setRemoved(false);
      api
        .get(`surveyTranslations/translations/${state.surveyId}`)
        .then(response => {
          const surveyTranslationsToList = response.data.map(
            (surveyTranslation: SurveyTranslation) => {
              return {
                ...surveyTranslation,
                surveyTitle: surveyTranslation.translation.title,
                surveyName: surveyTranslation.translation.name,
              };
            },
          );

          setSurveyTranslations(surveyTranslationsToList);
        });
    }
  }, [removed, state.surveyId]);

  useEffect(() => {
    api
      .get(`surveyTranslations/translations/${state.surveyId}`)
      .then(response => {
        const surveyTranslationsToList = response.data.map(
          (surveyTranslation: SurveyTranslation) => {
            return {
              ...surveyTranslation,
              surveyTitle: surveyTranslation.translation.title,
              surveyName: surveyTranslation.translation.name,
            };
          },
        );

        setSurveyTranslations(surveyTranslationsToList);
      });

    if (state.isLocked) {
      addToast({
        type: 'error',
        title: 'Survey Locked',
        description: `This survey is locked, changes are not allowed!`,
      });
    }
  }, [addToast, state.isLocked, state.survey, state.surveyId]);

  return (
    <Container>
      <BackButton url={`/surveys/${state.surveyId}`} />

      <h1>Survey Translations</h1>
      <Link
        hidden={state?.isLocked}
        to={{
          pathname: `/surveys/surveyTranslations/new`,
          state: {
            surveyId: state.surveyId && state.surveyId,
            survey: state.survey && state.survey,
          },
        }}
      >
        <Button variant="contained" type="button" disabled={state.isLocked}>
          Add Survey Translation
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={surveyTranslations}
          checkboxSelection={false}
          multiSelection={true}
          setSelectedRowId={setSelectedRowIds}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'surveyTranslationId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default SurveyTranslations;
