import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div``;

export const LanguageDiv = styled.div`
  display: flex;
`;

export const TranslationArrowDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 6px;
  margin: 0 10px 0 10px;
`;

export const TranslationArrow = styled.span`
  color: #00b698;
  padding: 5px;
  border-radius: 50%;

  font-size: 30px;
`;

export const H2Relative = styled.h2`
  position: relative;
`;

export const OriginalField = styled.div`
  width: 50%;
`;

export const TranslationDiv = styled.div`
  width: 50%;
`;
