import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns } from './columns';

// import BackButton from '../../components/BackButton';
import ButtonEb from '../../components/Button';

import { Container, Content, Button, EditIcon } from './styles';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getEncodedSurvey from '../../utils/getEncodedSurvey';
import updateSurveyTranslations from '../../utils/updateSurveyTranslations';

interface GDPR {
  surveyTextId: string;
  title: string;
  text: string;
  // company: string;
  translations: Translation[];
  textFootnote: string;
  textLinkFootnote: string;
}

interface Translation {
  text: string;
  languageId: number;
  textFootnoteTranslated: string;
  textLinkFootnoteTranslated: string;
}

interface State {
  select: boolean;
  surveyId: number;
  survey: any;
}

const GDPR: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [gdprs, setGDPRSs] = useState<GDPR[]>([]);
  const { state } = useLocation<State>();

  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);

  const { addToast } = useToast();

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'gdprId', width: 120 },
    // { columnName: 'title', width: 280 },
    // { columnName: 'text', width: 280 },
    // { columnName: 'company', width: 280 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/gdpr/edit/${row.gdprId}`);
    },
    [history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditIcon />,
    },
  ]);

  useEffect(() => {
    setLoading(true);

    api.get('gdpr').then(response => {
      setGDPRSs(
        response.data.map((gdpr: GDPR) => {
          return {
            ...gdpr,
            // gdprId: gdpr.gdprId.toString(),
          };
        }),
      );

      if (state?.survey.gdprId) setSelectedRowIds([state.survey.gdprId]);
    });

    setLoading(false);
  }, [state]);

  const handleConfirm = useCallback(async () => {
    try {
      const surveyJson = state?.survey;

      const survey = {
        ...surveyJson,
        gdprId: selectedRowIds[0],
      };

      const encodedSurveyToSave = getEncodedSurvey(JSON.stringify(survey));

      const response = await api.put(
        `/surveys/${state?.surveyId}`,
        encodedSurveyToSave,
      );

      if (response.data === null) {
        addToast({
          type: 'error',
          title: 'Survey Locked',
          description: `This survey is locked. No changes were saved.`,
        });
      } else {
        history.push(`/surveys/${state?.surveyId}`);

        await updateSurveyTranslations(
          encodedSurveyToSave,
          Number(state?.surveyId),
        );

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The survey text was selected successfully!',
        });
      }
    } catch (err) {
      if (err) {
        addToast({
          type: 'error',
          title: 'Updating Error',
          description: `An error occurred while updating survey, please try again.`,
        });
      }
    }
  }, [addToast, history, selectedRowIds, state?.survey, state?.surveyId]);

  return (
    <Container className={loading ? 'loading' : ''}>
      {/* {!!state?.select && <BackButton />} */}

      <h1> General Data Protection Regulation (GDPR) </h1>

      <Link
        to={{
          pathname: `/gdpr/new`,
        }}
      >
        <Button variant="contained" type="button">
          New GDPR
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={gdprs}
          selectionProp={selectedRowIds?.map(String)}
          multiSelection={false}
          setSelectedRowId={setSelectedRowIds}
          checkboxSelection={!!state?.select}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'gdprId'}
        ></Table>

        {!!state?.select && (
          <>
            <ButtonEb
              width="120px"
              height="40px"
              marginRight="30px"
              onClick={handleConfirm}
            >
              Confirm
            </ButtonEb>

            <Link to={''} onClick={history.goBack}>
              <ButtonEb width="120px" height="40px">
                Back
              </ButtonEb>
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};

export default GDPR;
