export const columns: any[] = [
  {
    name: 'questionId',
    title: 'Question',
  },
  {
    name: 'resultId',
    title: 'Result',
  },
  {
    name: 'answerOriginal',
    title: 'Original',
  },
  {
    name: 'answerEdited',
    title: 'Edited answer',
  },
  {
    name: 'created_at',
    title: 'Timestamp',
  },
  {
    name: 'user',
    title: 'User',
  },
];
