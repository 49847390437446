import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DeleteOutline } from '@material-ui/icons';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface Survey {
  surveyId: number;
  name: string;
  isLocked: boolean;
}

interface Props {
  survey: Survey;
}

const DeleteButton: React.FC<Props> = ({ survey }) => {
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: Survey) => {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the survey ${data.surveyId}?`,
        );
        if (userWantDelete && !data.isLocked) {
          const responseDelete = await api.delete(`/surveys/${data.surveyId}`);

          if (responseDelete.data === null) {
            addToast({
              type: 'error',
              title: 'Survey can not be deleted',
              description: 'The system can not delete this survey.',
            });
          } else if (responseDelete.data?.deleted) {
            addToast({
              type: 'success',
              title: 'Success',
              description: 'The survey was deleted successfully!',
            });
            history.push(`/projects/`);
            history.push(`/surveys/`);
          } else {
            addToast({
              type: 'info',
              title: 'Survey can not be deleted',
              description: responseDelete.data.message,
            });
          }
        }
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while deleting the survey, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Link to="#" onClick={() => handleSubmit(survey)}>
        <DeleteOutline />
      </Link>
    </>
  );
};

export default DeleteButton;
