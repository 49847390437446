import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

import Button from '../../../components/Button';

const QuestionTranslations: React.FC = () => {
  return (
    <Container>
      <h1>Question Translations</h1>

      <Link
        to={{
          pathname: `/questions/`,
          state: {
            select: true,
            pageTitle: 'Select questions before downloading model',
            isImportTranslations: true,
          },
        }}
      >
        <Button type="button" marginRight="30px" width="200px" height="40px">
          Download Model
        </Button>
      </Link>
    </Container>
  );
};

export default QuestionTranslations;
