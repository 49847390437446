/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Link } from 'react-router-dom';

import { FiLink } from 'react-icons/fi';

export const columnsLinks: any[] = [
  {
    name: 'languageName',
    title: 'Name',
  },

  {
    name: 'linkUrl',
    title: 'Link',
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <Link to={`${row.linkUrl}`} target="_blank">
            <FiLink size={22} color={'#ff0000'} />
          </Link>
        </>
      );

      return iconProperties;
    },
  },

  {
    name: 'copyUrl',
    title: 'Copy Url',
  },

  // {
  //   name: 'testLink',
  //   title: 'Test Link',
  //   width: 200,
  //   getCellValue: (row: any) => {
  //     const iconProperties = (
  //       <>
  //         <Link to={`/questionnaire/${row.accessId}?test=true`} target="_blank">
  //           <FiCheckCircle size={22} color={'#007f32'} />
  //         </Link>
  //       </>
  //     );

  //     return iconProperties;
  //   },
  // },
];
