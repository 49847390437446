import styled from 'styled-components';

export const ContentSurvey = styled.div`
  thead tr td:first-child {
    width: 45%;
    word-break: break-all;
  }

  .sv-completedpage {
    display: none;
    height: 100%;
  }

  .sv-title {
    padding-top: 1.3rem !important;
  }
`;
