import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import ParticipationLinkForm from '../Form';

interface ParamTypes {
  id: string;
}

interface ParticipationLink {
  name: string;
  customerId: number;
  projectId: number;
  surveyId: number;
  accesses: string[];
  segmentId: number;
  accessIds?: number[];
}

const EditParticipationLink: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { id } = useParams<ParamTypes>();
  const [participationLink, setParticipationLink] =
    useState<ParticipationLink>();
  const history = useHistory();
  const [selectedAccess, setSelectedAccess] = useState<number[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<number>(0);

  useEffect(() => {
    api.get(`/participationLinks/${id}`).then(response => {
      formRef.current?.setFieldValue('customerId', {
        value: response.data.customerId,
        // label: response.data.customerName,
      });

      formRef.current?.setFieldValue('projectId', {
        value: response.data.projectId,
      });

      formRef.current?.setFieldValue('surveyId', {
        value: response.data.surveyId,
      });

      formRef.current?.setFieldValue('segmentId', {
        value: response.data.segmentId,
      });

      setParticipationLink(response.data);
      setSelectedAccess(response.data.accessIds);
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: ParticipationLink) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required'),
          customerId: Yup.number().required('Customer is required'),
          projectId: Yup.number().required('Project required'),
          surveyId: Yup.number().required('Survey is required'),
          // accesses: Yup.array().min(1, 'Access is required'),
          segmentId: Yup.number().required('Segment is required'),
        });

        await schema
          .validate(data, {
            abortEarly: false,
          })
          .then(async () => {
            const body = {
              name: data.name,
              customerId: data.customerId,
              projectId: data.projectId,
              surveyId: data.surveyId,
              segmentId: selectedSegment,
              accessIds: selectedAccess.length > 0 ? selectedAccess : [0],
            };

            await api.put(`/participationLinks/${id}`, body);

            history.push('/participationLink/');

            addToast({
              type: 'success',
              title: 'Success',
              description: 'The Links was saved successfully!',
            });
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Access is required',
              description: 'Please select at least one access before save it.',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing the link, please try again.',
        });
      }
    },
    [addToast, history, id, selectedAccess, selectedSegment],
  );

  return (
    <>
      <h1>Edit Customer Link</h1>

      <ParticipationLinkForm
        formRef={formRef}
        handleSubmit={handleSubmit}
        initialData={participationLink}
        buttonText="Save"
        isEdit={true}
        setSelectedAccess={setSelectedAccess}
        setSelectedSegment={setSelectedSegment}
      />
    </>
  );
};

export default EditParticipationLink;
