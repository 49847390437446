export const columns: any[] = [
  {
    name: 'gdprId',
    title: 'ID',
  },
  {
    name: 'title',
    title: 'Title',
  },
  {
    name: 'text',
    title: 'Text',
  },
  { name: 'edit', title: '' },
];
