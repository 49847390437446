import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Content = styled.div`
  flex-grow: 4;
`;

export const ContentFile = styled.div`
  flex-grow: 4;
  margin-left: 20px;
`;

export const DivButtonSubmit = styled.div`
  display: flex;
  justify-content: center;
`;

export const ColumnFile = styled.td`
  padding: 10px;
  text-align: center;
`;
