/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */

/* eslint-disable no-self-compare */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-multi-str */

/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
// import 'jquery-bar-rating/jquery.barrating.js';
import '../SurveyCustomWidgets/eb-barrating.js';
import 'font-awesome/css/font-awesome.css';
import '../SurveyCustomWidgets/styles/bootstrap-stars.css';
import '../SurveyCustomWidgets/styles/css-stars.css';
import '../SurveyCustomWidgets/styles/fontawesome-stars.css';
import '../SurveyCustomWidgets/styles/fontawesome-stars-o.css';
import '../SurveyCustomWidgets/styles/bars-1to10.css';
import '../SurveyCustomWidgets/styles/bars-movie.css';
import '../SurveyCustomWidgets/styles/bars-square.css';
import '../SurveyCustomWidgets/styles/bars-pill.css';
import '../SurveyCustomWidgets/styles/bars-reversed.css';
import '../SurveyCustomWidgets/styles/bars-horizontal.css';
import '../SurveyCustomWidgets/styles/eb-emotions.css';

import 'jquery-ui-dist/jquery-ui.css';

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import 'nouislider/dist/nouislider.min.css';
import 'icheck/skins/square/blue.css';
import 'survey-creator/survey-creator.css';
import 'survey-knockout/survey.css';

import $ from 'jquery';

import * as SurveyKo from 'survey-knockout';
import * as SurveyJSCreator from 'survey-creator';
import * as widgets from 'surveyjs-widgets';
import './styles.css';
// import SortableTree from '@nosferatu500/react-sortable-tree';
import { SurveyCreatorConfig } from '../SurveyCreatorContext/SurveyCreatorConfig';
import { CustomCheckBox } from '../SurveyCreatorContext/CustomCheckboxSegments';
import Loading from '../../Loading/index.js';

(window as any).$ = (window as any).jQuery = $;
require('icheck');

interface Access {
  segments: string[];
  name: string;
  accessId: number;
}

SurveyCreatorConfig(SurveyKo, SurveyJSCreator, widgets);

interface SurveyCreactorComponentProps {
  surveyText: string;
  callbackSaveSurvey: (text: string) => void;
  updateAllQuestions: () => void;
  accesses: Access[];
  isLocked: boolean;
  idSurvey: number | undefined;
}

const SurveyCreatorComponent: React.FC<SurveyCreactorComponentProps> = ({
  surveyText,
  callbackSaveSurvey,
  updateAllQuestions,
  accesses,
  idSurvey,
  isLocked,
}) => {
  const creatorRef = useRef<SurveyJSCreator.SurveyCreator | undefined | null>(
    undefined,
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [surveyCreator, setSurveyCreator] =
    useState<SurveyJSCreator.SurveyCreator>();
  const renderQuestionInDesigner = useCallback((survey, options) => {
    options.question.registerFunctionOnPropertyValueChanged(
      'orderChoices',
      function (newValue: any) {
        if (newValue === 'asc value') {
          options.question.choices = options.question?.choices?.sort(
            (a: any, b: any) => {
              if (a.value < b.value) return -1;
              return a.value > b.value ? 1 : 0;
            },
          );
        } else {
          options.question.choices = options.question?.choices?.sort(
            (a: any, b: any) => {
              if (a.value > b.value) return -1;
              return a.value < b.value ? 1 : 0;
            },
          );
        }

        const parent = options.question;
        if (
          parent.getType() === 'ebslider' ||
          parent.getType() === 'ebsliderpolarity' ||
          parent.getType() === 'ebverticalpolarity' ||
          parent.getType() === 'ranking'
        ) {
          if (parent.updateRankingChoices) {
            parent.updateRankingChoices();
          } else {
            parent.updateRanking();
          }
        } else if (parent.updateVisibleChoices) {
          parent.updateVisibleChoices();
        }
      },
    );
  }, []);

  // load the survey creator
  useEffect(() => {
    const creatorOptions = {
      readOnly: isLocked,
      showState: true,
      isAutoSave: true,
      showToolbox: 'right',
      showPropertyGrid: 'right',
      // showSurveyTitle: 'always',
      // pageEditMode: 'single',
      allowAddToToolbox: false,
      allowModifyPages: false,
      showPagesToolbox: false,
      showJSONEditorTab: true,
      allowCopy: false,
      allowDragging: false,
      allowControlSurveyTitleVisibility: true,
      questionTypes: [
        'pagebreak',
        'html',
        'text',
        'comment',
        'radiogroup',
        'rating',
        'dropdown',
        'ebslider',
        'ebsliderpolarity',
        'ebverticalpolarity',
        'ebemotionsratings',
        'funnyemotionsratings',
        'barrating',
        'ranking',
        'checkbox',
        'image',
        'ebcontactform',
        'ebdragndrop',
      ],
    };

    const propertyStopList = [
      'bindings',
      'rangeName',
      'start',
      'step',
      'rangeMin',
      'rangeMax',
      'pipsMode',
      'pipsValues',
      'pipsText',
      'pipsDensity',
      'orientation',
      'direction',
      'rateValues',
      'rateMin',
      'rateMax',
      'rateStep',
      'displayRateDescriptionsAsExtremeItems',
      'defaultValueExpression',
      'rowsVisibleIf',
      'minValueExpression',
      'maxValueExpression',
    ];

    // create a new instance for surveyCreator
    const internalSurveyCreator = new SurveyJSCreator.SurveyCreator(
      null,
      creatorOptions,
    );

    internalSurveyCreator.onElementAllowOperations.add(function (
      editor,
      options,
    ) {
      // enable edit
      options.allowEdit = true;
      // disable delete
      options.allowDelete = true;
      // disable change require
      options.allowChangeRequired = true;
      // disable change show/hide title
      options.allowShowHideTitle = true;
      // disable add to toolbox
      options.allowAddToToolbox = false;
      // disable copying
      options.allowCopy = false;
      // disable changing type
      options.allowChangeType = true;
      // Enable/disable element drag&drop
      options.allowDragging = true;
    });

    internalSurveyCreator.onShowingProperty.add(function (sender, options) {
      options.canShow = propertyStopList.indexOf(options.property.name) === -1;
    });

    internalSurveyCreator.onSurveyInstanceCreated.add(function (
      sender,
      options,
    ) {
      // If we are creating a surface for designer surface
      if (options.reason === 'designer') {
        options.survey.onAfterRenderQuestion.add(renderQuestionInDesigner);
      }
    });

    // toolbox setttings
    internalSurveyCreator.toolbox.removeItem('bootstrapslider');
    internalSurveyCreator.toolbox.removeItem('sortablejs');
    internalSurveyCreator.toolbox.removeItem('sortablelist');

    internalSurveyCreator.text = surveyText;
    // callback function: is called by any change in the survey (properties, toolbox, pages..etc)
    internalSurveyCreator.saveSurveyFunc = (saveNo: any, callback: any) => {
      callbackSaveSurvey(internalSurveyCreator.text);
      callback(saveNo, callbackSaveSurvey);
    };

    internalSurveyCreator.toolbarItems.push({
      id: 'svd-update-questions',
      title: 'Update All Questions',
      visible: true,
      enabled: true,
      action: updateAllQuestions,
    });

    // put the new instance of surveyCreator into state
    setSurveyCreator(internalSurveyCreator);
  }, [
    callbackSaveSurvey,
    isLocked,
    renderQuestionInDesigner,
    updateAllQuestions,
    surveyText,
  ]);

  const refreshQuestionVisibility = useCallback(
    (segmentListAsString: string, questionName: string) => {
      if (surveyCreator) {
        if (segmentListAsString.length > 0) {
          const question1 =
            surveyCreator.survey.getQuestionByName(questionName);

          if (!question1.visibleIf.includes('questionWithSegment')) {
            // keep same logic
            question1.setPropertyValue(
              'visibleIf',
              question1.visibleIf
                ? `${question1.visibleIf} and questionWithSegment('${segmentListAsString}')=true`
                : `questionWithSegment('${segmentListAsString}')=true`,
            );
          } else {
            // replace the logic
            const indexStart = question1.visibleIf.indexOf(
              "questionWithSegment('",
            );

            const indexEnd = question1.visibleIf.indexOf(')', indexStart + 1);

            const textToReplace = question1.visibleIf.substring(
              indexStart,
              indexEnd,
            );

            question1.setPropertyValue(
              'visibleIf',
              `${question1.visibleIf.replace(
                textToReplace,
                `questionWithSegment('${segmentListAsString}'`,
              )}`,
            );
          }
        } else {
          // remove every logic related with segments
          const question1 =
            surveyCreator.survey.getQuestionByName(questionName);

          let indexStart = question1.visibleIf.indexOf(
            "and questionWithSegment('",
          );

          if (indexStart === -1) {
            indexStart = question1.visibleIf.indexOf("questionWithSegment('");
          }

          const indexEnd =
            question1.visibleIf.indexOf('true', indexStart + 1) + 4;

          const textToReplace = question1.visibleIf.substring(
            indexStart,
            indexEnd,
          );

          const visibleIf = `${question1.visibleIf.replace(textToReplace, ``)}`;

          if (visibleIf.startsWith(` and`)) {
            question1.setPropertyValue('visibleIf', visibleIf.slice(4));
          } else if (visibleIf.startsWith(`and`)) {
            question1.setPropertyValue('visibleIf', visibleIf.slice(3));
          } else {
            question1.setPropertyValue('visibleIf', visibleIf);
          }
        }
      }
    },
    [surveyCreator],
  );

  const refreshCustomComponentsNames = useCallback(() => {
    if (surveyCreator) {
      surveyCreator?.onQuestionAdded.add(function (sender, options) {
        const q = options.question;

        const t = q.getType();

        const question1 =
          surveyCreator.survey.getQuestionByValueName(`question1`);

        if (question1) {
          switch (t) {
            case 'ebcontactform':
              question1.name = `contact_form_${q.id}`;
              question1.title = `Contact Form`;
              break;
            case 'image':
              question1.name = `image_${q.id}`;
              break;
            case 'pagebreak':
              question1.name = `pagebreak_${q.id}`;
              break;
            case 'html':
            case 'htmlElement':
              question1.name = `html_${q.id}`;
              question1.html =
                '<p style="font-size: 20px; font-family: Roboto; font-weight: 700">Hier der gewünschte Text</p>';
              break;
            default:
              break;
          }
        }
      });
    }
  }, [surveyCreator]);

  useEffect(() => {
    if (surveyCreator) {
      surveyCreator.onSetPropertyEditorOptions.add(function (editor, options) {
        if (options.propertyName === 'choices') {
          options.editorOptions.allowAddRemoveItems = false;
          options.editorOptions.showTextView = true;
        }
      });
    }

    const customCheckbox = CustomCheckBox(
      SurveyKo,
      accesses,
      SurveyJSCreator,
      refreshCustomComponentsNames,
      refreshQuestionVisibility,
    );
  }, [
    accesses,
    refreshCustomComponentsNames,
    refreshQuestionVisibility,
    surveyCreator,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (containerRef.current) {
      creatorRef.current = surveyCreator;

      SurveyKo.koTemplate?.SurveyTemplateEngine.registerTemplate(
        'action-separator',
        "<div class='action-separator'></div>",
      );
      surveyCreator?.render(containerRef.current);
      return () => {
        if (creatorRef.current) {
          if (typeof (creatorRef.current as any).destroy === 'function') {
            (creatorRef.current as any).destroy();
          }
          creatorRef.current = null;
        }
      };
    }
  }, [surveyCreator]);

  return (
    <div id="surveyContainer">
      <div ref={containerRef} id="creatorElement"></div>
    </div>
  );
};

export default SurveyCreatorComponent;
