import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormSegment from '../Form';

interface Segment {
  segmentId: number;
  name: string;
}

const CreateSegment: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: Segment) => {
      try {
        formRef.current?.setErrors({});

        const segment = {
          name: data.name,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('The name is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/segments', segment);

        const redirectUrl = '/segments';

        history.push(redirectUrl);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The segment was created successfully!',
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating the segment, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <h1>New Segment</h1>

      <FormSegment
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
      />
    </>
  );
};

export default CreateSegment;
