import styled from 'styled-components';

interface HeaderProps {
  headerColor: string;
}

// export const Container = styled.div`
//   display: inline-block;
//   color: #aaa;
//   font-size: 12px;

//   h2 {
//     font-weight: bold;
//   }

//   p {
//     margin: 10px 0;
//   }
// `;

export const Container = styled.div`
  display: inline-block;
  color: black;
  font-size: 12px;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 24px 24px;
  position: relative;
  top: 40px;
  h2 {
    font-weight: bold;
  }

  p {
    margin: 10px 0;
  }
`;

export const Header = styled.header<HeaderProps>`
  /* Thin */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Thin.ttf) format('truetype');
    font-style: normal;
    font-weight: 100;
  }

  /* Thin Italic */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-ThinItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 100;
  }

  /* Light */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Light.ttf) format('truetype');
    font-style: normal;
    font-weight: 300;
  }

  /* Light Italic */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 300;
  }

  /* Normal */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
    font-style: normal;
    font-weight: 400;
  }

  /* Normal Italic */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Italic.ttf) format('truetype');
    font-style: italic;
    font-weight: 400;
  }

  /* Medium */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Medium.ttf) format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  /* Medium Italic */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 500;
  }

  /* Bold */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
    font-style: normal;
    font-weight: 700;
  }

  /* Bold Italic */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 700;
  }

  /* Black */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-Black.ttf) format('truetype');
    font-style: normal;
    font-weight: 900;
  }

  /* Black Italic */
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url(../fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 900;
  }

  width: 100%;
  height: 75px;
  font-family: Roboto;
  background-color: ${props => props.headerColor};
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  top: 60px;

  p {
    margin: 12px 0;
  }

  p.title {
    font-size: 1.2em;
    font-weight: bold;
  }

  p.small {
    font-size: 12px;
    margin: 10px 0 30px;
  }

  div.text,
  svg {
    line-height: 50px;
    vertical-align: middle;
    font-size: 30px;
  }
`;
