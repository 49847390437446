/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const columns: any[] = [
  { name: 'userGroup', title: 'Usergroup' },
  { name: 'languageCode', title: 'Language' },
  { name: 'resultId', title: 'Result' },
  { name: 'questionId', title: 'Question' },
  { name: 'questionText', title: ' Question Text' },
  { name: 'answerOriginal', title: 'Answer' },
  { name: 'answerGerman', title: 'Translation' },
  { name: 'categories', title: 'Categories' },
  { name: 'categoriesSplited', title: 'Categories' },
  { name: 'semantic', title: 'Semantic' },
  { name: 'edit', title: 'Edit' },
];
