/* eslint-disable no-alert */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

// creating a custom question type based on noUiSlider lib
import './styles/drag-drop.css';
import './eb-drag-and-drop.js';
import { ItemValue } from 'survey-react';

interface ValuePerBox {
  boxValue: number;
  answerValue: number[];
}

interface EbDragDropValue {
  value: number[];
  valuePerBox: ValuePerBox[];
}

export const EbDragDrop: any = (SurveyKo: any) => {
  return {
    name: 'ebdragndrop',
    title: 'EBDragNDrop',
    iconName: 'icon-barrating',
    widgetIsLoaded() {
      return typeof $ == 'function';
    },
    defaultJSON: {
      choices: [1, 2, 3, 4, 5],
    },
    isFit(question: any) {
      return question.getType() === 'ebdragndrop';
    },
    init() {
      SurveyKo.Serializer.addProperty('ebdragndrop', {
        name: 'boxTitles:itemvalues',
        category: 'ebdragndrop',
        categoryIndex: 1,
      });
    },
    isDefaultRender: false,
    htmlTemplate: "<div style='height: 600px'></div>",
    activatedByChanged(activatedBy: any) {
      SurveyKo.JsonObject.metaData.addClass(
        'ebdragndrop',
        [
          {
            name: 'hasOther',
            visible: false,
          },
          {
            name: 'otherText',
            visible: false,
          },
          {
            name: 'optionsCaption',
            visible: false,
          },
          {
            name: 'otherErrorText',
            visible: false,
          },
          {
            name: 'storeOthersAsComment',
            visible: false,
          },
          {
            name: 'renderAs',
            visible: false,
          },
        ],
        null,
        'checkbox',
      );
      SurveyKo.JsonObject.metaData.addProperties('ebdragndrop', [
        {
          name: 'boxTitles:itemvalues',
          category: 'ebdragndrop',
          categoryIndex: 1,
        },
      ]);
      SurveyKo.JsonObject.metaData.addProperties('ebdragndrop', [
        {
          name: 'keepIncorrectValues:boolean',
          category: 'ebdragndrop',
          default: true,
        },
      ]);
    },
    afterRender(question: any, el: any) {
      question.choices = question.choices.sort((a: any, b: any) => {
        if (a.value > b.value) return -1;
        return a.value < b.value ? 1 : 0;
      });

      let numberOfBoxes = question.boxTitles?.length ?? 0;
      if (!numberOfBoxes) {
        console.warn('Number of boxes not defined. Using the default 3');
        numberOfBoxes = 3;
        let arrayBoxes = [];
        for (let index = 0; index < numberOfBoxes; index += 1) {
          const element = new ItemValue(index + 1, `Box ${index + 1}`, '');

          arrayBoxes.push(element);
        }
        question.boxTitles = arrayBoxes;
      }

      function onUpdate(newValue: any) {
        question.value = newValue;
      }
      const options = {
        question,
        onUpdate,
      };

      // initiate widget
      el.innerHTML = null;
      el.innerHTML = `
      <div id="boxQuestions">
      </div>
      `;
      $(el).off();

      const boxQuestions = (<any>$(el)).find('#boxQuestions');

      function setBoxesFromQuestionValues() {
        const isCheckboxDefaultEmptyValue =
          Array.isArray(question.value) && question.value.length === 0;
        if (isCheckboxDefaultEmptyValue) {
          console.log('No value selected');
          return;
        }
        const selectedValues: EbDragDropValue = Array.isArray(question.value)
          ? question.value[0]
          : question.value;

        const destinationBoxes = $(boxQuestions).children('#boxAnswersPodium');
        const boxValues = selectedValues.valuePerBox;
        for (let i = 0; i < boxValues.length; i += 1) {
          const boxDestination = $(destinationBoxes).children(
            `[data-boxvalue=${boxValues[i].boxValue}]`,
          );
          for (
            let answerIndex = 0;
            answerIndex < boxValues[i].answerValue.length;
            answerIndex += 1
          ) {
            const selected = $(boxQuestions)
              .children('.boxQuestionsPodium')
              .children(
                `[data-valuenumber=${boxValues[i].answerValue[answerIndex]}]`,
              );
            $(selected).detach().appendTo(boxDestination);
          }
        }
      }
      boxQuestions?.ebdragndrop(options);

      // if the question value changed in the code, for example you have changed it in JavaScript
      question.valueChangedCallback = setBoxesFromQuestionValues;

      setBoxesFromQuestionValues();
    },
    willUnmount(question: any, el: any) {
      el.innerHTML = null;
      $(el).off();
      question.readOnlyChangedCallback = null;
      question.valueChangedCallback = null;
    },

    pdfQuestionType: 'checkbox',
  };
};
