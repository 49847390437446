import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IoMdAnalytics } from 'react-icons/io';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';

import {
  // FiSettings,
  FiDownload,
  FiBookOpen,
  FiList,
  FiPower,
  FiPackage,
  FiCode,
  FiSmile,
  FiMail,
  FiTwitch,
  FiLayers,
} from 'react-icons/fi';

import SpeedIcon from '@material-ui/icons/Speed';

import HowToRegIcon from '@material-ui/icons/HowToReg';

import InsertLinkIcon from '@material-ui/icons/InsertLink';

import { HiTranslate } from 'react-icons/hi';

import 'react-pro-sidebar/dist/css/styles.css';
import {
  SidebarHeaderCustom,
  SidebarHeaderContent,
  SidebarFooterContent,
  Button,
  EnvironmentContent,
} from './styles';

import { useAuth } from '../../hooks/auth';

import sidebarBg from '../../assets/menu-background.jpg';
import logoImg from '../../assets/eb-logo-white.png';

interface SideBarProps {
  name: string;
}

const SideBar: React.FC<SideBarProps> = ({ name, ...rest }) => {
  const { signOut } = useAuth();
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [isDevEnvironment, setIsDevEnvironment] = useState<boolean>();

  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    const host = `${window.location.hostname}`;

    setIsDevEnvironment(
      host.indexOf('localhost') !== -1 || host.indexOf('dev.meine') !== -1,
    );
  }, []);

  const handleMenuClick = useCallback(() => {
    setCurrentLocation(window.location.pathname);
  }, []);

  return (
    <ProSidebar image={sidebarBg} width="240px">
      {isDevEnvironment && (
        <EnvironmentContent>
          <span style={{ color: 'yellow' }}>DEV</span>
        </EnvironmentContent>
      )}
      <SidebarHeaderCustom>
        <SidebarHeaderContent>{name}</SidebarHeaderContent>
        <Button type="button" onClick={signOut}>
          <FiPower color={'#fff'} size={20} />
        </Button>
      </SidebarHeaderCustom>

      <SidebarContent>
        <Menu iconShape="circle">
          {/* <MenuItem
            icon={<InsertLinkIcon />}
            active={currentLocation === '/accesses/search'}
            onClick={handleMenuClick}
          >
            Access
            <Link to="/accesses/search" />
          </MenuItem> */}

          <SubMenu title="Access" icon={<InsertLinkIcon />}>
            <MenuItem
              active={currentLocation === '/accesses/search'}
              onClick={handleMenuClick}
            >
              Access List
              <Link to="/accesses/search" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/accessGroup'}
              onClick={handleMenuClick}
            >
              Groups List
              <Link to="/accessGroup" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/accesses/importSample'}
              onClick={handleMenuClick}
            >
              Import File
              <Link to="/accesses/importSample" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="A-Pillars" icon={<SpeedIcon />}>
            <MenuItem
              active={currentLocation === '/apillars/new'}
              onClick={handleMenuClick}
            >
              New A-Pillar
              <Link to="/apillars/new" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/apillars'}
              onClick={handleMenuClick}
            >
              List
              <Link to="/apillars" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Questions" icon={<FiBookOpen />}>
            <MenuItem
              active={currentLocation === '/questions/new'}
              onClick={handleMenuClick}
            >
              New Question
              <Link to="/questions/new" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/questions'}
              onClick={handleMenuClick}
            >
              List
              <Link to="/questions" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/questions/translations'}
              onClick={handleMenuClick}
            >
              Download File
              <Link to="/questions/translations" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/questions/importFile'}
              onClick={handleMenuClick}
            >
              Import File
              <Link to="/questions/importFile" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Customers" icon={<HowToRegIcon />}>
            <MenuItem
              active={currentLocation === '/customers/new'}
              onClick={handleMenuClick}
            >
              New Customer
              <Link to="/customers/new" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/customers'}
              onClick={handleMenuClick}
            >
              List
              <Link to="/customers" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Projects" icon={<FiSmile />}>
            <MenuItem
              active={currentLocation === '/projects/new'}
              onClick={handleMenuClick}
            >
              New Project
              <Link to="/projects/new" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/projects'}
              onClick={handleMenuClick}
            >
              List
              <Link to="/projects" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Surveys" icon={<FiList />}>
            <MenuItem
              active={currentLocation === '/surveys/new'}
              onClick={handleMenuClick}
            >
              New Survey
              <Link
                to={{
                  pathname: `/surveys/new`,
                  state: { projectId: undefined },
                }}
              />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/surveys'}
              onClick={handleMenuClick}
            >
              List
              <Link to="/surveys" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Products" icon={<FiPackage />}>
            <MenuItem
              active={currentLocation === '/products/new'}
              onClick={handleMenuClick}
            >
              New Product
              <Link to="/products/new" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/products/surveyTemplate'}
              onClick={handleMenuClick}
            >
              Survey Templates
              <Link to="/products/surveyTemplate" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/products'}
              onClick={handleMenuClick}
            >
              Structures
              <Link to="/products" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Customization" icon={<FiCode />}>
            <MenuItem
              active={currentLocation === '/surveyTexts/type/start'}
              onClick={handleMenuClick}
            >
              Start Texts
              <Link to="/surveyTexts/type/start" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/surveyTexts/type/final'}
              onClick={handleMenuClick}
            >
              Final Texts
              <Link to="/surveyTexts/type/final" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/logos'}
              onClick={handleMenuClick}
            >
              Logos
              <Link to="/logos" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/images'}
              onClick={handleMenuClick}
            >
              Images
              <Link to="/images" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/videos'}
              onClick={handleMenuClick}
            >
              Videos - Bucket
              <Link to="/videos" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/gdpr'}
              onClick={handleMenuClick}
            >
              GDPR
              <Link to="/gdpr" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/segments'}
              onClick={handleMenuClick}
            >
              Segments
              <Link to="/segments" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/participationLink'}
              onClick={handleMenuClick}
            >
              Customer Links
              <Link to="/participationLink" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/tags'}
              onClick={handleMenuClick}
            >
              Categories
              <Link to="/tags" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/tagTranslations'}
              onClick={handleMenuClick}
            >
              Category Translation
              <Link to="/tagTranslations" />
            </MenuItem>
          </SubMenu>

          <MenuItem
            icon={<FiMail />}
            active={currentLocation === '/respondents'}
            onClick={handleMenuClick}
          >
            Respondent EMails
            <Link to="/respondents" />
          </MenuItem>

          <SubMenu title="Languages" icon={<HiTranslate />}>
            <MenuItem
              active={currentLocation === '/languages/new'}
              onClick={handleMenuClick}
            >
              New Language
              <Link to="/languages/new" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/languages'}
              onClick={handleMenuClick}
            >
              List
              <Link to="/languages" />
            </MenuItem>
          </SubMenu>

          <MenuItem
            icon={<FiTwitch />}
            active={currentLocation === '/respondents'}
            onClick={handleMenuClick}
          >
            Message Board
            <a
              href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMjY0MTUxMDE5NTIifQ/all
"
              target="_blank"
              rel="noreferrer"
            />
          </MenuItem>

          <SubMenu title="Export Results" icon={<FiCode />}>
            <MenuItem
              icon={<FiDownload />}
              active={currentLocation === '/export'}
              onClick={handleMenuClick}
            >
              to old platform
              <Link to="/ExportResultGeneric" />
            </MenuItem>
            <MenuItem
              icon={<FiDownload />}
              active={currentLocation === '/exportDatawarehouse'}
              onClick={handleMenuClick}
            >
              to datawarehouse
              <Link to="/exportDatawarehouse" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Logs" icon={<FiLayers />}>
            <MenuItem
              active={currentLocation === '/logs'}
              onClick={handleMenuClick}
            >
              Surveys
              <Link to="/logs" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/logs/questions'}
              onClick={handleMenuClick}
            >
              Questions
              <Link to="/logs/questions" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/logs/answers'}
              onClick={handleMenuClick}
            >
              Edited Answers
              <Link to="/logs/answers" />
            </MenuItem>
          </SubMenu>
          <MenuItem
            icon={<IoMdAnalytics />}
            active={currentLocation === '/analytics'}
            onClick={handleMenuClick}
          >
            Analytics
            <Link to="/analytics" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <SidebarFooterContent>
          <img src={logoImg} alt="Victor" width="200" />
        </SidebarFooterContent>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideBar;
