import React, { useCallback, useEffect, useState } from 'react';
import { Container } from './styles';
import Select from '../SelectForm';

interface Option {
  label: string;
  value: string | number;
}

interface Props {
  name: string;
  setSelectedTags: any;
  tags: any[];
  tagsSearch: string[] | Option[] | undefined;
  acceptNewValues: boolean;
  componentHasChanged?: any;
}

const TagsSelect: React.FC<Props> = ({
  tags,
  name,
  setSelectedTags,
  tagsSearch,
  acceptNewValues,
  componentHasChanged,
}) => {
  const [localQuestionTags, setLocalQuestionTags] = useState<any[]>([]);
  const [localTagsSearch, setLocalTagsSearch] = useState<Option[]>([]);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (tags && tags.length > 0) {
      setLocalQuestionTags([...tags?.map(tag => tag?.value)]);
    }
  }, [tags]);

  useEffect(() => {
    if (tagsSearch)
      setLocalTagsSearch(
        tagsSearch.map((x: any) => {
          if (typeof x === 'string') {
            return {
              label: x,
              value: x,
            };
          }
          return x;
        }),
      );
  }, [tagsSearch]);

  const handleOnChange = useCallback(
    e => {
      setLocalQuestionTags(e?.map((item: any) => item.value));
      setShowWarning(false);
      // Clear the input
      if (componentHasChanged) componentHasChanged(true);
    },
    [componentHasChanged],
  );

  const handleKeyDown = useCallback(
    e => {
      setShowWarning(false);
      const listFiltered = localTagsSearch.filter(tag =>
        tag.label.split('/')[0].trim().includes(e.target.value),
      );

      if (e.key === 'Enter') {
        if (listFiltered.length > 0) {
          const isAlreadyAdded = localQuestionTags.some(
            (item: any) => item === e.target.value,
          );
          if (isAlreadyAdded) {
            setShowWarning(true);
            e.target.blur();
            e.target.focus();
            return;
          }

          const isEqual = listFiltered.find(
            (item: any) => item === e.target.value,
          );

          if (!isEqual) {
            setLocalQuestionTags(prevValues => [...prevValues, e.target.value]);
            return;
          }
          setShowWarning(false);
        }
      }

      if (e.key !== 'Enter') return;

      // Get the value of the input
      e.preventDefault();

      const { value } = e.target;
      // If the value is empty, return
      if (!value.trim()) return;
      // Add the value to the tags array
      const tagAlreadyExists = localQuestionTags.find(
        (tag: any) => tag === value,
      );
      if (tagAlreadyExists) {
        setShowWarning(true);
        e.target.blur();
        e.target.focus();
        return;
      }

      setLocalQuestionTags(prevValues => [...prevValues, value]);
      const newTagsSearch = localTagsSearch.filter(
        (tag: any) => tag !== value,
        [],
      );

      setShowWarning(false);
      setLocalTagsSearch(newTagsSearch);

      // Clear the input
      e.target.value = '';
      e.target.blur();
      e.target.focus();
    },
    [localQuestionTags, localTagsSearch],
  );

  const handleKeyDownChooseTag = useCallback(
    e => {
      setShowWarning(false);
      const listFiltered = localTagsSearch.filter(tag =>
        tag.label.split('/')[0].trim().includes(e.target.value),
      );

      if (e.key === 'Enter') {
        if (listFiltered.length > 0) {
          const isAlreadyAdded = localQuestionTags.some(
            (item: any) => item === e.target.value,
          );
          if (isAlreadyAdded) {
            setShowWarning(true);
            e.target.blur();
            e.target.focus();
            return;
          }

          const isEqual = listFiltered.find(
            (item: any) => item === e.target.value,
          );

          if (!isEqual) {
            setLocalQuestionTags(prevValues => [...prevValues, e.target.value]);
            return;
          }
          setShowWarning(false);
        }
      }
    },
    [localQuestionTags, localTagsSearch],
  );

  const optionFilter = useCallback((option: Option, rawInput: string) => {
    if (
      option.label
        .split('/')[0]
        .trim()
        .toLowerCase()
        .includes(rawInput.toLowerCase())
    ) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    setSelectedTags(
      localQuestionTags.map(item => {
        return { value: item };
      }),
    );
  }, [localQuestionTags, setSelectedTags]);

  return (
    <Container>
      <>
        <Select
          isMulti
          name={name ?? 'tags'}
          options={localTagsSearch}
          value={localQuestionTags.map(tag => {
            return { value: tag, label: tag };
          })}
          className="basic-multi-select"
          onChange={handleOnChange}
          filterOption={optionFilter}
          onKeyDown={acceptNewValues ? handleKeyDown : handleKeyDownChooseTag}
          escapeClearsValue={false}
        />
        {showWarning && (
          <span style={{ color: 'red' }}>
            * This tag was already added to this question.
          </span>
        )}
      </>
    </Container>
  );
};

export default TagsSelect;
