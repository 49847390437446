/* eslint-disable no-shadow */

enum EnumIntegrationStatusDataWarehouse {
  Canceled = -1,
  Pending = 0,
  Staging_Integrating_Languages = 1,
  Staging_Integrating_Questions = 2,
  Staging_Integrating_Alternatives = 3,
  Staging_Integrating_Projects = 4,
  Staging_Integrating_Categories = 5,
  Staging_Integrating_Customers = 6,
  Staging_Integrating_Access = 7,
  Staging_Integrating_Surveys = 8,
  Staging_Integrating_Results = 9,
  Staging_Preparing_Dw_Integration = 10,
  DW_Integrating_Languages = 11,
  DW_Integrating_Access = 12,
  DW_Integrating_Questions = 13,
  DW_Integrating_Categories = 14,
  DW_Integrating_Alternatives = 15,
  DW_Integrating_Customers = 16,
  DW_Integrating_Projects = 17,
  DW_Integrating_Surveys = 18,
  DW_Integrating_Results = 19,
  Finished = 20,
  Started = 21,
}

export default EnumIntegrationStatusDataWarehouse;
