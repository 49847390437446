import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import Table from '../../../components/DevExpressTable/DevExpressTable';
import { columns } from '../columns';

import { Container, Content } from './styles';

interface Log {
  idLog: number;
  timestamp: string;
  verb: string;
  url: string;
  user: string;
}

interface LogDB {
  timestamp: Date;
  message: string;
  _id: string;
}

const Logs: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<Log[]>([]);

  useEffect(() => {
    setLoading(true);
    api.get('logs/questions').then(response => {
      let counter = 0;
      const logList = response?.data?.map((logDB: LogDB) => {
        const msgSplited = logDB.message?.split('URL');
        let operation = msgSplited[0]?.split('HTTP')[1]?.replace(/\s/g, '');
        let url = msgSplited[1]?.split('USER')[0];
        if (!operation) {
          // old logs has no HTTP and USER - TODO: this if can be removed in the future
          if (logDB.message.includes('PUT')) {
            operation = 'PUT';
            url = logDB.message?.split(' ')[1];
          } else if (logDB.message.includes('POST')) {
            operation = 'POST';
            url = logDB.message?.split(' ')[1];
          }
        }
        const row = {
          idLog: counter,
          timestamp: new Date(logDB.timestamp).toLocaleString(),
          verb:
            operation === 'POST'
              ? 'CREATE'
              : operation === 'PUT'
              ? 'UPDATE'
              : '',
          url,
          user: msgSplited[1]?.split('USER')[1],
        };
        counter += 1;

        return row;
      });
      setLogs(logList);
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1> Questions - Last updates</h1>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={logs}
          checkboxSelection={false}
          hasFilterRow={true}
          idName={'idLog'}
        ></Table>
      </Content>
    </Container>
  );
};

export default Logs;
