import styled from 'styled-components';

export const BoxMessageContainerStyled = styled.div`
  position: absolute;
  z-index: 2000;
  width: 87%;
  top: -25px;
  right: 3px;
  padding: 30px;
  overflow: hidden;
`;

export default BoxMessageContainerStyled;
