import React, { useCallback, useEffect, useState } from 'react';

import { read, utils } from 'xlsx';

import Select from 'react-select';

import {
  ColumnFile,
  Container,
  Content,
  ContentFile,
  DivButtonSubmit,
} from './styles';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import BackButton from '../../../components/BackButton';

interface TagTranslation {
  Text: string;
  Translation: string;
  RowNum: number;
}

interface Language {
  languageId: number;
  name: string;
}

interface DefaultLanguage {
  value: string;
  label: string;
}

interface ResponseImport {
  languageName: string;
  text: string;
  translation: string;
  tagTranslationId: number;
}

const ImportFileTagTranslations: React.FC = () => {
  const { addToast } = useToast();
  const [translations, setTranslations] = useState<TagTranslation[]>([]);
  const [isSeelectedFile, setIsSelectedFile] = useState(false);
  const [showButtonSubmit, setShowButtonSubmit] = useState(false);
  const [isSelectedLanguage, setIsSelectedLanguage] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState({
    value: '0',
    label: 'Select...',
  } as DefaultLanguage);
  const [tagDefaultLanguages, setTagDefaultLanguages] = useState(
    [] as DefaultLanguage[],
  );

  const [responseImport, setResponseImport] = useState<ResponseImport[]>([]);

  useEffect(() => {
    api.get('languages').then(response => {
      const languageList: DefaultLanguage[] = [];
      if (response.data) {
        response.data.forEach((language: Language) => {
          if (language.languageId !== 1) {
            languageList.push({
              value: `${language.languageId}`,
              label: language.name,
            });
          }
        });
      }
      setTagDefaultLanguages(languageList);
    });
  }, []);

  const onChange = async (e: any): Promise<void> => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();

    const wb = read(f);
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const data = utils.sheet_to_json<TagTranslation>(ws); // generate objects

    const translationsMapped = data.map((x: any) => {
      const a: TagTranslation = x;
      // eslint-disable-next-line no-underscore-dangle
      a.RowNum = x.__rowNum__;

      return a;
    });

    const filtered = translationsMapped.filter(x => x.Text && x.Translation);

    if (filtered && filtered.length > 0) {
      setTranslations(translationsMapped.filter(x => x.Text && x.Translation));
      setIsSelectedFile(true);
      setShowButtonSubmit(true);
    } else
      addToast({
        type: 'info',
        title: 'Check the file',
        description:
          'The system could not find any translation on this file. Please check the file and try again.',
      });
  };

  const handleLanguageDefaultChange = useCallback(language => {
    setDefaultLanguage(language);
    setIsSelectedLanguage(true);
  }, []);

  const handleImportSubmit = useCallback(() => {
    setShowButtonSubmit(!showButtonSubmit);

    try {
      api
        .post('/tagTranslations/importFile', {
          languageId: +defaultLanguage.value,
          languageName: defaultLanguage.label,
          data: translations,
        })
        .then(response => {
          setResponseImport(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, [defaultLanguage, showButtonSubmit, translations]);

  return (
    <>
      <BackButton />
      <h1>Import Translations</h1> <br />
      <Container>
        <Content>
          <Select
            name="defaultLanguageId"
            value={defaultLanguage}
            options={tagDefaultLanguages}
            onChange={e => handleLanguageDefaultChange(e)}
          />
        </Content>
        <ContentFile>
          <div className="App" hidden={!isSelectedLanguage}>
            <div className="upload-btn-wrapper">
              <input
                type="file"
                name="myfile"
                disabled={isSeelectedFile}
                onChange={onChange}
              />
              <span>[ columns: Text / Translation ]</span>
            </div>
            <br />
          </div>
        </ContentFile>
      </Container>
      <table
        hidden={!isSeelectedFile && translations && translations?.length === 0}
      >
        <thead>
          <th>Text</th>
          <th>Translation</th>
        </thead>
        <tbody>
          {translations?.map((t: TagTranslation) => (
            <tr key={t.Text}>
              <ColumnFile>{t.Text}</ColumnFile>
              <ColumnFile>{t.Translation}</ColumnFile>
            </tr>
          ))}
        </tbody>
      </table>
      <DivButtonSubmit>
        <Button
          hidden={!showButtonSubmit}
          type="button"
          onClick={handleImportSubmit}
          marginRight="30px"
          width="200px"
          height="40px"
        >
          Submit
        </Button>
      </DivButtonSubmit>
      <div hidden={showButtonSubmit || !isSeelectedFile}>
        <h2>Import result:</h2>

        <table>
          <thead>
            <th>Language</th>
            <th>Text</th>
            <th>Translation</th>
          </thead>
          <tbody>
            {responseImport?.map((t: ResponseImport) => (
              <tr key={t.tagTranslationId}>
                <ColumnFile>{t.languageName}</ColumnFile>
                <ColumnFile>{t.text}</ColumnFile>
                <ColumnFile>{t.translation}</ColumnFile>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ImportFileTagTranslations;
