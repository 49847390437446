import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 20px 0 0;
`;

export const DivCombobox = styled.div`
  margin-top: 20px;

  #labelHideAlternatives {
    margin-left: 5px;
  }
`;
