import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div``;

export const DivFilter = styled.div`
  margin: 20px 20px 20px 0;
  input {
    margin-left: 5px;
    margin-right: 10px;
    width: 150px;
  }
`;

export const SpanWarning = styled.span`
  color: red;
`;

export const IdsSelected = styled.p`
  color: gray;
`;
