import styled from 'styled-components';
import { Button as Btn } from '@material-ui/core';

export const Container = styled.div``;

export const Content = styled.div``;

export const BoxId = styled.div`
  margin-top: 25px;
`;

export const HeaderInformations = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderInformationsLeft = styled.div``;

export const HeaderInformationsRight = styled.div`
  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

export const ButtonDelete = styled.button`
  background-color: #00b698;
  color: white;
  border: 0;
  &:hover {
    background-color: #009179;
  }
  padding: 0px 20px;
`;

export const DivButtonDialog = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled(Btn)`
  margin: 20px 20px 10px 0 !important;
  width: 200px;
`;
