export const columns: any[] = [
  {
    name: 'categoryId',
    title: 'ID',
  },
  {
    name: 'title',
    title: 'Title',
  },
  {
    name: 'type',
    title: 'Type',
  },
  // {
  //   name: 'delete',
  //   title: '',
  // },
];
