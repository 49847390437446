/* eslint-disable no-shadow */
enum EnumCombinationKeys {
  Top_Middle_Flop = '1',
  Promoter_Passive_Detractor = '2',
  Others = '3',
  No_combinations = '4',
}

export enum EnumCombinationValues {
  Top_Middle_Flop = 'Top-Middle-Flop',
  Promoter_Passive_Detractor = 'Promoter-Passive-Detractor',
  Others = 'Others',
  No_combinations = 'No Combinations',
}

export default EnumCombinationKeys;
