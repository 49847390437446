import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import { Container } from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import FormApillar from '../Form';

const CreateApillar: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (aPillar: any) => {
      try {
        const structure = {
          name: aPillar.title,
          categoryId: aPillar.categoryId,
          topics: aPillar.children?.map((topic: any) => {
            return {
              name: topic.title,
              categoryId: topic.categoryId,
              subtopics: topic.children?.map((subTopic: any) => {
                return {
                  name: subTopic.title,
                  categoryId: subTopic.categoryId,
                  questionIds: subTopic.children?.map((question: any) => {
                    return parseInt(question.questionid, 10);
                  }),
                };
              }),
            };
          }),
        };

        await api.post('/apillars', structure);
        history.push('/apillars');

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The apillar was defined successfully!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description:
            'An error occurred while creating A-Pillar, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <FormApillar handleSubmit={handleSubmit}></FormApillar>
    </Container>
  );
};

export default CreateApillar;
