/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import Select from 'react-select';
import SelectForm from '../../../../components/SelectForm';
import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../../components/FormInput';
import Button from '../../../../components/Button';

import { Container } from './styles';
import BackButton from '../../../../components/BackButton';
import getEncodedSurvey from '../../../../utils/getEncodedSurvey';
import TextArea from '../../../../components/TextAreaEb';
import getQuestionsWithTranslations from '../../../../utils/getQuestionsWithTranslations';
import getTranslatedSurvey from '../../../../utils/getSurveyTranslatedSurvey';
import getDecodedSurvey from '../../../../utils/getDecodedSurvey';

interface SurveyTranslation {
  languageId: number;
  surveyId: number;
  translation: string;
}

interface Language {
  languageId: number;
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  value: number;
  label: string;
}

interface State {
  surveyId: number;
  survey: any;
}

interface SelectProps {
  value: number;
  label: string;
}

const CreateSurveyTranslation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { state } = useLocation<State>();
  const { addToast } = useToast();

  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>();
  const [survey, setSurvey] = useState<any>();

  const [loading, setLoading] = useState(false);

  const [logos, setLogos] = useState<SelectProps[]>();
  const [urlLogo, setUrlLogo] = useState<string>('');

  const returnFieldName = useCallback(elementName => {
    const regexReplace = new RegExp('[^0-9a-zA-Z]+', 'g');
    const fieldName = elementName.replace(regexReplace, '');

    return fieldName;
  }, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async (): Promise<void> => {
      const responseSurvey = await api.get(`/surveys/${state?.surveyId}`);
      const responseLanguages = await api.get('languages');
      const languagesDropDown = responseLanguages.data
        .filter(
          (item: Language) =>
            !responseSurvey.data?.languages?.includes(item.languageId),
        )
        .map((languageDropDown: Language) => {
          return {
            ...languageDropDown,
            value: languageDropDown.languageId,
            label: languageDropDown.name,
          };
        });

      const surveyJson = responseSurvey.data;

      setSurvey(surveyJson);
      setLanguages(languagesDropDown);
    };
    fetchData();
    setLoading(false);
  }, [state?.surveyId]);

  /// GET LOGOS
  useEffect(() => {
    api.get('logos').then(response => {
      const logosDB = response.data.map((logo: any) => {
        return {
          value: logo.logoId,
          label: logo.originalname,
          filePath: `${response.request?.responseURL.replace(
            '/logos',
            '/files',
          )}/${logo?.filename}`,
        };
      });
      setLogos(logosDB);

      if (survey?.logo) {
        const logoSurveyTranslation = logosDB.find(
          (x: any) => x.value === survey.logo.id,
        );

        if (logoSurveyTranslation) {
          formRef.current?.setFieldValue('logoId', logoSurveyTranslation);
        }
      }
    });
  }, [survey]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        delete survey._id;
        delete survey.__v;
        delete survey.created_by;
        delete survey.updated_by;
        delete survey.created_at;
        delete survey.updated_at;

        const decodedSurvey = getDecodedSurvey(survey);

        const encodedSurvey = getEncodedSurvey(
          JSON.stringify(decodedSurvey),
          selectedLanguage,
        );

        const questionWithTranslations = await getQuestionsWithTranslations(
          encodedSurvey,
        );
        const logo = {
          id: data.logoId,
          filePath: urlLogo,
        };

        const translatedSurvey = await getTranslatedSurvey(
          encodedSurvey,
          questionWithTranslations,
          selectedLanguage?.languageId ?? 0,
          undefined,
          data,
        );

        const newSurveyTranslation = {
          languageId: selectedLanguage?.languageId,
          languageName: selectedLanguage?.name,
          surveyId: Number(state.surveyId),
          translation: translatedSurvey,
          logo,
        };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // languageId: Yup.string().required('Language is required'),
          // data.tran: Yup.string().required('survey title is required'),
          logoId: Yup.string().required('logo is required'),
        });

        schema.validate(newSurveyTranslation, {
          abortEarly: false,
        });

        // save survey translation
        await api.post('/surveyTranslations', newSurveyTranslation);

        // updating survey: get survey to update with the new languageId

        const surveyFromDatabase = {
          ...survey,

          languages: survey?.languages
            ? [...survey?.languages, newSurveyTranslation.languageId]
            : [newSurveyTranslation.languageId],
        };

        await api.put(`/surveys/${state?.surveyId}`, surveyFromDatabase);
        // end updating survey

        addToast({
          type: 'success',
          title: 'Success',
          description: 'Survey Translation was saved successfully!',
        });

        history.push({
          pathname: `/surveys/${state.surveyId}/surveyTranslations`,
          state: { surveyId: state.surveyId, survey },
        });
      } catch (err) {
        console.log('error', err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating survey translation, please try again.',
        });
      }
    },
    [addToast, history, selectedLanguage, state.surveyId, survey, urlLogo],
  );

  const handleLanguageChange = useCallback(async (e: Language | null) => {
    if (e) {
      setSelectedLanguage({
        ...e,
        languageId: e?.value ?? 0,
        name: e?.label ?? '',
      });
    }
  }, []);

  const handleLogoChange = useCallback(x => {
    setUrlLogo(x.filePath);
  }, []);

  return (
    <Container>
      <BackButton />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Add Survey Translation</h1>

        <h3>Select the language</h3>
        <Select options={languages} onChange={e => handleLanguageChange(e)} />

        <h2>Logo</h2>
        <SelectForm
          name="logoId"
          options={logos}
          onChange={e => handleLogoChange(e)}
          formatOptionLabel={(logo: any) => (
            <div className="logo-option">
              <img src={logo.filePath} alt="logo-image" />
              <span>{logo.label}</span>
            </div>
          )}
        />
        <div style={{ marginTop: 20 }}>
          <p>Survey Title: {survey?.title}</p>

          <Input
            type="text"
            name="surveyTitle"
            placeholder="Enter translation for survey title"
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <p>Survey Description: {survey?.description}</p>

          <Input
            type="text"
            name="surveyDescription"
            placeholder="Enter translation for survey description"
          />
        </div>
        {survey &&
          survey?.pages?.map((page: any) =>
            page?.elements?.map((element: any, i: number) =>
              element?.type === 'matrix' &&
              element?.titleLocation !== 'hidden' ? (
                <div key={i} style={{ marginTop: 30 }}>
                  <p>Matrix Title: {element.title}</p>
                  <Input
                    type="text"
                    name={returnFieldName(element.name)}
                    placeholder={`Enter translation for matrix: ${element.title}`}
                  />
                </div>
              ) : element?.type === 'html' ? (
                <div key={i} style={{ marginTop: 30 }}>
                  <p>
                    HTML Name: {element.name} {`>>`} Page: {i}
                  </p>
                  <TextArea
                    name={`${element.name}`}
                    defaultValue={element.html}
                  />
                </div>
              ) : element?.description ? (
                <div key={i} style={{ marginTop: 30 }}>
                  <p>
                    Description of question{' '}
                    <strong>{element.questionId}</strong> at Page: {i}
                  </p>
                  <p>Original description: {element.description}</p>
                  <TextArea
                    name={element.questionId.toString().replace('.', '_')}
                    defaultValue={element.description}
                  />
                </div>
              ) : (
                <div key={i}></div>
              ),
            ),
          )}

        <Button type="submit" width="200px">
          Save
        </Button>
      </Form>
    </Container>
  );
};
export default CreateSurveyTranslation;
