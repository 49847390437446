import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useQuery } from '../../../../hooks/queryString';
import api from '../../../../services/api';

import InfoText from './info';
import { Container, Content, Header } from './styles';

interface Translation {
  languageId: number;
  text: string;
}

interface GDPR {
  defaultLanguageId: number;
  gdprId: number;
  text: string;
  translations: Translation[];
}

const MoreInfo: React.FC = () => {
  const query = useQuery();
  const [isTso, setIsTSO] = useState(false);
  const [isSpiritHoch3, setIsSpiritHoch3] = useState(false);
  const [surveyName, setSurveyName] = useState('');
  const [logoPath, setLogoPath] = useState('');
  const [textGdpr, setTextGdpr] = useState('');
  const [headerCollor, setHeaderCollor] = useState<string>('#e0e0e0');
  const [faviconPathLocal, setFaviconPathLocal] = useState<string>('');

  useEffect(() => {
    const isTSOLocal = localStorage.getItem('isTso') !== 'false';
    const isSpiritHoch3Local =
      localStorage.getItem('isSpiritHoch3') !== 'false';

    const logoPathLocal = localStorage.getItem('logoPath');
    setFaviconPathLocal(localStorage.getItem('faviconPath') ?? '');
    const surveyNameLocal = localStorage.getItem('surveyName');

    setIsTSO(isTSOLocal);
    setIsSpiritHoch3(isSpiritHoch3Local);

    setSurveyName(surveyNameLocal ?? '');
    setLogoPath(logoPathLocal ?? '');

    const languageId = Number(query.get('lang'));
    const gdprId = Number(query.get('gdpr'));

    setHeaderCollor(window.location.hash);

    if (gdprId && gdprId > 0 && languageId && languageId > 0) {
      api.get(`gdpr/${gdprId}`).then(response => {
        const gdpr: GDPR = response.data;

        const text =
          gdpr.defaultLanguageId === languageId
            ? gdpr.text
            : gdpr.translations.some(x => x.languageId === languageId)
            ? gdpr.translations.find(x => x.languageId === languageId)?.text
            : 'DEFAULT';

        setTextGdpr(text ?? 'DEFAULT');
      });
    } else {
      setTextGdpr('DEFAULT');
    }
  }, [query]);

  useLayoutEffect(() => {
    const favicon2 = document.getElementById('favicon');
    document.title = surveyName ?? 'Victor';
    favicon2?.setAttribute('href', faviconPathLocal);
    // const prefix = process.env.NODE_ENV === 'development' ? 'http' : 'https';
    // if (isSpiritHoch3) {
    //   favicon2?.setAttribute(
    //     'href',
    //     `${prefix}://${window.location.host}/faviconsp3.ico`,
    //   );
    //   return;
    // }
    // // isTSO
    // if (isTso) {
    //   favicon2?.setAttribute(
    //     'href',
    //     `${prefix}://${window.location.host}/favicontso.ico`,
    //   );
    // } else {
    //   favicon2?.setAttribute(
    //     'href',
    //     `${prefix}://${window.location.host}/favicon.ico`,
    //   );
    // }
  }, [faviconPathLocal, surveyName]);

  return (
    <>
      <Header headerColor={headerCollor}>
        {/* <img src={logoPath} alt="Logo - Survey" /> */}
      </Header>
      <Container>
        <Content>
          {textGdpr === 'DEFAULT' ? ( // default
            <InfoText visible={true}></InfoText>
          ) : (
            ReactHtmlParser(textGdpr as string)
          )}
        </Content>
      </Container>
    </>
  );
};

export default MoreInfo;
