interface Column {
  name: string;
  title: string;
}

export const columns: Column[] = [
  {
    name: 'surveyTranslationId',
    title: 'Id',
  },
  {
    name: 'languageId',
    title: 'Language Id',
  },
  {
    name: 'languageName',
    title: 'Language Name',
  },
  {
    name: 'surveyName',
    title: 'Survey Name',
  },
  {
    name: 'surveyTitle',
    title: 'Survey Title',
  },
  { name: 'edit', title: '' },
  { name: 'delete', title: '' },
];
