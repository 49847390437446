import React, { useCallback, useEffect, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import Input from '../../../components/FormInput';
import Button from '../../../components/Button';

import { AddIcon, Container, RulesContainer } from './styles';

import Select from '../../../components/SelectForm';

import TableRows from '../tableRows';

import api from '../../../services/api';
import AutoCompleteInput from '../../../components/AutoCompleteInput';

interface Rule {
  schlagwort: string;
  standalone: string;
  negative_signaling_keyword: string;
  only_Q_List_Befragung: string;
  non_Q_List_Befragung: string;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: Rule[];
  handleSubmit: SubmitHandler;
}

interface SelectOptions {
  value: string;
  label: string;
}

const types: SelectOptions[] = [
  { value: 'customers', label: 'Customers' },
  { value: 'tso', label: 'TSO' },
  { value: 'managers', label: 'Managers' },
];

const FormTags: React.FC<Props> = props => {
  const [secondLevelTags, setSecondLevelTags] = useState<string[]>([]);
  const [rowsData, setRowsData] = useState<any[]>([]);

  const addTableRows = useCallback(() => {
    const rowsInput: Rule = {
      schlagwort: '',
      standalone: '',
      negative_signaling_keyword: '',
      only_Q_List_Befragung: '',
      non_Q_List_Befragung: '',
    };

    setRowsData([...rowsData, rowsInput]);
  }, [rowsData]);

  const deleteTableRows = useCallback(
    async (index: any) => {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
    },
    [rowsData],
  );

  const handleChange = useCallback(
    async (index, evnt) => {
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    },
    [rowsData],
  );

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      await api.get(`/tags/getAllSecondLevel`).then(response => {
        if (response && response.data) setSecondLevelTags(response.data);
      });
    };

    loadData();
  }, []);

  useEffect(() => {
    if (props.initialData) {
      setRowsData(props.initialData);
    } else {
      addTableRows();
    }
  }, [props.initialData]);

  useEffect(() => {}, [props.initialData]);

  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Category</h2>
        <Input type="text" name="category" placeholder="Enter the category" />

        <h2>Parent Category</h2>
        <AutoCompleteInput
          type="text"
          name="category_2"
          placeholder="Enter the parent category"
          suggestion={secondLevelTags}
        ></AutoCompleteInput>

        <h2>Type</h2>
        <Select name="type" options={types} defaultValue={types[0]}></Select>

        <h2>Rules</h2>
        {/*
        <div>
          <ul>
            <li>Schlagwort is required f.e: Zurückrufen</li>
            <li>Standalone can be 1 or empty f.e: 1</li>
            <li>Negative_signaling is not required f.e:telefonische</li>
            <li>Only_Q_List_Befragung is a list of questions splited by ,. f.e: F11089,F11225</li>
            <li>Non_Q_List_Befragung is a list of questions splited by ,. f.e: F11205, F11099</li>
          </ul>
        </div> */}

        <div
          style={{
            margin: '1.2rem',
          }}
        >
          <div hidden={true}>
            <Input type="text" name="rules" value={JSON.stringify(rowsData)} />
          </div>

          <RulesContainer>
            <table>
              <thead>
                <th>Schlagwort</th>
                <th>Standalone</th>
                <th>Negative_signaling</th>
                <th>Only_Q_List_Befragung</th>
                <th>Non_Q_List_Befragung</th>

                <button onClick={addTableRows} type={'button'}>
                  <AddIcon></AddIcon>
                </button>
              </thead>

              <tr></tr>
              <tbody>
                <TableRows
                  rowsData={rowsData}
                  deleteTableRows={deleteTableRows}
                  handleChange={handleChange}
                ></TableRows>
              </tbody>
            </table>
          </RulesContainer>
        </div>

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormTags;
