import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';
import FormCustomers from '../Form';

interface Customer {
  name: string;
  customerId: number;
  companyTypes: SelectProps;
  sectors: SelectProps;
  regions: SelectProps;
  employees: SelectProps;
  revenues: SelectProps;
}
interface SelectProps {
  value: number;
  label: string;
}

interface ParamTypes {
  id: string;
}

const EditCustomer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { addToast } = useToast();

  const [customer, setCustomer] = useState<Customer>();

  useEffect(() => {
    api.get(`/customers/${id}`).then(response => {
      const customerEdit: Customer = {
        customerId: response.data.customerId,
        name: response.data.name,
        companyTypes: response.data.company,
        sectors: response.data.sector,
        regions: response.data.region,
        employees: response.data.employee,
        revenues: response.data.revenue,
      };

      setCustomer(customerEdit);
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: Customer) => {
      try {
        const company =
          formRef.current?.getFieldRef('company').select?.state?.selectValue[0];

        const sector =
          formRef.current?.getFieldRef('sector').select?.state?.selectValue[0];

        const region =
          formRef.current?.getFieldRef('region').select?.state?.selectValue[0];

        const employee =
          formRef.current?.getFieldRef('employee').select?.state
            ?.selectValue[0];

        const revenue =
          formRef.current?.getFieldRef('revenue').select?.state?.selectValue[0];

        const editCustomer = {
          ...data,
          company,
          sector,
          region,
          employee,
          revenue,
          // countries: selectedCountries,
        };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Customer name is required'),
          company: Yup.object().required('Company is required'),
          sector: Yup.object().required('Sector is required'),
          region: Yup.object().required('Region is required'),
          employee: Yup.object().required('Number of employees is required'),
          revenue: Yup.object().required('Revenues is required'),
        });

        await schema.validate(editCustomer, {
          abortEarly: false,
        });

        const response = await api.put(`/customers/${id}`, editCustomer);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The custumer was edited successfully!',
        });

        history.push('/customers', {
          customerId: response.data.customerId,
          name: response.data.name,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing customer, please try again.',
        });
      }
    },
    [addToast, history, id],
  );

  return (
    <Container>
      <h1>Edit Customer</h1>
      <FormCustomers
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Save"
        initialData={customer}
      ></FormCustomers>
    </Container>
  );
};
export default EditCustomer;
