import EnumIntegrationStatusDataWarehouse from './enums/EnumIntegrationStatusDataWarehouse';

export default function getIntegrationStatusDescription(value: string): string {
  switch (value) {
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Canceled,
      )
    ]:
      return 'Canceled';
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Pending,
      )
    ]:
      return 'Pending';
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Languages,
      )
    ]:
      return 'Staging: Integrating Languages';
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Questions,
      )
    ]:
      return 'Staging: Integrating Questions';
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Alternatives,
      )
    ]:
      return 'Staging: Integrating Alternatives';
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Projects,
      )
    ]:
      return 'Staging: Integrating Projects';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Categories,
      )
    ]:
      return 'Staging: Integrating Categories';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Customers,
      )
    ]:
      return 'Staging: Integrating Customers';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Access,
      )
    ]:
      return 'Staging: Integrating Access';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Surveys,
      )
    ]:
      return 'Staging: Integrating Surveys';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Integrating_Results,
      )
    ]:
      return 'Staging: Integrating Results';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Staging_Preparing_Dw_Integration,
      )
    ]:
      return 'Staging: Preparing DW Integration';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Languages,
      )
    ]:
      return 'DW: Languages';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Access,
      )
    ]:
      return 'DW: Access';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Questions,
      )
    ]:
      return 'DW: Question';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Categories,
      )
    ]:
      return 'DW: Categories';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Alternatives,
      )
    ]:
      return 'DW: Alternatives';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Customers,
      )
    ]:
      return 'DW: Customers';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Projects,
      )
    ]:
      return 'DW: Projects';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Surveys,
      )
    ]:
      return 'DW: Surveys';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.DW_Integrating_Results,
      )
    ]:
      return 'DW: Results';

    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Finished,
      )
    ]:
      return 'Finished';
    case Object.keys(EnumIntegrationStatusDataWarehouse)[
      Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
        EnumIntegrationStatusDataWarehouse.Started,
      )
    ]:
      return 'Started';

    default:
      return 'finished';
  }
}
