/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { DeleteOutline } from '@material-ui/icons';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

interface Access {
  accessId: number;
  surveyId: number;
  name: string;
  segments: string[];
  isLocked: boolean;
}

interface Props {
  access: Access;
}

const DeleteButton: React.FC<Props> = ({ access }) => {
  const { addToast } = useToast();
  const history = useHistory();
  const [resultsCount, setResultsCount] = useState(0);

  const handleSubmit = useCallback(
    async (data: Access) => {
      try {
        const response = await api.get(`results/access/${access.accessId}`);
        const resultCount = response?.data?.length ?? 0;
        if (resultCount > 0) {
          const userWantDelete = confirm(
            `This survey contains ${resultCount} result(s). If you delete this access,
            ALL RESULTS WILL BE DELETED!

            Are you sure you want to delete the access ${data.accessId}?`,
          );

          if (userWantDelete) {
            const responseAccessDelete = await api.delete(
              `/accesses/${data.accessId}`,
            );

            if (responseAccessDelete === null) {
              addToast({
                type: 'error',
                title: 'Survey Locked',
                description: 'Survey is locked. You can not delete the access.',
              });
            } else {
              const responseResultsDelete = await api.delete(
                `/results/access/${data.accessId}`,
              );

              if (responseResultsDelete === null) {
                addToast({
                  type: 'error',
                  title: 'Survey Locked',
                  description:
                    'Survey is locked. You can not delete the access.',
                });
              } else {
                addToast({
                  type: 'success',
                  title: 'Success',
                  description: `The access ${data.accessId} and all it's results were removed successfully!`,
                });

                // history.push(`/surveys/-1/accesses`); // workaround to refresh grid. the useEffect is affected when surveyId is changed
                history.push(
                  `/surveys/${data.surveyId}/accesses/edit/${data.accessId}`,
                );
                history.push(`/surveys/${data.surveyId}/accesses`);
              }
            }
          }
        } else {
          // eslint-disable-next-line no-restricted-globals, no-alert
          const userWantDelete = confirm(
            `Are you sure you want to delete the access ${data.accessId}?`,
          );
          if (userWantDelete) {
            const responseDelete = await api.delete(
              `/accesses/${data.accessId}`,
            );

            if (responseDelete.data === null) {
              addToast({
                type: 'error',
                title: 'Survey Locked',
                description: 'Survey is locked. You can not delete the access.',
              });
            } else {
              addToast({
                type: 'success',
                title: 'Success',
                description: 'The access was removed successfully!',
              });
              // history.push(`/surveys/-1/accesses`); // workaround to refresh grid. the useEffect is affected when surveyId is changed
              history.push(
                `/surveys/${data.surveyId}/accesses/edit/${data.accessId}`,
              );
              history.push(`/surveys/${data.surveyId}/accesses`);
            }
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          console.log(`err`, err);
          return;
        }

        console.log(`err`, err);

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while deleting the access, please try again.',
        });
      }
    },
    [access.accessId, addToast, history],
  );

  return (
    <>
      <Link to="#" onClick={() => handleSubmit(access)}>
        <DeleteOutline />
      </Link>
    </>
  );
};

export default DeleteButton;
