import React, { useCallback, useState } from 'react';
import Fuse from 'fuse.js';
import { FiAlertTriangle, FiXCircle } from 'react-icons/fi';
import { Container, DivWarning } from './styles';

interface Props {
  name: string;
  setSelectedTags: any;
  tags: any[];
  existentTagsInDatabase: any[];
}

const TagsSelect: React.FC<Props> = ({
  tags,
  setSelectedTags,
  existentTagsInDatabase,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showSimillarTags, setShowSimillarTags] = useState(false);
  const [simillarTags, setSimillarTags] = useState<string[]>([]);

  const removeTag = useCallback(
    index => {
      setSelectedTags((prevValues: any) =>
        prevValues.filter((el: any, i: number) => i !== index),
      );

      setSimillarTags([]);
      setShowSimillarTags(false);
    },
    [setSelectedTags],
  );

  const handleKeyDown = useCallback(
    e => {
      setShowWarning(false);
      // If user did not press enter key, return
      if (e.key !== 'Enter') return;
      // Get the value of the input
      const { value } = e.target;

      // If the value is empty, return
      if (!value.trim()) return;

      e.preventDefault();

      let tagAlreadyExists;
      // Add the value to the tags array
      tagAlreadyExists = tags.find((tag: any) => tag === value);
      if (tagAlreadyExists) {
        setShowWarning(true);

        return;
      }

      tagAlreadyExists = existentTagsInDatabase.find(
        (tag: any) => tag === value,
      );
      if (tagAlreadyExists) {
        setShowWarning(true);

        return;
      }

      const options = {
        threshold: 0.3,
        location: 0,
        distance: 100,
        includeMatches: true,
        includeScore: true,
        useExtendedSearch: true,
      };

      const fuse = new Fuse([...existentTagsInDatabase, ...tags], options);
      const searchResult = fuse.search(value);

      if (searchResult.length > 0) {
        setShowSimillarTags(true);
        const similarWords: any[] = searchResult
          .filter(result => !simillarTags.includes(result.item))
          .map(word => word.item);

        setSimillarTags((prevValues: any) => [...prevValues, ...similarWords]);
      }

      // Add the value to the tags array
      setSelectedTags((prevValues: any) => [...prevValues, value]);
      // Clear the input
      e.target.value = '';
    },
    [existentTagsInDatabase, setSelectedTags, simillarTags, tags],
  );

  const handleCloseWarning = useCallback(() => {
    setShowSimillarTags(false);
    setSimillarTags([]);
  }, []);
  return (
    <Container>
      <div className="tags-input-container">
        {tags.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        <input
          onKeyDown={handleKeyDown}
          type="text"
          className="tags-input"
          placeholder="Type something"
        />
      </div>
      {showWarning && (
        <span style={{ color: 'red' }}>* This tag already exists.</span>
      )}
      {showSimillarTags && (
        <DivWarning>
          <button onClick={handleCloseWarning} type="button">
            <FiXCircle size={18} />
          </button>

          <h3>
            <p>
              <FiAlertTriangle /> WARNING <FiAlertTriangle />
            </p>
            <p>
              Tags that you typed are simillar to some of the existent tags in
              the database or you typed before.
            </p>
            Please be sure that you want to create new tags as you typed or you
            can use one of the existents listed bellow.
          </h3>
          {simillarTags.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </DivWarning>
      )}
    </Container>
  );
};

export default TagsSelect;
