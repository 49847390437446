/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, {
  useEffect,
  useState,
  useCallback,
  Children,
  useRef,
} from 'react';
import { FiXCircle } from 'react-icons/fi';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  removeNodeAtPath,
  getFlatDataFromTree,
  getNodeAtPath,
} from '@nosferatu500/react-sortable-tree';
import { Box, Modal } from '@material-ui/core';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import Input from '../../../components/FormInput';
import {
  Container,
  SortableTreeStyled,
  SortableList,
  ContainerSortableTree,
  ContainerSortableList,
  ContainerStructure,
  Loader,
  ContainerHeader,
  ContainerAddButton,
  DivModal,
  DivButtonEditAnswer,
  ButtonCreateCategory,
  AddButton,
} from './styles';
import api from '../../../services/api';
import ButtonForm from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import EnumCategoryType from '../../../utils/enums/EnumCategoryType';

interface TreeData {
  title: string;
  isTwin: boolean;
  type: string;
  level: number;
  categoryid?: number;
  questionId?: number;
  children: Children[];
}

interface Children {
  title: string;
  isTwin: boolean;
  type?: string;
  level?: number;
  categoryId?: number;
  questionId?: number;
  children?: Children[];
}

interface Props {
  initialData?: any;
  handleSubmit: any;
}

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FormApillar: React.FC<Props> = props => {
  const { addToast } = useToast();
  const [questions, setQuestions] = useState<any>([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState<boolean>(false);
  const [categoriesFromDb, setCategoriesFromDb] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);

  const [treeQuestions, setTreeQuestions] = useState<any[]>([]);
  const [treeStructure, setTreeStructure] = useState<any>(
    props.initialData ?? [],
  );
  const [treeCategories, setTreeCategories] = useState<any[]>([]);

  const [stringQueryQuestions, setStringQueryQuestions] = useState('');
  const [stringQueryCategories, setStringQueryCategories] = useState('');
  const [questionListDisabled, setQuestionListDisabled] = useState(false);

  const [questionNamesUpdatedWhenEditing, setQuestionNamesUpdatedWhenEditing] =
    useState(false);

  const [questionListUpdatedWhenEditing, setQuestionListUpdatedWhenEditing] =
    useState(false);

  const [searchParam] = useState(['title']);
  const formRef = useRef<FormHandles>(null);

  const [open, setOpen] = React.useState(false);
  const [refreshCategory, setRefreshCategory] = React.useState(true);
  const handleOpenModal = (): void => setOpen(true);
  const handleCloseModal = (): void => setOpen(false);

  const externalNodeType = 'ebNodeType';

  useEffect(() => {
    if (refreshCategory) {
      api.get(`categories`).then(response => {
        setCategoriesFromDb(response.data);
        setRefreshCategory(false);
      });
    }
  }, [refreshCategory]);

  // disable question list if there is no SubTopic
  useEffect(() => {
    if (treeStructure) {
      setQuestionListDisabled(
        treeStructure.some((aPillar: any) =>
          aPillar.children?.some((topic: any) => topic.children?.length > 0),
        ),
      );
    }
  }, [treeStructure]);

  // questions
  useEffect(() => {
    setIsLoadingQuestions(true);
    api
      .post(`/questions/getByQuestionType`, { type: 'Scale' })
      .then(result => {
        const questionList = result.data.map((q: any) => {
          const questionFormatted = {
            questionid: q.questionId,
            title: `${q.questionId}-${q.text}`,
            type: 'Question',
          };
          return questionFormatted;
        });

        setIsLoadingQuestions(false);
        setTreeQuestions(questionList);
        setQuestions(questionList);
      })
      .finally(() => {
        setIsLoadingQuestions(false);
      });
  }, []);

  // update question name when Editing
  useEffect(() => {
    if (
      !questionNamesUpdatedWhenEditing &&
      props.initialData &&
      questions &&
      questions.length > 0
    ) {
      const aPillar = treeStructure[0];
      aPillar.children = aPillar.children?.map((topic: any) => {
        topic.children = topic.children.map((subtopic: any) => {
          subtopic.children = subtopic.children.map((question: any) => {
            question.title = questions.find(
              (x: any) => x.questionid === question.questionid,
            )?.title;
            return question;
          });

          return subtopic;
        });

        return topic;
      });

      setTreeStructure([aPillar]);

      setQuestionNamesUpdatedWhenEditing(true);
    }
  }, [
    props.initialData,
    questionNamesUpdatedWhenEditing,
    questions,
    treeStructure,
  ]);

  useEffect(() => {
    let categoryList: any = [];

    // get all categories from database, but remove those already added to structure
    if (treeStructure && treeStructure.length > 0) {
      // We can improve this, creating a method returning flat list of questions

      // extract flat list  categories from all A-Pillars
      const structureApillar = treeStructure.filter(
        (item: any) => item !== undefined,
      );

      // extract flat list  categories from all Topics
      const structureTopic = structureApillar
        .flatMap(({ children }: any) => children)
        .filter((item: any) => item !== undefined);

      // extract flat list categories from all SubTopics
      const structureSubTopic = structureTopic
        .flatMap(({ children }: any) => children)
        .filter(
          (item: any) =>
            item !== undefined && item.type === EnumCategoryType.Subtopic,
        );

      // We can improve this, creating a method returning flat list of questions
      // flat list with all categories from structure
      const categoryStructureFlat = [
        ...structureApillar,
        ...structureTopic,
        ...structureSubTopic,
      ];

      // get categories that haven't been added to the structure yet
      const categoryListRemaining = categoriesFromDb
        .filter((category: any) => {
          return !categoryStructureFlat.some((item: any) => {
            return item.categoryId === category.categoryId;
          });
        })
        .filter((item: any) => item !== undefined);

      categoryList = [...categoryListRemaining];
    } else {
      categoryList = categoriesFromDb;
    }

    setCategories(categoryList);
    setTreeCategories(categoryList);
  }, [treeStructure, categoriesFromDb]);

  // remove duplicate questions (edit)
  useEffect(() => {
    // get all categories from database, but remove those already added to structure
    if (
      questions &&
      questions.length > 0 &&
      treeStructure &&
      treeStructure.length > 0 &&
      !questionListUpdatedWhenEditing
    ) {
      // We can improve this, creating a method returning flat list of questions

      // extract flat list  categories from all A-Pillars
      const structureApillar = treeStructure.filter(
        (item: any) => item !== undefined,
      );

      // extract flat list  categories from all Topics
      const structureTopic = structureApillar
        .flatMap(({ children }: any) => children)
        .filter((item: any) => item !== undefined);

      // extract flat list categories from all SubTopics
      const structureSubTopic = structureTopic
        .flatMap(({ children }: any) => children)
        .filter(
          (item: any) =>
            item !== undefined && item.type === EnumCategoryType.Subtopic,
        );

      const structureQuestions = structureSubTopic
        .flatMap(({ children }: any) => children)
        .map((x: any) => {
          return x.questionid;
        });

      const questionFiltered = questions.filter(
        (x: any) => !structureQuestions.includes(x.questionid),
      );

      setQuestions(questionFiltered);
      setTreeQuestions(questionFiltered);
      setQuestionListUpdatedWhenEditing(true);
    }
  }, [questionListUpdatedWhenEditing, questions, treeStructure]);

  // rules for drop questions and categories into structure tree
  const canDrop = useCallback((item: any, grid: string): boolean => {
    // RULE: root node can´t have siblings
    if (
      item.nextParent === null &&
      item.nextPath.length === 1 &&
      grid === 'main'
    ) {
      return false;
    }

    // RULE: Question parent must be SubTopic
    if (
      item.node.type === EnumCategoryType.Question &&
      item.nextParent?.type !== EnumCategoryType.Subtopic &&
      grid === 'main'
    ) {
      return false;
    }

    // RULE: Question can't have children
    if (item.nextParent?.type === EnumCategoryType.Question) {
      return false;
    }

    // RULE: Questions can't have categories as siblings - When the user try to drop a Question
    const nextParentHasNotOnlyQuestions = item.nextParent?.children.some(
      (c: any) => c.type !== EnumCategoryType.Question,
    );

    if (
      nextParentHasNotOnlyQuestions &&
      item.node.type === EnumCategoryType.Question
    ) {
      return false;
    }

    // RULE: Categories and questions can't be siblings - When the user try to drop a Categorie
    const nextParentHasQuestions = item.nextParent?.children.some(
      (c: any) => c.type === EnumCategoryType.Question,
    );

    if (
      nextParentHasQuestions &&
      item.node.type !== EnumCategoryType.Question
    ) {
      return false;
    }

    // RULE: Topic that has questions as children can't be dropped at A-Pillar's level
    if (
      item.nextPath.length === 1 &&
      item.node?.children?.some(
        (c: any) => c.type === EnumCategoryType.Question,
      )
    ) {
      return false;
    }

    // Categories can't be dropped into 4th level
    if (
      item.nextPath.length === 4 &&
      item.node.type !== EnumCategoryType.Question
    ) {
      return false;
    }

    // RULE: Can't drop category on questions
    if (
      (item.node.type === EnumCategoryType.Apillar ||
        item.node.type === EnumCategoryType.Topic) &&
      grid === 'questions'
    ) {
      return false;
    }

    // RULE: Can't drop question on categories
    if (item.node.type === EnumCategoryType.Question && grid === 'categories') {
      return false;
    }

    return true;
  }, []);

  const search = useCallback(
    (items: any[], searchType) => {
      const stringQuery =
        searchType === 'questions'
          ? stringQueryQuestions
          : stringQueryCategories;
      return items?.filter((item: any) => {
        return searchParam?.some(newItem => {
          return (
            item &&
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(stringQuery.toLowerCase()) > -1
          );
        });
      });
    },
    [searchParam, stringQueryCategories, stringQueryQuestions],
  );

  const setValueAfterMoving = useCallback(
    (movingType: string) => {
      const arrayToFilter = movingType === 'questions' ? questions : categories;
      const flatData = getFlatDataFromTree({
        treeData: treeStructure,
        getNodeKey: ({ node }: any) => node.id,
        ignoreCollapsed: false,
      }).map(({ node, path, getNodeKey }: any) => ({
        title: node.title,
        questionid: node.id,
        parent: path.length > 1 ? path[path.length - 2] : null,

        children: `${getNodeAtPath({
          treeData: treeStructure,
          path: [1],
          questionid: node.id,
          getNodeKey,
        })}`,
      }));

      return arrayToFilter.filter((c: TreeData) => {
        return !flatData!.some((x: TreeData) => {
          return x?.title === c?.title;
        });
      });
    },
    [categories, questions, treeStructure],
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  // change type (A-pillar, topic, subtopic) acoording level and set removeButton for each node
  const createNewProps = useCallback(
    (node, path) => {
      // remove button and set type

      let listCategories: any = [];
      let listQuestions: any = [];

      // remove button
      const buttons = [
        <button
          key=""
          onClick={() => {
            const nodeWithoutChildren = {
              _id: node._id,
              categoryId: node.categoryId,
              questionid: node.questionid,
              title: node.title,
              type: node.type,
            };

            // check if node has children and child type to remove and put it back to original list
            if (node.children?.length > 0) {
              switch (node.type) {
                case EnumCategoryType.Apillar: {
                  // remove A-pillar node and get its children to remove

                  // get all Topic from A-pillar to remove
                  const listTopics = node.children.flatMap((c: any) => ({
                    ...c,
                  }));

                  // get all subtopics from Topic of A-pillar to remove
                  const listSubtopics = listTopics
                    .flatMap(({ children }: any) => children)
                    ?.filter(
                      (i: any) =>
                        i !== undefined && i.type !== EnumCategoryType.Question,
                    );

                  // get all questions from Topic of A-pillar to remove
                  const listQuestionsOfTopic = listTopics
                    .flatMap(({ children }: any) => children)
                    ?.filter(
                      (i: any) =>
                        i !== undefined && i.type === EnumCategoryType.Question,
                    );

                  // get all questions from SubTopic of A-pillar to remove
                  const listQuestionsOfSubtopic = listSubtopics
                    .flatMap(({ children }: any) => children)
                    ?.filter((i: any) => i !== undefined);

                  const listTopicsWithoutChildren = listTopics.map(
                    (obj: any) => {
                      return {
                        _id: obj._id,
                        categoryId: obj.categoryId,
                        questionid: obj.questionid,
                        title: obj.title,
                        type: obj.type,
                      };
                    },
                  );

                  const listSubTopicsWithoutChildren = listSubtopics.map(
                    (obj: any) => {
                      return {
                        _id: obj._id,
                        categoryId: obj.categoryId,
                        questionid: obj.questionid,
                        title: obj.title,
                        type: obj.type,
                      };
                    },
                  );

                  listCategories = [
                    nodeWithoutChildren,
                    ...listTopicsWithoutChildren,
                    ...listSubTopicsWithoutChildren,
                  ];

                  listQuestions = [
                    ...listQuestionsOfTopic,
                    ...listQuestionsOfSubtopic,
                  ];
                  break;
                }
                case EnumCategoryType.Topic: {
                  // remove A-pillar node and get its children to remove

                  // get all SubTopics from Topic to remove
                  const listSubtopics = node.children
                    .flatMap((c: any) => ({ ...c }))
                    .filter(
                      (i: any) =>
                        i !== undefined && i.type !== EnumCategoryType.Question,
                    );

                  const listQuestionsOfTopic = node.children
                    .flatMap((c: any) => ({
                      ...c,
                    }))
                    ?.filter(
                      (i: any) =>
                        i !== undefined && i.type === EnumCategoryType.Question,
                    );

                  const listQuestionsOfSubtopic = listSubtopics
                    .flatMap(({ children }: any) => children)
                    ?.filter((i: any) => i !== undefined);

                  const listSubTopicsWithoutChildren = listSubtopics.map(
                    (obj: any) => {
                      return {
                        _id: obj._id,
                        categoryId: obj.categoryId,
                        questionid: obj.questionId,
                        title: obj.title,
                        type: obj.type,
                      };
                    },
                  );

                  listCategories = [
                    nodeWithoutChildren,
                    ...listSubTopicsWithoutChildren,
                  ];

                  listQuestions = [
                    ...listQuestionsOfTopic,
                    ...listQuestionsOfSubtopic,
                  ];

                  break;
                }
                case EnumCategoryType.Subtopic: {
                  const listQuestionsOfSubtopic = node.children
                    .flatMap((c: any) => ({ ...c }))
                    .filter(
                      (i: any) =>
                        i !== undefined && i.type === EnumCategoryType.Question,
                    );

                  listCategories = [nodeWithoutChildren];

                  listQuestions = [...listQuestionsOfSubtopic];
                  break;
                }
                default: {
                  break;
                }
              }
            }

            if (
              (!node.children || node.children?.length === 0) &&
              node.type === EnumCategoryType.Question
            ) {
              listQuestions.push(nodeWithoutChildren);
            } else if (
              (!node.children || node.children?.length === 0) &&
              node.type !== EnumCategoryType.Question
            ) {
              listCategories.push(nodeWithoutChildren);
            }

            const treeCategoriesLocal = treeCategories;
            treeCategoriesLocal.push(...listCategories);
            setTreeCategories(treeCategoriesLocal);

            const treeQuestionsLocal = treeQuestions;
            treeQuestionsLocal.push(...listQuestions);
            setTreeQuestions(treeQuestionsLocal);

            setTreeStructure(
              removeNodeAtPath({
                treeData: treeStructure,
                path,
                getNodeKey,
              }),
            );
          }}
        >
          <FiXCircle />
        </button>,
      ];

      if (node.type === EnumCategoryType.Question) {
        return {
          ...node,
          questionid: node.questionId,
          title: `${EnumCategoryType.Question}: ${node.title}`,
          buttons,
        };
      }

      // add properties to strucutre item
      switch (path.length) {
        case 1: {
          node.type = EnumCategoryType.Apillar; // eslint-disable-line no-param-reassign
          return {
            buttons,
            categoryid: node.categoryId,
            title: `${EnumCategoryType.Apillar}: ${node.title}`,
            type: EnumCategoryType.Apillar,
            path,
          };
        }
        case 2: {
          node.type = EnumCategoryType.Topic; // eslint-disable-line no-param-reassign
          return {
            buttons,
            categoryid: node.categoryId,
            title: `${EnumCategoryType.Topic}: ${node.title}`,
            type: EnumCategoryType.Topic,
            path,
          };
        }
        case 3: {
          node.type = EnumCategoryType.Subtopic; // eslint-disable-line no-param-reassign
          return {
            buttons,
            categoryid: node.categoryId,
            title: `${EnumCategoryType.Subtopic}: ${node.title}`,
            type: EnumCategoryType.Subtopic,
            path,
          };
        }
        default: {
          return node;
        }
      }
    },
    [treeCategories, treeQuestions, treeStructure],
  );

  const validateAndSubmit = useCallback(async () => {
    if (!treeStructure || !treeStructure[0]) {
      addToast({
        type: 'info',
        title: 'Validation failed',
        description: 'Empty structure.',
      });
      return;
    }

    const aPillar = treeStructure[0];

    if (
      !(
        aPillar.children &&
        aPillar.children.length > 0 &&
        aPillar.children.every((topic: any) => {
          return (
            topic.children &&
            topic.children.length > 0 &&
            topic.children.every(
              (subTopic: any) =>
                subTopic.children && subTopic.children.length > 0,
            )
          );
        })
      )
    ) {
      addToast({
        type: 'info',
        title: 'Validation failed',
        description: 'Invalid structure.',
      });
      return;
    }

    props.handleSubmit(aPillar);
  }, [addToast, props, treeStructure]);

  const handleSubmitCategory = useCallback(
    (data: any) => {
      const { title } = data;

      const bodyPostCategory: any = {
        title,
        type: 'Topic',
      };

      const checkDuplicates = categoriesFromDb.filter(
        (category: any) => category.title === title,
      );

      if (checkDuplicates.length > 0) {
        addToast({
          type: 'info',
          title: 'Category already exists',
          description: 'The system already has some category with this title',
        });
      } else {
        api
          .post(`/categories/`, bodyPostCategory)
          .then(response => {
            if (response.data) {
              addToast({
                type: 'success',
                title: 'Created category',
                description: `The category ${title} was created successfully.`,
              });
            }
            setRefreshCategory(true);
          })
          .catch(error => {
            console.log(error);
            addToast({
              type: 'error',
              title: 'Registration Error',
              description:
                'An error occurred while creating the category, please try again.',
            });
          });
      }

      handleCloseModal();
    },
    [addToast, categoriesFromDb],
  );

  return (
    <Container>
      <h1>A-Pillar</h1>

      <span>Setup the structure here...</span>
      <ContainerSortableTree>
        <SortableTreeStyled
          style={{ height: 410 }}
          canDrop={(e: any) => canDrop(e, 'main')}
          treeData={treeStructure}
          maxDepth={4}
          rowHeight={55}
          onChange={(treeData: any) => setTreeStructure(treeData)}
          dndType={externalNodeType}
          shouldCopyOnOutsideDrop={false}
          generateNodeProps={({ node, path }: any) =>
            createNewProps(node, path)
          }
        />
      </ContainerSortableTree>

      <ContainerStructure>
        <ContainerSortableList>
          <ContainerHeader>
            <div>
              <h4>Categories</h4>
              <input
                name={'inputSearchCategories'}
                placeholder={'search...'}
                onChange={event => setStringQueryCategories(event.target.value)}
              />
            </div>
            <ContainerAddButton>
              <AddButton type="button" onClick={handleOpenModal}>
                <AddCircleOutlineIcon></AddCircleOutlineIcon>
              </AddButton>
            </ContainerAddButton>
          </ContainerHeader>

          <SortableList
            treeData={search(treeCategories, 'categories')}
            dndType={externalNodeType}
            onChange={() =>
              setTreeCategories(setValueAfterMoving('categories'))
            }
            shouldCopyOnOutsideDrop={false}
            canDrop={(e: any) => canDrop(e, 'categories')}
          />
        </ContainerSortableList>

        <ContainerSortableList>
          <h4>
            Questions{' '}
            {!questionListDisabled &&
              '(Set "A-Pillar", "Topic" and "Subtopic" to enable Drag & Drop Questions)'}
          </h4>
          <input
            name={'inputSearchQuestions'}
            placeholder={'search...'}
            onChange={event => setStringQueryQuestions(event.target.value)}
          />
          <SortableList
            canDrag={questionListDisabled}
            treeData={search(treeQuestions, 'questions')}
            dndType={externalNodeType}
            onChange={() => setTreeQuestions(setValueAfterMoving('questions'))}
            shouldCopyOnOutsideDrop={false}
            canDrop={(e: any) => canDrop(e, 'questions')}
          ></SortableList>
          {isLoadingQuestions && (
            <Loader>
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Loader>
          )}
        </ContainerSortableList>
      </ContainerStructure>

      {/* Define new categories */}
      <DivModal>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box css={styleModal}>
            <>
              <Form ref={formRef} onSubmit={handleSubmitCategory}>
                <h2>Title</h2>
                <Input name="title" placeholder="Enter the title" />

                <DivButtonEditAnswer>
                  <ButtonCreateCategory type="submit">
                    {'Create Category'}
                  </ButtonCreateCategory>
                </DivButtonEditAnswer>
              </Form>
            </>
          </Box>
        </Modal>
      </DivModal>

      <ButtonForm
        type="submit"
        width="200px"
        height="50px"
        marginRight="30px"
        onClick={() => {
          validateAndSubmit();
        }}
      >
        Save
      </ButtonForm>
    </Container>
  );
};

export default FormApillar;
