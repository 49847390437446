export const columns: any[] = [
  {
    name: 'externalLinkId',
    title: 'ID',
  },

  {
    name: 'name',
    title: 'Name',
  },

  {
    name: 'code',
    title: 'Code',
  },
  {
    name: 'copyUrl',
    title: '',
  },
  // { name: 'edit', title: '' },
  {
    name: 'delete',
    title: '',
  },
];
