import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { Container, Content, Button, DeleteIcon } from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface Category {
  categoryId: number;
  title: string;
  type: string;
}

const Category: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: true },
    { columnName: 'categoryId', width: 120 },
  ]);

  const handleDelete = useCallback(
    (row: any) => {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const userWantDelete = confirm(
        `Are you sure you want to delete the category ${row.categoryId} - ${row.title}?`,
      );

      if (userWantDelete) {
        api.delete(`/categories/${row.categoryId}`).then(response => {
          if (response.data) {
            if (response.data.deleted) {
              addToast({
                type: 'success',
                title: 'Success',
                description: `The categorory ${row.categoryId} was deleted successfully!`,
              });
              history.push('/projects');
              history.push('/categories');
            } else {
              addToast({
                type: 'info',
                title: 'Can not be deleted',
                description: response.data.message,
              });
            }
          } else {
            addToast({
              type: 'error',
              title: 'Deleting Error',
              description: `An error occurred while deleting this category, please try again.`,
            });
          }
        });
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'delete',
      label: 'Delete',
      onClick: handleDelete,
      icon: <DeleteIcon />,
      width: 120,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('categories').then(response => {
      setCategories(
        response.data.map((category: Category) => {
          return {
            title: category.title,
            categoryId: category.categoryId,
            type: category.type,
          };
        }),
      );
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Categories (structure) </h1>

      <Link
        to={{
          pathname: `/categories/new`,
        }}
      >
        <Button variant="contained" type="button">
          New Category
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={categories}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          idName={'categoryId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default Category;
