import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';
import { Container, Content, Button } from './styles';
import api from '../../services/api';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { useToast } from '../../hooks/toast';

interface Customer {
  customerId: number;
  name: string;
  employee?: string;
  sector?: string;
  revenue?: string;
  region?: string;
  company?: string;
  countProjects: number;
}

const Customers: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'customerId', width: 200 },
    { columnName: 'delete', width: 80 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/customers/edit/${row.customerId}`);
    },
    [history],
  );

  const handleClickDelete = useCallback(
    (row: any) => {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the customer ${row.customerId}?`,
        );

        if (userWantDelete) {
          api.delete(`/customers/${row.customerId}`).then(response => {
            if (response.data === true) {
              if (row.customerId !== undefined) {
                row?.api?.updateRows([
                  { customerId: row.customerId, _action: 'delete' },
                ]);
              }

              history.push('/projects');
              history.push('/customers');
            } else {
              addToast({
                type: 'info',
                title: 'Customer is related to a project',
                description: `You can not delete this customer because it belongs to a project.`,
              });
            }
          });
        }
      } catch (err) {
        if (err) {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Updating Error',
            description: `An error occurred while deleting this customer, please try again.`,
          });
        }
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: 'Edit',
      onClick: handleClickEdit,
      icon: <EditOutlined />,
    },

    {
      columnName: 'delete',
      label: 'Delete',
      onClick: handleClickDelete,
      icon: <DeleteOutline />,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('customers').then(response => {
      setCustomers(response.data);
    });
    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Customers</h1>

      <Link to="/customers/new">
        <Button variant="contained" type="button">
          New Customer
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={customers}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'customerId'}
        ></Table>
      </Content>
    </Container>
  );
};
export default Customers;
