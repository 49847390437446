import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiCopy } from 'react-icons/fi';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns } from './columns';

import { Container, Content, Button, EditIcon, DeleteIcon } from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface AnalyticsPageLink {
  customerSector: string;
  customerRegion: string;
  customerNumberOfEmployees: string;
  customerName: string;
  projectName: string;
  projectType: string;
  year: string;
  surveyName: string;
  questionIds: number[];
}

const AnalyticsPageLinks: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [analyticsExternalLinks, setAnalyticsExternalLinks] = useState<
    AnalyticsPageLink[]
  >([]);

  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'externalLinkId', width: 120 },
  ]);

  const handleClickUrlCopy = useCallback((row: any) => {
    let host = `${window.location.hostname}`;

    if (host === 'localhost') {
      host = `http://localhost:3001/bubblechart`;
    } else {
      host = `${process.env.REACT_APP_VISUALIZATION_URL}/bubblechart`;
    }
    const server = `${host}`;

    navigator.clipboard.writeText(
      `${server}/${row.externalLinkId}/${row.code}`,
    );
  }, []);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/analyticsLink/edit/${row.externalLinkId}`);
    },
    [history],
  );

  const handleDelete = useCallback(
    (row: any) => {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const userWantDelete = confirm(
        `Are you sure you want to delete the link ${row.externalLinkId} - ${row.name}?`,
      );

      if (userWantDelete) {
        api.delete(`/externalLinks/${row.externalLinkId}`).then(response => {
          if (response.data) {
            addToast({
              type: 'success',
              title: 'Success',
              description: 'The link was deleted successfully!',
            });

            history.push('/projects');
            history.push('/analytics');
          } else {
            addToast({
              type: 'error',
              title: 'Deleting Error',
              description: `An error occurred while deleting this link, please try again.`,
            });
          }
        });
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'copyUrl',
      label: 'Copy Link',
      onClick: handleClickUrlCopy,
      icon: <FiCopy />,
      width: 120,
    },

    {
      columnName: 'edit',
      label: 'Edit',
      onClick: handleClickEdit,
      icon: <EditIcon />,
      width: 120,
    },

    {
      columnName: 'delete',
      label: 'Delete',
      onClick: handleDelete,
      icon: <DeleteIcon />,
      width: 120,
    },
  ]);

  useEffect(() => {
    setLoading(true);

    api.get('externalLinks').then(response => {
      setAnalyticsExternalLinks(
        response.data.map((externalLink: any) => {
          return {
            ...externalLink,
          };
        }),
      );
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1> Analytics Links </h1>

      <Link
        to={{
          pathname: `/analyticsLink/new`,
        }}
      >
        <Button variant="contained" type="button">
          New Analytics External Link
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={analyticsExternalLinks}
          selectionProp={selectedRowIds?.map(String)}
          multiSelection={false}
          setSelectedRowId={setSelectedRowIds}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'externalLinkId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default AnalyticsPageLinks;
