import React from 'react';

import { DeleteOutline } from '@material-ui/icons';

interface Props {
  rowsData: any;
  deleteTableRows: any;
  handleChange: any;
}

const TableRows: React.FC<Props> = ({
  rowsData,
  deleteTableRows,
  handleChange,
}) => {
  return rowsData.map((data: any, index: any) => {
    const {
      schlagwort,
      standalone,
      negative_signaling_keyword,
      only_Q_List_Befragung,
      non_Q_List_Befragung,
    } = data;
    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={schlagwort}
            onChange={evnt => handleChange(index, evnt)}
            name="schlagwort"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={standalone}
            onChange={evnt => handleChange(index, evnt)}
            name="standalone"
            className="form-control"
          />{' '}
        </td>

        <td>
          <input
            type="text"
            value={negative_signaling_keyword}
            onChange={evnt => handleChange(index, evnt)}
            name="negative_signaling_keyword"
            className="form-control"
          />{' '}
        </td>

        <td>
          <input
            type="text"
            value={only_Q_List_Befragung}
            onChange={evnt => handleChange(index, evnt)}
            name="only_Q_List_Befragung"
            className="form-control"
          />{' '}
        </td>

        <td>
          <input
            type="text"
            value={non_Q_List_Befragung}
            onChange={evnt => handleChange(index, evnt)}
            name="non_Q_List_Befragung"
            className="form-control"
          />{' '}
        </td>

        <td>
          <button
            className="btn btn-outline-danger"
            onClick={() => deleteTableRows(index)}
            type="button"
          >
            <DeleteOutline></DeleteOutline>
          </button>
        </td>
      </tr>
    );
  });
};
export default TableRows;
