import React from 'react';
import { useTransition } from 'react-spring';
import { BoxMessage } from '../../hooks/boxMessage';
import Box from './BoxMessage';

import { BoxMessageContainerStyled } from './styles';

interface BoxMessageContainerProps {
  messages: BoxMessage[];
}

const BoxMessageContainer: React.FC<BoxMessageContainerProps> = ({
  messages,
}) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { top: '-120%', opacity: 0 },
      enter: { top: '5%', opacity: 1 },
      leave: { top: '-120%', opacity: 0 },
    },
  );

  return (
    <BoxMessageContainerStyled>
      {messagesWithTransitions.map(({ item, key, props }) => (
        <Box key={key} style={props} message={item} />
      ))}
    </BoxMessageContainerStyled>
  );
};

export default BoxMessageContainer;
