import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px 0 0 45px;
  position: relative;
  margin: 80px 110px;
  padding: 20px;
  width: 60%;
  background-color: rgb(255, 255, 255, 0.8);
`;
