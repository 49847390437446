import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from './styles';
import api from '../../../services/api';
import SearchAccess from '../../Accesses/Search';
import { useToast } from '../../../hooks/toast';

interface ParamTypes {
  id: string;
}

interface AccessGroup {
  accessIds: number[];
  name: string;
}

const EditAccessGroup: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<ParamTypes>();
  const [initialData, setInitialData] = useState<any>();
  const [name, setName] = useState<string>();

  useEffect(() => {
    api.get(`/accessesGroup/${id}`).then(response => {
      setInitialData(response.data);
      setName(response.data.name);
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (accessGroup: AccessGroup) => {
      try {
        api.put(`/accessesGroup/${id}`, accessGroup).then(response => {
          if (response.data) {
            addToast({
              type: 'success',
              title: 'Success',
              description: 'The group was saved successfully!',
            });

            history.push('/accessGroup');
          }
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing access group, please try  try again',
        });
      }
    },
    [addToast, history, id],
  );

  return (
    <Container>
      <h1>Edit Access Group</h1>
      {initialData && (
        <>
          <h2>Name</h2>
          <input
            name="name"
            type="text"
            defaultValue={name}
            placeholder="Type group name"
            onChange={e => setName(e.target.value)}
            style={{
              width: '100%',
              padding: 5,
              resize: 'none',
            }}
          ></input>
          <h2>Access</h2>

          <SearchAccess
            select={true}
            name={name}
            selectedIds={initialData.accessIds}
            isEdit={true}
            idEdited={+id}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </Container>
  );
};
export default EditAccessGroup;
