import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../../../services/api';
import Table from '../../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';

import { Container, Content } from './styles';

interface Log {
  // _id: string;
  questionId: number;
  resultId: number;
  answerOriginal: string;
  answerEdited: string;
  user: string;
  created_at: string;
  logEditedAnswerId: number;
}

const Answers: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<Log[]>([]);
  const [tableColumnExtensions] = useState([
    // { columnFilteringEnabled: false },
    { columnName: 'questionId', width: 120 },
    { columnName: 'resultId', width: 120 },
    { columnName: 'created_at', width: 220 },
    { columnName: 'user', width: 250 },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('logsEditedAnswers').then((response: any) => {
      const logList = response?.data.map((log: Log) => {
        const aux = log;
        aux.created_at = moment(aux.created_at)?.format('DD.MM.YYYY HH:mm:ss');
        return aux;
      });
      setLogs(logList);
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Edited answers</h1>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={logs}
          checkboxSelection={false}
          hasFilterRow={true}
          tableColumnExtensions={tableColumnExtensions}
          idName={'logEditedAnswerId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default Answers;
