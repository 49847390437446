export const columns: any[] = [
  {
    name: 'languageId',
    title: 'LangID',
  },
  {
    name: 'languageName',
    title: 'Language',
  },
  {
    name: 'text',
    title: 'Category',
  },
  {
    name: 'translation',
    title: 'Translation',
  },
  {
    name: 'lastUpdate',
    title: 'Timestamp',
  },
  {
    name: 'user',
    title: 'User',
  },
];
