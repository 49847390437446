/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { LabelOutlined, ListAltOutlined } from '@material-ui/icons';

import { StyledTooltip } from './styles';

interface Option {
  label: string;
  text: string;
  combinationValue?: string;
}

export const columns: any[] = [
  {
    name: 'questionId',
    title: 'ID',
  },
  // {
  //   name: 'externalId',
  //   title: 'External ID',
  // },
  {
    name: 'externalIdTSO',
    title: 'E_ID TSO',
  },

  {
    name: 'externalIdBefragung',
    title: 'E_ID Befragung',
  },
  {
    name: 'text',
    title: 'Texts',
  },

  {
    name: 'options',
    title: 'Options',
    getCellValue: (row: any) =>
      row.options && row.options.length > 0 ? (
        <>
          <StyledTooltip
            title={row.options.map((t: Option, i: number) => {
              return <li key={i}>{t.text}</li>;
            })}
          >
            <ListAltOutlined />
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'combinations',
    title: 'Combinations',
    getCellValue: (row: any) =>
      row.options &&
      row.combinationType &&
      row.combinationType !== 'No_combinations' ? (
        <>
          <StyledTooltip
            title={row.options.map((t: Option, i: number) => {
              return <li key={i}>{t.combinationValue}</li>;
            })}
          >
            <ListAltOutlined />
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  {
    name: 'topics',
    title: 'Topics',
    getCellValue: (row: any) =>
      row.topics && row.topics.length > 0 ? (
        <>
          <StyledTooltip
            title={row.topics.map((t: Option, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <LabelOutlined />
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  { name: 'edit', title: '' },
];
