import styled from 'styled-components';

export const Container = styled.div``;

export const BoxRadioButtons = styled.div`
  width: 60em;
  display: flex;
  justify-content: flex-start;
  align-itens: center;
`;
