import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Select from '../../../components/SelectForm';
import { Container, SelectMultiple } from './styles';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Input from '../../../components/FormInput';
import ProgressStepBar from '../../../components/ProgressStepBar';
import { Content } from '../styles';
import EnumProductSubGroup from '../../../utils/enums/EnumProductSubGroup';

interface SelectType {
  value: number;
  label: string;
}
interface ParticipationLink {
  name: string;
  customerId: number;
  projectId: number;
  surveyId: number;
  segmentId: number;
  accesses: string[];
  accessIds?: number[];
}

interface Survey {
  isLocked: boolean;
  name: string;
  projectId: number;
  projectName: string;
  subGroup: string;
  surveyId: number;
  title: string;
}

interface Tag {
  value: string;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: ParticipationLink;
  handleSubmit: SubmitHandler;
  isEdit?: boolean;
  setSelectedAccess: any;
  setSelectedSegment?: any;
}
const steps = [
  {
    label: 'Cutomer',
    step: 1,
  },
  {
    label: 'Project',
    step: 2,
  },
  {
    label: 'Survey',
    step: 3,
  },
];
const ParticipationLinkForm: React.FC<Props> = props => {
  const surveySelectField = useRef<any>(null);

  const [customerList, setCustomerList] = useState<SelectType[]>([]);
  const [customerSelectedState, setCustomerSelectedState] = useState(0);

  const [projectListSelect, setProjectListSelect] = useState<SelectType[]>([]);
  const [projectSelectedState, setProjectSelectedState] = useState(0);

  const [surveyListSelect, setSurveyListSelect] = useState<SelectType[]>([]);
  const [surveySelectedState, setSurveySelectedState] = useState(0);

  const [segmentFullList, setSegmentFullList] = useState<SelectType[]>([]);
  const [segmentList, setSegmentList] = useState<SelectType[]>([]);
  const [segmentSelectedState, setSegmentSelectedState] = useState(0);

  const [accessFullList, setAccessFullList] = useState<any[]>([]);
  const [accessList, setAccessList] = useState<SelectType[]>([]);
  const [progress, setProgress] = useState<number>(0);

  const [accessSelectedState, setAccessSelectedState] = useState([0]);
  const [selectedAccessNameOptions, setSelectedAccessNameOptions] = useState<
    string[]
  >([]);
  const [tags, setTags] = useState<Tag[]>([{ value: 'All' }]);

  const [accessHasChanged, setAccessHasChanged] = useState<boolean>(false);

  // getCustomers
  useEffect(() => {
    api.get(`/customers`).then(response => {
      const customersResult = response.data.map((x: any) => {
        const customer: SelectType = {
          value: x.customerId,
          label: x.name,
        };
        return customer;
      });

      setCustomerList(customersResult);
    });
  }, []);

  // getProjects
  useEffect(() => {
    // show all projects
    api.get(`projects`).then(response => {
      if (customerSelectedState > 0) {
        const projectsByCustomer = response.data.filter(
          (x: any) =>
            x.customer ===
            customerList.find(y => y.value === customerSelectedState)?.label,
        );
        const projectsData: SelectType[] = projectsByCustomer.map(
          (project: any) => {
            const projectToSelect = {
              label: project.name,
              value: project.projectId,
            };
            return projectToSelect;
          },
        );
        setProjectListSelect(projectsData);
      } else {
        const projectsData: SelectType[] = response.data?.map(
          (project: any) => {
            const projectToSelect = {
              label: project.name,
              value: project.projectId,
            };
            return projectToSelect;
          },
        );
        setProjectListSelect(projectsData);
      }
    });
  }, [customerList, customerSelectedState]);

  // getSurveysByProject
  useEffect(() => {
    setSurveySelectedState(0);
    let surveysFiltered: Survey[] = [];
    if (projectSelectedState) {
      api.get(`/surveys/project/${projectSelectedState}`).then(response => {
        if (response.data) {
          surveysFiltered = response.data;

          const surveysSelect: SelectType[] = surveysFiltered.map(
            (survey: Survey) => {
              const subGroupName: string =
                Object.values(EnumProductSubGroup)[
                  Object.keys(EnumProductSubGroup).indexOf(
                    survey.subGroup ? survey.subGroup : '',
                  )
                ];

              const surveySelect: SelectType = {
                label: `${survey.name} (${subGroupName})`,
                value: survey.surveyId,
              };
              return surveySelect;
            },
          );

          setSurveyListSelect(surveysSelect);
        }
      });
    }
  }, [projectSelectedState]);

  // getAccessBysurvey
  useEffect(() => {
    if (surveySelectedState > 0) {
      api.get(`/accesses/survey/${surveySelectedState}`).then(response => {
        setAccessFullList(
          response.data?.map((access: any) => {
            return {
              accessId: access.accessId,
              accessName: access.name,
              segments: access.segments,
            };
          }),
        );

        const accessesList = response.data?.map((x: any) => {
          const access: SelectType = {
            value: x.accessId,
            label: x.name,
          };
          return access;
        });

        accessesList.push({ label: 'All', value: 0 });

        setAccessList(accessesList);
      });
    }
  }, [surveySelectedState]);

  // getAllSegments
  useEffect(() => {
    api.get(`/segments`).then(response => {
      const segmentsResult = response.data.map((x: any) => {
        const segment: SelectType = {
          value: x.segmentId,
          label: x.name,
        };
        return segment;
      });

      segmentsResult.push({ label: 'All', value: 0 });

      setSegmentFullList(segmentsResult);
      setSegmentList(segmentsResult);
    });
  }, []);
  // setSegementListFilteredByAccess
  useEffect(() => {
    if (
      accessSelectedState &&
      accessSelectedState?.length > 0 &&
      !accessSelectedState.includes(0)
    ) {
      const listSegmentsByAccess = accessFullList
        .filter(x => accessSelectedState.includes(x.accessId))
        ?.map(y => {
          return y.segments;
        })
        .reduce((list, sub) => list.concat(sub), []);

      const segmentsFilteredByAccess = segmentFullList.filter(
        (x: any) => listSegmentsByAccess?.includes(x.label) || x.value === 0,
      );

      setSegmentList(segmentsFilteredByAccess);
    } else {
      let segmentsArray: string[] = [];
      accessFullList.forEach((x: any) => {
        segmentsArray = segmentsArray.concat(x.segments);
      });

      const segmentsFilteredByAccess = segmentFullList.filter(
        (x: any) => segmentsArray?.includes(x.label) || x.value === 0,
      );

      setSegmentList(segmentsFilteredByAccess);
    }
  }, [accessFullList, accessSelectedState, segmentFullList]);

  // selectedAccess
  useEffect(() => {
    const accessIdsArray = accessList
      .filter((x: any) => selectedAccessNameOptions.includes(x.label))
      .map((y: any) => {
        return y.value;
      });

    setAccessSelectedState(accessIdsArray);
  }, [accessList, selectedAccessNameOptions]);

  // selectedAccessParent
  useEffect(() => {
    props.setSelectedAccess(accessSelectedState);
  }, [accessSelectedState, props]);

  const handleSelectedSurveyChange = useCallback((survey: any) => {
    setSurveySelectedState(survey.value);
    setSegmentSelectedState(0);
    setAccessSelectedState([0]);
    setProgress(3);
    setTags([]);
  }, []);

  const handleSelectedProjectListChange = useCallback((project: any) => {
    if (project.value) setProjectSelectedState(project.value);
    setSurveySelectedState(0);
    setSegmentSelectedState(0);
    setAccessSelectedState([0]);
    setProgress(2);
    setTags([]);
  }, []);

  const handleSelectedCustomerListChange = useCallback((customer: any) => {
    setCustomerSelectedState(customer.value);
    setProjectSelectedState(0);
    setSurveySelectedState(0);
    setSegmentSelectedState(0);
    setAccessSelectedState([0]);
    setProgress(1);
    setTags([]);
  }, []);
  // filling out accesses when edit
  useEffect(() => {
    if (props.isEdit) {
      setTags(
        accessList
          .filter(x => props.initialData?.accessIds?.includes(x.value))
          .map(x => {
            return { value: x.label };
          }),
      );
    }
  }, [accessList, props.initialData?.accessIds, props.isEdit]);

  const handleAccessSelectChange = useCallback(
    (selectedValue: any) => {
      if (selectedValue && selectedValue.length > 0) {
        const arr = [...selectedValue.map((sv: any) => sv.value)];
        setSelectedAccessNameOptions(arr);
      } else {
        setSelectedAccessNameOptions([]);
      }
      if (accessHasChanged) {
        setSegmentSelectedState(0);
      }
    },
    [accessHasChanged],
  );

  const handleSegmentSelectChange = useCallback((segment: any) => {
    setSegmentSelectedState(segment.value);
  }, []);

  const handleValueChange = (
    selectTypes: SelectType[],
    value: number,
  ): SelectType => {
    return {
      label: selectTypes.find(x => x.value === value)?.label ?? 'Select...',
      value,
    };
  };

  useEffect(() => {
    if (props.setSelectedSegment)
      props.setSelectedSegment(segmentSelectedState);
  }, [props, segmentSelectedState]);

  return (
    <Container>
      <Content>
        {!props.isEdit && (
          <ProgressStepBar
            steps={steps}
            activeStep={progress}
          ></ProgressStepBar>
        )}
        <Form
          ref={props.formRef}
          initialData={props.initialData}
          onSubmit={props.handleSubmit}
        >
          <h2>Name</h2>
          <Input
            type="text"
            name="name"
            placeholder="Enter a name to identify this Link (used only for MAFFO)"
          />
          <br />
          <h3>Customer</h3>
          <Select
            name="customerId"
            options={customerList}
            onChange={e => handleSelectedCustomerListChange(e)}
            value={handleValueChange(customerList, customerSelectedState)}
          />

          {customerSelectedState > 0 && (
            <>
              <br />
              <h3>Project</h3>
              <Select
                name="projectId"
                options={projectListSelect}
                onChange={e => handleSelectedProjectListChange(e)}
                value={handleValueChange(
                  projectListSelect,
                  projectSelectedState,
                )}
              />
            </>
          )}

          {projectSelectedState > 0 && (
            <>
              <br />
              <h3>Survey</h3>
              <Select
                name="surveyId"
                ref={surveySelectField}
                options={surveyListSelect}
                onChange={e => handleSelectedSurveyChange(e)}
                value={handleValueChange(surveyListSelect, surveySelectedState)}
              />
            </>
          )}

          {surveySelectedState > 0 && (
            <>
              <br />
              <h3>Accesses</h3>
              <SelectMultiple
                name="accesses"
                setSelectedTags={handleAccessSelectChange}
                tags={tags}
                tagsSearch={accessList.map(access => access.label)}
                acceptNewValues={false}
                componentHasChanged={setAccessHasChanged}
              ></SelectMultiple>

              <br />
              <h3>Segment</h3>
              <Select
                name="segmentId"
                options={segmentList}
                onChange={e => handleSegmentSelectChange(e)}
                value={handleValueChange(segmentList, segmentSelectedState)}
              />

              <Button type="submit" width="200px">
                {props.buttonText}
              </Button>
            </>
          )}
        </Form>
      </Content>
    </Container>
  );
};

export default ParticipationLinkForm;
