import styled from 'styled-components';

import { Add } from '@material-ui/icons';

export const Container = styled.div``;

export const RulesContainer = styled.div`
  && button {
    background: #ffffff;
    border: 0;
    margin: 0;
    padding: 0;
  }

  && th {
    width: 5rem;
  }
`;

export const AddIcon = styled(Add)`
  display: table-cell;
  vertical-align: middle;
`;
