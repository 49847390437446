import styled from 'styled-components';
import { DeleteOutline } from '@material-ui/icons';

export const Container = styled.div``;

export const Content = styled.div`
  position: relative;
  margin: 20px 0 0;
`;

export const DivCombobox = styled.div`
  margin-top: 20px;

  #labelHideAlternatives {
    margin-left: 5px;
  }
`;

export const BoxRow = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    margin: 20px 0 0;
    font-size: 20px;
  }
`;

export const BoxCol = styled.div`
  flex: 1;
  text-align: center;
  margin: 14px 0;
`;

export const BoxColTitle = styled.div`
  flex: 1;
  text-align: center;
  margin: 14px 0;
  padding: 12px 0;
  border-radius: 4px;
  background-color: #f6f6f6;
`;

export const DeleteIcon = styled(DeleteOutline)`
  display: table-cell;
  vertical-align: middle;
`;

export const ContainerInput = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 8px;
  width: 100%;
  color: #333;
  min-height: 12px;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #333;

    &::placeholder {
      color: #66636099;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const DivLoad = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: relative;
    background: #22222236;
    z-index: 1;
  }

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 6px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #ffffff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SearchButtonLoadingDiv = styled.div`
  display: flex;
`;

export const LoadingDiv = styled.div`
  position: relative;
  margin-top: 25px;
  .spinner {
    width: 30px;
    height: 30px;
    border-color: #8b8b8b2b;
    border-top-color: #1b1b1b;

    border-width: 3px;
  }
`;
