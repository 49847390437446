/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { useField } from '@unform/core';
import { TiDelete } from 'react-icons/ti';

import { RiTranslate } from 'react-icons/ri';
import {
  Container,
  Content,
  Break,
  ValueLabel,
  DeleteButton,
  AddButton,
  Input,
  TranslationArrowDiv,
  TranslationArrow,
  EmptyDiv30,
  Title,
} from './styles';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

interface Props {
  name: string;
  filledOptions?: Option[];
  width?: string;
  disabled?: boolean;
  disableTranslations?: boolean;
  translate?: boolean;
  combinationTypes?: boolean;
  title?: boolean;
  questionId?: number;
}

interface Option {
  value: number;
  text: string;
  disabled?: boolean;
  textTranslated?: string;
  combinationValue?: string;
}

const MultiInput: React.FC<Props> = ({
  name,
  width,
  filledOptions,
  disabled = false,
  disableTranslations = false,
  translate = false,
  combinationTypes = false,
  title = false,
  questionId = 0,
}) => {
  const { addToast } = useToast();
  const [options, setOptions] = useState<Option[]>(() => {
    if (filledOptions) {
      return filledOptions;
    }
    return [{ value: 1, text: '' }];
  });
  const { fieldName, error, registerField } = useField(name);

  const handleInputOptionChange = useCallback(
    (index: number, value: string): void => {
      const optionList = [...options];
      optionList[index].text = value;
      setOptions(optionList);
    },
    [options],
  );

  const handleInputOptionTranslateTextChange = useCallback(
    (index: number, value: string): void => {
      const optionList = [...options];
      optionList[index].textTranslated = value;
      setOptions(optionList);
    },
    [options],
  );

  const handleInputCombinationTextChange = useCallback(
    (index: number, value: string): void => {
      const optionList = [...options];
      optionList[index].combinationValue = value;
      setOptions(optionList);
    },
    [options],
  );

  const handleRemoveOption = useCallback(
    (index: number): void => {
      if (questionId > 0) {
        const answerValue = index + 1;
        api
          .post(`results/getResultsByQuestionIdAndOption`, {
            questionId,
            optionValue: answerValue,
          })
          .then(response => {
            if (response.data) {
              if (response.data?.length > 0) {
                addToast({
                  type: 'info',
                  title: 'Cannot be delete!',
                  description: `This option cannot be deleted because there is some results with this value.`,
                });
              } else {
                const optionList = [...options];
                optionList.splice(index, 1);
                setOptions(optionList);
              }
            }
          });
      } else {
        const optionList = [...options];
        optionList.splice(index, 1);
        setOptions(optionList);
      }
    },
    [addToast, options, questionId],
  );

  const handleAddOption = useCallback((): void => {
    const optionsSorted = options.sort((a: any, b: any) =>
      a.value > b.value ? 1 : -1,
    );

    setOptions([
      ...options,
      {
        value: optionsSorted[options.length - 1].value + 1,
        text: '',
      },
    ]);
  }, [options]);

  useEffect(() => {
    if (
      filledOptions &&
      filledOptions.length === 1 &&
      filledOptions[0].value === 1 &&
      filledOptions[0].text === ''
    )
      return; // the default empty value
    if (filledOptions) {
      setOptions(
        disabled
          ? filledOptions.map(opt => {
              opt.disabled = disabled;
              return opt;
            })
          : filledOptions,
      );
    }
  }, [filledOptions, disabled]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: options,
      getValue: (opt: Option[]) => {
        const allOptionsAreEmpty = options.every(option => option.text === '');

        if (allOptionsAreEmpty) return undefined;

        return options.filter(option => {
          return option.text.trim() !== '';
        });
      },
    });
  }, [fieldName, registerField, options]);

  return (
    <Container width={width}>
      {title && (
        <Title>
          {combinationTypes ? (
            <>
              <h2>Options</h2>
              <h2>Combination</h2>
              <h2>Translate</h2>
            </>
          ) : (
            <>
              <h2>Options</h2>
              <h2>Translate</h2>
            </>
          )}
        </Title>
      )}

      {options.map((option, index) => (
        <Content key={index}>
          <ValueLabel>{option.value}</ValueLabel>
          <Input
            type="text"
            value={option.text}
            isErrored={!!error}
            disabled={option.disabled}
            onChange={event =>
              handleInputOptionChange(index, event.target.value)
            }
          />

          {combinationTypes && (
            <>
              <div style={{ width: '15px' }}></div>
              <ValueLabel>{option.value}</ValueLabel>

              <Input
                type="text"
                value={option.combinationValue}
                isErrored={!!error}
                disabled={false}
                onChange={event =>
                  handleInputCombinationTextChange(index, event.target.value)
                }
              />
            </>
          )}

          {translate && (
            <>
              <TranslationArrowDiv>
                <TranslationArrow>
                  <RiTranslate />
                </TranslationArrow>
              </TranslationArrowDiv>

              <ValueLabel>{option.value}</ValueLabel>

              <Input
                type="text"
                value={option.textTranslated}
                isErrored={!!error}
                disabled={disableTranslations}
                onChange={event =>
                  handleInputOptionTranslateTextChange(
                    index,
                    event.target.value,
                  )
                }
              />
            </>
          )}
          {options.length - 1 === index ? (
            <>
              {!option.disabled && options.length > 1 && (
                <DeleteButton>
                  <TiDelete
                    color="#00B698"
                    size={30}
                    onClick={() => handleRemoveOption(index)}
                  />
                </DeleteButton>
              )}
              <>
                <Break />
                <AddButton onClick={handleAddOption}>Add New</AddButton>
              </>
            </>
          ) : (
            <EmptyDiv30 />
          )}
        </Content>
      ))}
    </Container>
  );
};

export default MultiInput;
