import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as Yup from 'yup';

import { Container } from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import FormApillar from '../Form';
import EnumCategoryType from '../../../utils/enums/EnumCategoryType';

interface ParamTypes {
  id: string;
}

const EditApillar: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { addToast } = useToast();
  const history = useHistory();
  const [initialData, setInitialData] = useState<any>([]);

  useEffect(() => {
    api.get(`/apillars/${id}`).then(result => {
      const aPillarFromDb = result.data;
      const aPillar = {
        categoryId: aPillarFromDb.categoryId,
        title: aPillarFromDb.name,
        type: EnumCategoryType.Apillar,
        expanded: true,
        children: aPillarFromDb.topics.map((topic: any) => {
          return {
            categoryId: topic.categoryId,
            title: topic.name,
            type: EnumCategoryType.Topic,
            expanded: true,
            children: topic.subtopics.map((subTopic: any) => {
              return {
                categoryId: subTopic.categoryId,
                title: subTopic.name,
                type: EnumCategoryType.Subtopic,
                expanded: true,
                children: subTopic.questionIds.map((questionId: number) => {
                  return {
                    questionid: questionId,
                    title: `${questionId} - Loading...`,
                    type: EnumCategoryType.Question,
                  };
                }),
              };
            }),
          };
        }),
      };
      setInitialData([aPillar]);
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (aPillar: any) => {
      try {
        const structure = {
          name: aPillar.title,
          categoryId: aPillar.categoryId,
          topics: aPillar.children?.map((topic: any) => {
            return {
              name: topic.title,
              categoryId: topic.categoryId,
              subtopics: topic.children?.map((subTopic: any) => {
                return {
                  name: subTopic.title,
                  categoryId: subTopic.categoryId,
                  questionIds: subTopic.children?.map((question: any) => {
                    return parseInt(question.questionid, 10);
                  }),
                };
              }),
            };
          }),
        };

        await api.put(`/apillars/${id}`, structure);
        history.push('/apillars');

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The A-Pillar was updated successfully!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description:
            'An error occurred while updating A-Pillar, please try again.',
        });
      }
    },
    [addToast, history, id],
  );

  return (
    <Container>
      {initialData.length > 0 && (
        <FormApillar
          handleSubmit={handleSubmit}
          initialData={initialData}
        ></FormApillar>
      )}
    </Container>
  );
};

export default EditApillar;
