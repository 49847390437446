import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactExport from 'react-export-excel';

import Select from 'react-select';
import moment from 'moment';
import Table from '../../components/DevExpressTable/DevExpressTable';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';

import {
  Container,
  Content,
  BoxCol,
  BoxColTitle,
  BoxRow,
  ContainerInput,
  DivLoad,
  SearchButtonLoadingDiv,
  LoadingDiv,
} from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import EnumIntegrationType from '../../utils/enums/EnumIntegrationType';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

/* eslint-disable no-shadow */

// When updating this enum, you must also update the respective Enum in the backend project.
enum EnumStatusIntegration {
  Pending = 0,
  Started = 1,
  Finished = 2,
  Aborted = 3,
}

enum EnumProjectOldPlatform {
  None = -1,
  Befragung = 0,
  Umfrage = 1,
  TSÖ = 2,
}

interface SelectType {
  value: number;
  label: string;
}

const downloadTypes: SelectType[] = [
  { value: 0, label: 'All Data' },
  { value: 1, label: 'Test Data' },
  { value: 2, label: 'Real Data' },
  { value: 3, label: 'Real Data ( >30% )' },
];

const projectsOldPlatform: SelectType[] = [
  { value: -1, label: 'None' }, // when 'None' is selected, the system shows the data from Victor
  { value: 0, label: 'Befragung' },
  { value: 1, label: 'Umfrage' },
  { value: 2, label: 'TSÖ' },
];

const usergroups = [
  { label: 'MitarbeiterBetrieb', value: 1 },
  { label: 'FührungskraftBetrieb', value: 2 },
  { label: 'Privatkunde', value: 3 },
  { label: 'Firmenkunde', value: 4 },
  { label: 'MitarbeiterVertrieb', value: 5 },
  { label: 'FührungskraftVertrieb', value: 6 },
  { label: 'Privatkunde_2', value: 10 },
  { label: 'Firmenkunde_2', value: 11 },
  { label: 'vermögende Privatkunden', value: 13 },
  { label: 'Privatk_3', value: 14 },
  { label: 'Firmenk_3', value: 15 },
  { label: 'Firmenk_4', value: 16 },
  { label: 'vermögende PK_2', value: 17 },
  { label: 'vermögende PK_3', value: 18 },
];

interface Columns {
  name: string;
  title: string;
}

interface Result {
  Access: string;
  Code: string;
  EndTime: string;
  LanguageName: string;
  Progress: string;
  ProjectName: string;
  Segments: string;
  StartTime: string;
  Survey: string;
  Test: string;
  UserGroup: string;
  Date: Date;
  resultId: number;
  results: any[];
}

interface FilterDataSchema {
  surveyId: number;
  searchType: number;
  userGroup: number;
  projectOldPlatform?: number;
  bankName: string;
  userGroupOldPlatformName: string;
  segment: string;
  access: string;
}

interface Integration {
  integrationId?: number;
  origin: string;
  destination: string;
  url: string;
  dataSchema: FilterDataSchema;
  type: number;
  status: number;
  statusName?: string;
}

const ExportResultGeneric: React.FC = () => {
  const surveySelectField = useRef<any>(null);
  const oldPlatformSelectField = useRef<any>(null);
  const bankNameInputField = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();
  const [results, setResults] = useState<Result[]>([]);
  const [columns, setColumns] = useState<Columns[]>([]);
  const [showAccess, setShowAccess] = useState(false);
  const date = moment().format('MM-DD-YYYY hh:mm:ss');
  const [searchTypeState, setSearchTypeState] = useState(0);
  const [yearSelectedState, setYearSelectedState] = useState(0);
  const [
    userGroupOldPlatformSelectedState,
    setUserGroupOldPlatformSelectedState,
  ] = useState(0);
  const [projectOldPlatformSelectedState, setProjectOldPlatformSelectedState] =
    useState(-1);

  const [userGroupList, setUserGroupList] = useState([]);
  const [userGroupSelectedState, setuserGroupSelectedState] = useState(0);

  const [typeListSelect, setTypeListSelect] = useState<SelectType[]>([]);
  const [typeSelectedState, setTypeSelectedState] = useState(0);

  const [projectListSelect, setProjectListSelect] = useState<SelectType[]>([]);
  const [projectSelectedState, setProjectSelectedState] = useState(0);

  const [surveyListSelect, setSurveyListSelect] = useState<SelectType[]>([]);
  const [surveySelectedState, setSurveySelectedState] = useState(0);

  const [segmentList, setSegmentList] = useState<SelectType[]>([]);
  const [allSegmentList, setAllSegmentList] = useState<SelectType[]>([]);
  const [segmentSelectedState, setSegmentSelectedState] = useState(0);
  const [segmentNameSelected, setSegmentNameSelected] = useState('');

  const [accessFullList, setAccessFullList] = useState<any[]>([]);
  const [accessList, setAccessList] = useState<SelectType[]>([]);
  const [accessSelectedState, setAccessSelectedState] = useState(0);
  const [accessNameSelected, setAccessNameSelected] = useState('');

  const [integrationsList, setIntegrationsList] = useState<Integration[]>([]); // novo

  const [hasData, setHasData] = useState<boolean>(false);

  const [hideData, setHideData] = useState<boolean>(true);

  const [gettingData, setGettingData] = useState<boolean>(false);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'resultId', title: 'ID', width: 100 },
    { columnName: 'Survey', title: 'Survey', width: 150 },
    { columnName: 'LanguageName', title: 'Language', width: 150 },
    { columnName: 'UserGroup', title: 'Survey', width: 170 },
    { columnName: 'StartTime', width: 200 },
    { columnName: 'EndTime', width: 200 },
    { columnName: 'Test', width: 100 },
  ]);

  const years = Array.from(
    new Array(new Date().getFullYear() - 2005 + 1),
    (val, index) => index + 2005,
  ).map((x: number) => {
    return {
      label: x.toString(),
      value: x,
    };
  });

  years.push({ label: 'All', value: 0 });

  // get integrations with status pending 0
  const getIntegrations = useCallback(() => {
    api.get('integrations/type/1').then(response => {
      const pendingIntegrations = response.data
        .sort((a: any, b: any) => {
          return b.integrationId - a.integrationId;
        })
        .map((item: Integration) => {
          return {
            ...item,
            statusName: Object.keys(EnumStatusIntegration)[
              Object.values(EnumStatusIntegration).indexOf(item.status)
            ],
            destination: Object.keys(EnumProjectOldPlatform)[
              Object.values(EnumProjectOldPlatform).indexOf(
                item.dataSchema?.projectOldPlatform ?? 0,
              )
            ],
          };
        });
      setIntegrationsList([pendingIntegrations[0]]);
    });
  }, []);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  useEffect(() => {
    const id = setInterval(getIntegrations, 5000);

    return () => clearInterval(id);
  }, [getIntegrations]);

  // get all usergroups
  useEffect(() => {
    api.get('subGroups').then(response => {
      const usergroupList = response.data;
      usergroupList.push({ label: 'All', value: 0 });
      setUserGroupList(usergroupList);
    });
  }, []);

  // get all project types
  useEffect(() => {
    api.get('projectsTypes').then(response => {
      const typeList = response.data;
      typeList.push({ label: 'All', value: 0 });
      setTypeListSelect(typeList);
    });
  }, []);

  // all segments
  useEffect(() => {
    api.get(`/segments`).then(response => {
      const segmentsResult = response.data.map((x: any) => {
        const segment: SelectType = {
          value: x.segmentId,
          label: x.name,
        };
        return segment;
      });

      segmentsResult.push({ label: 'All', value: 0 });
      setAllSegmentList(segmentsResult);
    });
  }, []);

  // Segments for current selection
  useEffect(() => {
    setSegmentSelectedState(0);

    let segments = allSegmentList;

    if (accessFullList.length > 0) {
      let currentAccessList = accessFullList;
      if (accessSelectedState > 0) {
        currentAccessList = currentAccessList.filter(
          x => x.accessId === accessSelectedState,
        );
      }

      const segmentsFromAccess = currentAccessList.map(x => {
        return x.segments;
      });

      segments = segments.filter(x =>
        segmentsFromAccess.flat().includes(x.label),
      );
      segments.unshift({ label: 'All', value: 0 });
    }
    setSegmentList(segments);
  }, [accessFullList, accessSelectedState, allSegmentList]);

  // get Access by survey
  useEffect(() => {
    setAccessSelectedState(0);

    if (surveySelectedState > 0) {
      api.get(`/accesses/survey/${surveySelectedState}`).then(response => {
        setAccessFullList(
          response.data?.map((access: any) => {
            return {
              accessId: access.accessId,
              accessName: access.name,
              segments: access.segments,
            };
          }),
        );

        const accessesList = response.data?.map((x: any) => {
          const access: SelectType = {
            value: x.accessId,
            label: x.name,
          };
          return access;
        });

        accessesList.push({ label: 'All', value: 0 });

        setAccessList(accessesList);
        setShowAccess(true);
      });
    }
    setShowAccess(false);
  }, [surveySelectedState]);

  // getProjectsByType
  useEffect(() => {
    setProjectSelectedState(0);
    const projects: SelectType[] = [{ label: 'All', value: 0 }];
    if (typeSelectedState) {
      api.get(`projects/${typeSelectedState}/getByType`).then(response => {
        const projectsData: SelectType[] = response.data?.map(
          (project: any) => {
            const projectToSelect = {
              label: project.name,
              value: project.projectId,
            };
            return projectToSelect;
          },
        );

        setProjectListSelect(projects.concat(projectsData));
      });
    } else {
      // show all projects
      api.get(`projects`).then(response => {
        const projectsData: SelectType[] = response.data?.map(
          (project: any) => {
            const projectToSelect = {
              label: project.name,
              value: project.projectId,
            };
            return projectToSelect;
          },
        );
        setProjectListSelect(projects.concat(projectsData));
      });
    }
  }, [typeSelectedState]);

  // getSurveysByProjectUsergroupYear
  useEffect(() => {
    let surveysFiltered: any[] = [];
    if (projectSelectedState) {
      api.get(`/surveys/project/${projectSelectedState}`).then(response => {
        if (response.data) {
          surveysFiltered = response.data;

          if (userGroupSelectedState !== 0) {
            surveysFiltered = surveysFiltered.filter(
              (survey: any) => survey.subGroup === userGroupSelectedState,
            );
          }

          if (yearSelectedState > 0) {
            surveysFiltered = surveysFiltered.filter(
              (survey: any) => survey.year === yearSelectedState,
            );
          }

          const surveysSelect: SelectType[] = surveysFiltered.map(
            (survey: any) => {
              const surveySelect: SelectType = {
                label: survey.name,
                value: survey.surveyId,
              };
              return surveySelect;
            },
          );

          setSurveyListSelect(surveysSelect);
        }
      });
    } else {
      api.get(`/surveys/`).then(response => {
        if (response.data) {
          surveysFiltered = response.data;

          if (userGroupSelectedState !== 0) {
            surveysFiltered = surveysFiltered.filter(
              (survey: any) => survey.subGroup === userGroupSelectedState,
            );
          }

          if (yearSelectedState > 0) {
            surveysFiltered = surveysFiltered.filter(
              (survey: any) => survey.year === yearSelectedState,
            );
          }

          const surveysSelect: SelectType[] = surveysFiltered.map(
            (survey: any) => {
              const surveySelect: SelectType = {
                label: survey.name,
                value: survey.surveyId,
              };
              return surveySelect;
            },
          );

          setSurveyListSelect(surveysSelect);
        }
      });
    }
  }, [projectSelectedState, userGroupSelectedState, yearSelectedState]);

  const getResults = (): Result[] => {
    // return results;
    let listResultsFiltered = results;
    if (accessSelectedState) {
      listResultsFiltered = listResultsFiltered?.filter(
        x =>
          x.Access ===
          accessList.find(y => y.value === accessSelectedState)?.label,
      );
    }

    if (segmentSelectedState) {
      listResultsFiltered = listResultsFiltered?.filter((x: any) =>
        x.Segments.includes(
          segmentList.find(
            s => s.value === segmentSelectedState && segmentSelectedState > 0,
          )?.label ?? '',
        ),
      );
    }

    return listResultsFiltered;
  };

  const handleDonwloadTypeChange = useCallback((downloadType: any) => {
    setSearchTypeState(downloadType.value);
    setHideData(true);
  }, []);

  const handleSegmentSelectChange = useCallback((segment: any) => {
    setSegmentNameSelected(segment.label);
    setSegmentSelectedState(segment.value);
    setHideData(true);
  }, []);

  const handleAccessSelectChange = useCallback((access: any) => {
    setAccessNameSelected(access.label);
    setAccessSelectedState(access.value);
    setHideData(true);
  }, []);

  const handleUserGroupSelectChange = useCallback((userGroup: any) => {
    setuserGroupSelectedState(userGroup.value);
    setHideData(true);
  }, []);

  const handleSelectedSurveyChange = useCallback((survey: any) => {
    setSurveySelectedState(survey.value);
    setHideData(true);
  }, []);

  const handleSelectedYearChange = useCallback((year: any) => {
    setYearSelectedState(year.value);
    setHideData(true);
  }, []);

  const handleUserGroupOldPlatformChange = useCallback((usergroup: any) => {
    setUserGroupOldPlatformSelectedState(usergroup.value);
  }, []);

  const handlehasData = useCallback((hasData: boolean) => {
    setHasData(hasData);
  }, []);

  const handleProjectsOldPlatformChange = useCallback(
    (projectOldPlatform: any) => {
      setHideData(true);
      setProjectOldPlatformSelectedState(projectOldPlatform.value);

      if (
        projectOldPlatform.value === -1 ||
        projectOldPlatformSelectedState === 2
      ) {
        setUserGroupOldPlatformSelectedState(0);
      }
    },
    [projectOldPlatformSelectedState],
  );

  const handleSelectedProjectListChange = useCallback((project: any) => {
    setHideData(true);
    setProjectSelectedState(project.value);
  }, []);

  const handleSelectedTypeListChange = useCallback((type: any) => {
    setHideData(true);
    setTypeSelectedState(type.value);
  }, []);

  const handleValueChange = (
    selectTypes: SelectType[],
    value: number,
  ): SelectType => {
    return {
      label: selectTypes.find(x => x.value === value)?.label ?? 'Select...',
      value,
    };
  };

  const handleExportToOldPlatform = useCallback(() => {
    if (projectOldPlatformSelectedState === -1) {
      addToast({
        type: 'error',
        title: 'Select Old Platform',
        description: 'Please, select the Old Platform target to export data!',
      });
      return;
    }

    if (bankNameInputField.current?.value === '') {
      addToast({
        type: 'error',
        title: 'Type bank name',
        description: 'Please, type the bank name to export data!',
      });
      return;
    }

    const bankNameLen = bankNameInputField.current?.value.length;
    if (bankNameLen && bankNameLen > 40) {
      addToast({
        type: 'error',
        title: 'Bank name is too long',
        description: 'Please, the bank name must have less then 40 characters',
      });
      return;
    }

    const userGroupOldPlatform = usergroups.find(
      x => x.value === userGroupOldPlatformSelectedState,
    );

    if (!userGroupOldPlatform && projectOldPlatformSelectedState !== 2) {
      addToast({
        type: 'error',
        title: 'User group in old platform invalid',
        description: 'Please, select an usergroup (in old platform)',
      });
      return;
    }

    const surveyId = surveySelectedState;
    const paramsPost = {
      surveyId,
      searchType: searchTypeState,
      userGroup: userGroupSelectedState,
      projectOldPlatform: projectOldPlatformSelectedState,
      bankName: bankNameInputField.current?.value ?? '',
      userGroupOldPlatformName: userGroupOldPlatform?.label ?? '',
      segment: segmentNameSelected,
      access: accessNameSelected,
      accessId: accessSelectedState,
    };

    const filterToExport: Integration = {
      url: `/results/survey/export/exportOldPlatformNifi`,
      origin: surveySelectField.current?.state.value.label ?? '',
      destination: oldPlatformSelectField.current?.state.value.label ?? '',
      status: 0, // pending
      type: EnumIntegrationType.OldPlatform, // don't need type yet
      dataSchema: paramsPost, // { body}
    };

    api.post('/integrations', filterToExport).then(response => {
      addToast({
        type: 'success',
        title: 'Success',
        description:
          'The data was prepared to be exported. Please wait until the transaction finish!',
      });
      setIntegrationsList(oldValue => [...oldValue, response.data]);
    });
  }, [
    accessNameSelected,
    accessSelectedState,
    addToast,
    projectOldPlatformSelectedState,
    searchTypeState,
    segmentNameSelected,
    surveySelectedState,
    userGroupOldPlatformSelectedState,
    userGroupSelectedState,
  ]);

  const handleClickButtonSearch = useCallback(() => {
    setGettingData(true);

    if (surveySelectedState !== 0) {
      const surveyId = surveySelectedState;
      const paramsPost = {
        surveyId,
        searchType: searchTypeState,
        userGroup: userGroupSelectedState,
        projectOldPlatform: projectOldPlatformSelectedState,
      };
      api
        .post(`/results/survey/export/oldplatform`, paramsPost)
        .then(response => {
          setGettingData(false);
          setHideData(false);
          if (response.data.msg) {
            addToast({
              type: 'info',
              title: `${response.data.title}`,
              description: `${response.data.msg}`,
            });
            setResults([]);
          } else if (response.data.length > 0) {
            const dynamicColumns: any[] = Object.keys(response.data[0])
              .filter(x => x !== 'results')
              .map(key => {
                if (key === 'resultId') {
                  return {
                    name: key.trim() ?? '',
                    title: 'ID',
                  };
                }
                if (key === 'accessId') {
                  return {
                    name: '',
                    title: '',
                  };
                }
                return {
                  name: key.trim() ?? '',
                  title: key.trim() ?? '',
                };
              });

            const headerQuestionIds = response.data[0].results
              .filter((x: any) => x.questionId !== 'NaN')
              .map((result: any) => {
                return {
                  name: result.questionId,
                  title: result.questionId,
                };
              });

            const headerQuestionIdsWithoutDuplicates: any[] = [];

            headerQuestionIds.forEach((element: any) => {
              if (
                !headerQuestionIdsWithoutDuplicates.some(
                  (x: any) =>
                    x.title === element.title && x.name === element.name,
                )
              ) {
                headerQuestionIdsWithoutDuplicates.push(element);
              }
            });

            response.data.forEach((row: { results: any[] }, index: number) => {
              row.results.forEach((element: any) => {
                response.data[index][element.questionId] = element.value;
              });
            });

            setColumns(
              dynamicColumns.concat(headerQuestionIdsWithoutDuplicates),
            );

            // sort list by last update:
            const resultsAux = response.data.map((obj: any) => {
              return { ...obj, date: new Date(obj.EndTime) };
            });

            const resultsSortedDesc: Result[] = resultsAux.sort(
              (objA: any, objB: any) =>
                objB.date.getTime() - objA.date.getTime(),
            );

            resultsSortedDesc.map((x: any) => {
              const y = x;
              y.StartTime = new Date(x.StartTime).toLocaleString();
              y.EndTime = new Date(x.EndTime).toLocaleString();
              return y;
            });

            setResults(resultsSortedDesc);
          } else {
            setResults([]);
          }
        });
    }
  }, [
    addToast,
    projectOldPlatformSelectedState,
    searchTypeState,
    surveySelectedState,
    userGroupSelectedState,
  ]);

  return (
    <Container>
      <BackButton />

      <br />
      <h3>Usergroup</h3>
      <Select
        options={userGroupList}
        onChange={e => handleUserGroupSelectChange(e)}
        value={handleValueChange(userGroupList, userGroupSelectedState)}
      />

      <br />
      <h3>Type</h3>
      <Select
        options={typeListSelect}
        onChange={e => handleSelectedTypeListChange(e)}
        value={handleValueChange(typeListSelect, typeSelectedState)}
      />

      <br />
      <h3>Project</h3>
      <Select
        options={projectListSelect}
        onChange={e => handleSelectedProjectListChange(e)}
        value={handleValueChange(projectListSelect, projectSelectedState)}
      />

      <br />
      <h3>Year</h3>
      <Select
        options={years.reverse()}
        onChange={e => handleSelectedYearChange(e)}
        value={handleValueChange(years, yearSelectedState)}
      />

      <br />
      <h3>Survey</h3>
      <Select
        ref={surveySelectField}
        options={surveyListSelect}
        onChange={e => handleSelectedSurveyChange(e)}
        value={handleValueChange(surveyListSelect, surveySelectedState)}
      />

      <div hidden={!showAccess}>
        <br />
        <h3>Link</h3>
        <Select
          options={accessList}
          onChange={e => handleAccessSelectChange(e)}
          value={handleValueChange(accessList, accessSelectedState)}
        />
      </div>

      <br />
      <h3>Segment</h3>
      <Select
        options={segmentList}
        onChange={e => handleSegmentSelectChange(e)}
        value={handleValueChange(segmentList, segmentSelectedState)}
      />

      <br />
      <h3>Download Type</h3>
      <Select
        options={downloadTypes}
        onChange={e => handleDonwloadTypeChange(e)}
        value={handleValueChange(downloadTypes, searchTypeState)}
      />

      <br />
      <h3>Project (old Platform)</h3>
      <Select
        ref={oldPlatformSelectField}
        options={projectsOldPlatform}
        onChange={e => handleProjectsOldPlatformChange(e)}
        value={handleValueChange(
          projectsOldPlatform,
          projectOldPlatformSelectedState,
        )}
      />

      {projectOldPlatformSelectedState >= 0 && (
        <>
          <br />
          <h3>Bank Name (in old Platform)</h3>
          <ContainerInput>
            <input
              ref={bankNameInputField}
              type="text"
              name={'bankeNameInput'}
              placeholder={`Enter bank name to show in the old platform`}
              maxLength={130}
            />
          </ContainerInput>

          <div
            hidden={
              !(
                projectOldPlatformSelectedState === 0 ||
                projectOldPlatformSelectedState === 1
              )
            }
          >
            <br />
            <h3>UserGroup (in old platform)</h3>
            <Select
              options={usergroups}
              onChange={e => handleUserGroupOldPlatformChange(e)}
              value={handleValueChange(
                usergroups,
                userGroupOldPlatformSelectedState,
              )}
            />
          </div>
        </>
      )}
      <SearchButtonLoadingDiv>
        {surveySelectedState > 0 && (
          <Button
            onClick={handleClickButtonSearch}
            marginRight="35px"
            width="280px"
            height="40px"
            disabled={gettingData}
          >
            Search
          </Button>
        )}
        <LoadingDiv>
          {gettingData && (
            <DivLoad>
              <div className="overlay">
                <div className="overlay__inner">
                  <div className="overlay__content">
                    <span className="spinner"></span>
                  </div>
                </div>
              </div>
            </DivLoad>
          )}
        </LoadingDiv>
      </SearchButtonLoadingDiv>

      {integrationsList.map((integration: Integration) => (
        <BoxRow key={integration.integrationId}>
          <BoxColTitle>SurveyId: {integration.dataSchema.surveyId}</BoxColTitle>
          <BoxCol>
            <span>IntegrationId: {integration.integrationId}</span>
          </BoxCol>
          <BoxCol>
            <span>{integration.statusName ?? 'Pending'}</span>
          </BoxCol>
          <BoxCol>
            <span>{integration.destination}</span>
          </BoxCol>
        </BoxRow>
      ))}

      {!hideData && (
        <>
          <h2>Data: </h2>
          <Content>
            {gettingData && (
              <DivLoad>
                <div className="overlay">
                  <div className="overlay__inner">
                    <div className="overlay__content">
                      <span className="spinner"></span>
                    </div>
                  </div>
                </div>
              </DivLoad>
            )}
            <Table
              columnsProp={columns}
              dataProp={getResults()}
              checkboxSelection={false}
              tableColumnExtensions={tableColumnExtensions}
              idName={'resultId'}
              hasData={handlehasData}
              defaultPageSize={10}
            ></Table>
          </Content>

          {hasData && (
            <>
              <ExcelFile
                element={
                  <Button marginRight="35px" width="150px" height="40px">
                    Download
                  </Button>
                }
                filename={`Results - ${date}`}
              >
                <ExcelSheet data={getResults()} name="Results">
                  {columns.map(column => {
                    return (
                      <ExcelColumn
                        key={column.title.trim()}
                        label={column.title.trim()}
                        value={column.name.trim()}
                      />
                    );
                  })}
                </ExcelSheet>
              </ExcelFile>
              <Button
                onClick={handleExportToOldPlatform}
                marginRight="35px"
                width="280px"
                height="40px"
                disabled={
                  !!integrationsList.find(x => x.status === 0 || x.status === 1)
                }
              >
                Export to Old Platform
              </Button>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ExportResultGeneric;
