export const columns: any[] = [
  {
    name: 'languageId',
    title: 'ID',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'languageCode',
    title: 'Code',
  },
  {
    name: 'pagePrevText',
    title: 'Back button',
  },
  {
    name: 'pageNextText',
    title: 'Next button',
  },
  {
    name: 'completeText',
    title: 'Complete Button',
  },
  {
    name: 'progressText',
    title: 'Progress Text',
  },
  {
    name: 'nameButtonStartText',
    title: 'Button StartText',
  },
  {
    name: 'requiredErrorText',
    title: 'Required Error Text ',
  },
  {
    name: 'placeholderTextQuestion',
    title: 'Placeholder Text Question',
  },
  {
    name: 'requiredAllrowsErrorText',
    title: 'Required All Rows Error Text',
  },
  { name: 'edit', title: '' },
];
