import React, { useState, useCallback, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Link } from 'react-router-dom';

import { ButtonNext, Content, GDPRText } from '../styles';

interface Props {
  text: string;
  onStartButtonClick: (event: any) => void;
  surveyName: string;
  logoPath: string;
  buttonColor: string;
  languageId: number;
  gdprId: number;
  nameButtonNext: string;
  headerColor: string;
  languageCode: string;
  nameMehrInformation: string;
  textFootnoteGDPR: string;
}

const Start: React.FC<Props> = ({
  text,
  onStartButtonClick,
  surveyName,
  logoPath,
  buttonColor,
  headerColor,
  languageId,
  languageCode,
  gdprId,
  nameButtonNext,
  nameMehrInformation,
  textFootnoteGDPR,
}) => {
  const [visibleButtonNext, setVisibleButtonNext] = useState(true);

  useEffect(() => {
    const dynamicButton = document.getElementById(
      'fortsetzen',
    ) as HTMLButtonElement;

    if (dynamicButton !== null && dynamicButton !== undefined) {
      setVisibleButtonNext(false);
      dynamicButton.addEventListener('click', e => onStartButtonClick(e));
      dynamicButton.setAttribute('class', 'button-fortfahren');
    }
  }, [onStartButtonClick]);

  const handleLocalStorage = useCallback(() => {
    localStorage.setItem('surveyName', surveyName);
    localStorage.setItem('logoPath', logoPath);
  }, [logoPath, surveyName]);

  return (
    <>
      <div style={{ padding: '10px' }}>
        <Content>
          {ReactHtmlParser(text as string)}
          {visibleButtonNext && (
            <ButtonNext
              buttonColor={buttonColor}
              type="button"
              onClick={onStartButtonClick}
            >
              {nameButtonNext}
            </ButtonNext>
          )}
          <GDPRText>
            {languageId > 0 && textFootnoteGDPR !== '' ? (
              textFootnoteGDPR
            ) : (
              <>
                Vertrauen ist wichtig, besonders wenn es um Ihre Daten geht.
                Deshalb sehen wir es als unsere Verpflichtung, Ihre Daten mit
                höchster Sorgfalt zu verwalten und alles zu tun, um Ihre
                Informationen vor Missbrauch zu schützen. <br />
                Die emotion banking GmbH verarbeitet Ihre Daten ausschließlich
                auf Grundlage der gesetzlichen Bestimmungen (DSGVO).
              </>
            )}

            <Link
              to={{
                pathname: `/moreinfo?lang=${
                  languageId > 0 ? languageId : 1
                }&langCode=${languageCode}&gdpr=${gdprId}&style=${headerColor}`,
              }}
              target="_blank"
              onClick={() => handleLocalStorage()}
            >
              {nameMehrInformation}
            </Link>
          </GDPRText>
        </Content>
      </div>
    </>
  );
};

export default Start;
