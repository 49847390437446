import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../services/api';

import { Container, Content, BoxCard, Header } from './styles';

interface ParamTypes {
  id: string;
  code: string;
}

interface Participation {
  title: string;
  totalPeopleStartedSurvey: number;
  totalPeople30OrMore: number;
  totalPeople: number;
}

const ParticipationCustomer: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { code } = useParams<ParamTypes>();

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const date = `${day}/${month}/${year}`;

  const [participations, setParticipations] = useState<Participation>();

  // get result data
  useEffect(() => {
    api.get(`/results/participationCustomer/${id}/${code}`).then(response => {
      setParticipations(response.data);
    });
  }, [code, id]);

  return (
    <>
      {participations && (
        <Container>
          <Header>
            <h1>{participations.title}</h1>
          </Header>
          <Content out={false}>
            <BoxCard color={'#EBFDEF'} position={'center'}>
              <h3>{participations?.totalPeople30OrMore}</h3>
              <span>{date}</span>
            </BoxCard>
          </Content>
        </Container>
      )}
    </>
  );
};

export default ParticipationCustomer;
