export const columns: any[] = [
  {
    name: 'tagId',
    title: 'ID',
  },
  {
    name: 'category',
    title: 'Category',
  },

  {
    name: 'category_2',
    title: 'Parent category',
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'edit',
    title: '',
  },
  {
    name: 'delete',
    title: '',
  },
];
