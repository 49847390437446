import { ListAltOutlined } from '@material-ui/icons';
import { StyledTooltip } from './styles';

export const columns: any[] = [
  {
    name: 'apillarId',
    title: 'ID',
  },
  {
    name: 'name',
    title: 'Name',
  },

  {
    name: 'categories',
    title: 'Categories',
    getCellValue: (row: any) =>
      row.categories && row.categories.length > 0 ? (
        <>
          <StyledTooltip
            title={row.categories.map((t: string, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <ListAltOutlined />
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },

  {
    name: 'questions',
    title: 'Questions',
    getCellValue: (row: any) =>
      row.questions && row.questions.length > 0 ? (
        <>
          <StyledTooltip
            title={row.questions.map((t: number, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <ListAltOutlined />
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },

  { name: 'edit', title: '' },
  // { name: 'delete', title: '' },
];
