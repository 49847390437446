export const columns: any[] = [
  {
    name: 'surveyTextId',
    title: 'ID',
  },
  {
    name: 'title',
    title: 'Title',
  },
  {
    name: 'text',
    title: 'Text',
  },
  { name: 'edit', title: '' },
  { name: 'delete', title: '' },
];

export const columnsSelect: any[] = [
  {
    name: 'surveyTextId',
    title: 'ID',
  },
  {
    name: 'title',
    title: 'Title',
  },
  {
    name: 'text',
    title: 'Text',
  },
];
