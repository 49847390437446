import styled from 'styled-components';
import TagsSelect from '../../../components/TagsSelect';
import Button from '../../../components/Button';

export const Container = styled.div``;

export const Content = styled.div``;

export const SelectMultiple = styled(TagsSelect)``;

export const VisualizationsButtons = styled.div``;

export const VButton = styled(Button)`
  margin-right: 15px;
`;
