import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  TextareaHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';
import { AutoGrowingContainer } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  value: string;
}

const AutoGrowingTextarea: React.FC<TextAreaProps> = ({
  name,
  value,
  ...rest
}) => {
  const [text, setText] = useState(value || '');
  const [contentHeight, setContentHeight] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const textareaRef = useRef<any>(null);
  const { fieldName, error, registerField } = useField(name);

  const handleTextChange = useCallback((event: any) => {
    setText(event.target.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!textareaRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const textarea: any = textareaRef.current;
    if (textarea) {
      textarea.style.height = textarea.scrollHeight;
      const newHeight = textarea.scrollHeight;
      setContentHeight(newHeight);
    }
  }, [text]);

  return (
    <AutoGrowingContainer
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      data-testid="input-container"
      style={{ height: contentHeight }}
    >
      <textarea
        ref={textareaRef}
        value={text}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleTextChange}
        style={{
          width: '100%',
          paddingTop: '7px',
          paddingBottom: '9px',
          height: contentHeight,
          resize: 'none',
          overflow: 'hidden',
          boxSizing: 'border-box',
        }}
        {...rest}
      />
    </AutoGrowingContainer>
  );
};

export default AutoGrowingTextarea;
