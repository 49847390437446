import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';
import FormLanguage from '../Form';

interface Language {
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  accessLockedMessage: string;
  codeNotExistsMessage: string;
  surveyCompletedMessage: string;
}

const CreateLanguage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const handleSubmit = useCallback(
    async (data: Language) => {
      try {
        const newLanguage = { ...data };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Language name is required'),
          pagePrevText: Yup.string().required('pagePrevText is required'),
          pageNextText: Yup.string().required('pageNextText is required'),
          completeText: Yup.string().required('completeText is required'),
          nameButtonStartText: Yup.string().required(
            'nameButtonStartText is required',
          ),
          progressText: Yup.string().required('progressText is required'),
          requiredErrorText: Yup.string().required(
            'requiredErrorText is required',
          ),
          placeholderTextQuestion: Yup.string().required(
            'placeholderTextQuestion is required',
          ),
          requiredAllrowsErrorText: Yup.string().required(
            'requiredAllrowsErrorText is required',
          ),
          textFootnote: Yup.string().required('textFootnote is required'),

          textLinkFootnote: Yup.string().required(
            'textLinkFootnote is required',
          ),

          accessLockedMessage: Yup.string().required(
            'accessLockedMessage is required',
          ),
          codeNotExistsMessage: Yup.string().required(
            'codeNotExistsMessage is required',
          ),
          surveyCompletedMessage: Yup.string().required(
            'surveyCompletedMessage is required',
          ),
          contactUsMessage: Yup.string().required(
            'contactUsMessage is required',
          ),
        });

        await schema.validate(newLanguage, {
          abortEarly: false,
        });

        await api.post('/languages', newLanguage);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The language was saved successfully!',
        });

        history.push('/languages');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating language, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <h1>New Laguage</h1>
      <FormLanguage
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
      />
    </Container>
  );
};
export default CreateLanguage;
