import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { EditOutlined } from '@material-ui/icons';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';
import { Container, Content, Button } from './styles';
import api from '../../services/api';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';

interface Language {
  languageId: number;
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  nameButtonStartText: string;
}

const Languages: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'languageId', width: 80 },
    // { columnName: 'name', width: 1200 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/languages/edit/${row.languageId}`);
    },
    [history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: 'Edit',
      onClick: handleClickEdit,
      icon: <EditOutlined />,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('languages').then(response => {
      setLanguages(response.data);
    });
    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Languages</h1>

      <Link to="/languages/new">
        <Button variant="contained" type="button">
          New Language
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={languages}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'languageId'}
        ></Table>
      </Content>
    </Container>
  );
};
export default Languages;
