/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../services/api';

const getQuestionsWithTranslations = async (
  surveyEncoded: any,
): Promise<any[]> => {
  // INICIO buscar todas as translations (das questions do survey) de uma só vez
  const questionIdsToSearch: any[] = [];
  const questionsWithTranslation: any[] = [];
  surveyEncoded.pages.forEach(async (page: any) => {
    page.elements.forEach(async (element: any) => {
      if (element.questionId) {
        questionIdsToSearch.push(element.questionId);
      } else if (element.matrixId) {
        element.rows.forEach(async (row: any) => {
          questionIdsToSearch.push(row.questionIdMatrix ?? row.questionId);
        });
      }
    });
  });

  const valuesToSearch = JSON.stringify(questionIdsToSearch)
    .replace(/]/g, '')
    .replace(/\[/g, '')
    .replace(/"/g, '');

  if (valuesToSearch) {
    const response = await api.get(
      `questions/getQuestionsByMultipleIds/${valuesToSearch}`,
    );

    response.data.forEach((question: any) => {
      questionsWithTranslation.push(question);
    });
  }
  return questionsWithTranslation;
};

export default getQuestionsWithTranslations;
