/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Link } from 'react-router-dom';

import { ListAltOutlined, EditOutlined } from '@material-ui/icons';

import { StyledTooltip } from '../styles';

export const columns: any[] = [
  {
    name: 'accessId',
    title: 'Access ID',
  },
  {
    name: 'accessName',
    title: 'Access',
  },
  {
    name: 'surveyId',
    title: 'Survey ID',
  },
  {
    name: 'sample',
    title: 'Sample',
  },
  {
    name: 'surveyName',
    title: 'Survey',
  },

  {
    name: 'subGroupSurvey',
    title: 'User Group',
  },

  {
    name: 'segments',
    title: 'Segments',
    width: 120,
    getCellValue: (row: any) =>
      row.segments && row.segments.length > 0 ? (
        <>
          <StyledTooltip
            title={row.segments.map((t: string, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <div>
              <ListAltOutlined />
            </div>
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  {
    name: 'groupNames',
    title: 'Groups',
    width: 120,
    getCellValue: (row: any) =>
      row.groupNames && row.groupNames.length > 0 ? (
        <>
          <StyledTooltip
            title={row.groupNames.map((t: string, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <div>
              <ListAltOutlined />
            </div>
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },

  {
    name: 'edit',
    title: 'Access Edit ',
    width: 200,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <Link
            to={{
              pathname: `/surveys/${row.surveyId}/accesses/edit/${row.accessId}`,
              state: {
                surveyName: row.surveyName,
                subgroup: row.subGroupSurvey,
              },
            }}
          >
            <EditOutlined />
          </Link>
        </>
      );

      return iconProperties;
    },
  },

  {
    name: 'Survey Edit',
    title: 'Survey Edit',
    width: 200,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <Link
            to={{
              pathname: `/surveys/${row.surveyId}`,
            }}
          >
            <EditOutlined />
          </Link>
        </>
      );

      return iconProperties;
    },
  },
];

export const columnsSelect: any[] = [
  {
    name: 'accessId',
    title: 'Access ID',
  },
  {
    name: 'accessName',
    title: 'Access',
  },
  {
    name: 'surveyId',
    title: 'Survey ID',
  },
  {
    name: 'surveyName',
    title: 'Survey',
  },

  {
    name: 'subGroupSurvey',
    title: 'User Group',
  },
  {
    name: 'segments',
    title: 'Segments',
    width: 120,
    getCellValue: (row: any) =>
      row.segments && row.segments.length > 0 ? (
        <>
          <StyledTooltip
            title={row.segments.map((t: string, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <div>
              <ListAltOutlined />
            </div>
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  {
    name: 'groupNames',
    title: 'Groups',
    width: 120,
    getCellValue: (row: any) =>
      row.groupNames && row.groupNames.length > 0 ? (
        <>
          <StyledTooltip
            title={row.groupNames.map((t: string, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <div>
              <ListAltOutlined />
            </div>
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
];
