import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';
import { Container, Content, Button } from './styles';
import api from '../../services/api';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { useToast } from '../../hooks/toast';

interface AccessGroup {
  accessGroupId: number;
  name: string;
  accessIds: number[];
}

const AccessGroup: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<AccessGroup[]>([]);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: true },
    { columnName: 'accessGroupId', width: 120 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/accessGroup/edit/${row.accessGroupId}`);
    },
    [history],
  );

  const handleClickDelete = useCallback(
    (row: any) => {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the group ${row.accessGroupId}?`,
        );

        if (userWantDelete) {
          api.delete(`/accessesGroup/${row.accessGroupId}`).then(response => {
            if (response.data && response.data.deleted === true) {
              history.push('/projects');
              history.push('/accessgroup');
            } else if (response.data.message) {
              addToast({
                type: 'info',
                title: 'The group was not deleted ',
                description: `${response.data.message}`,
              });
            } else {
              throw response.data;
            }
          });
        }
      } catch (err) {
        if (err) {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Updating Error',
            description: `An error occurred while deleting this group, please try again.`,
          });
        }
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditOutlined />,
    },

    {
      columnName: 'delete',
      label: '',
      onClick: handleClickDelete,
      icon: <DeleteOutline />,
    },
  ]);

  const customFilterPredicateAccessIds = useCallback((value, filter, row) => {
    return row.accessIds?.some((a: number) => {
      return `${a}` === filter.value;
    });
  }, []);

  const integratedFilteringColumnExtensions = [
    { columnName: 'accessIds', predicate: customFilterPredicateAccessIds },
  ];

  useEffect(() => {
    setLoading(true);
    api.get('accessesGroup').then(response => {
      setGroups(response.data);
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Access Groups </h1>

      <Link
        to={{
          pathname: `/accessgroup/new`,
        }}
      >
        <Button variant="contained" type="button">
          New Group
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={groups}
          checkboxSelection={false}
          actionColumns={actionColumns}
          tableColumnExtensions={tableColumnExtensions}
          idName={'accessGroupId'}
          hasFilterRow={true}
          integratedFilteringColumnExtensions={
            integratedFilteringColumnExtensions
          }
        ></Table>
      </Content>
    </Container>
  );
};

export default AccessGroup;
