import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';

import ExcelJs from 'exceljs';
import { IActionColumn } from '../../../components/DevExpressTable/ActionsColumn';
import api from '../../../services/api';
import { Content } from '../styles';

import Table from '../../../components/DevExpressTable/DevExpressTable';
import { DownloadIcon, Header } from './styles';

interface ParamTypes {
  projectId: string;
}

const ProjectAnalytics: React.FC = () => {
  const { projectId } = useParams<ParamTypes>();

  const [listAccessProject, setListAccessProject] = useState<any[]>([]);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'download', width: 50 },
  ]);

  const handleClickDownload = useCallback(
    async (row: any) => {
      const workbook = new ExcelJs.Workbook();

      // get List textQuestions and answers to fill allDataExcel1QA2ZW
      await api
        .post(`/results/project/exportResultsTextQuestions/${projectId}`, {
          accessNameFilter: row.accessName,
        })
        .then(response => {
          response.data.forEach((dataMock: any) => {
            const sheet = workbook.addWorksheet(dataMock.nameSheet);

            sheet.columns = [
              // {
              //   header: 'SurveyID',
              //   key: 'surveyId',
              //   width: 30,
              // },
              {
                header: 'Gruppe',
                key: 'subgroup',
                width: 30,
              },
              // {
              //   header: 'ResultID',
              //   key: 'resultId',
              //   width: 30,
              // },
              // {
              //   header: 'QuestionID',
              //   key: 'questionId',
              //   width: 30,
              // },
              {
                header: 'Antwort',
                key: 'answer',
                width: 60,
              },
              {
                header: 'Categories',
                key: 'category',
                width: 60,
              },
              {
                header: 'Categories_2',
                key: 'category_2',
                width: 60,
              },
            ];

            dataMock.data.forEach((answer: any) => {
              sheet.addRow({
                // surveyId: answer.surveyId,
                subgroup: answer.subgroup,
                // resultId: answer.resultId,
                // questionId: answer.questionId,
                answer: answer.answer,
                category: answer.category,
                category_2: answer.category_2,
              });
            });

            // Insert a row by sparse Array (assign to columns A, E & I)
            const rowValues = [];
            rowValues[1] = dataMock.textQuestion;

            // insert new row and return as row object
            sheet.insertRow(1, rowValues);
            sheet.mergeCells(1, 1, 1, sheet.columns.length);

            sheet.getCell('A1').font = {
              // name: 'Comic Sans MS',
              // family: 4,
              // size: ,
              // underline: true,
              bold: true,
            };
            sheet.getCell('A1').alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };

            sheet.getCell('A2').font = {
              bold: true,
            };
            sheet.getCell('A2').alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };
            sheet.getCell('B2').font = {
              bold: true,
            };
            sheet.getCell('B2').alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };
            sheet.getCell('C2').font = {
              bold: true,
            };
            sheet.getCell('C2').alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };
            sheet.getCell('D2').font = {
              bold: true,
            };
            sheet.getCell('D2').alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };
          });
        });

      await workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Answers_project_${projectId}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },
    [projectId],
  );

  useEffect(() => {
    api.get(`/accesses/getByProject/${projectId}`).then(response => {
      if (response && response.data) {
        setListAccessProject(response.data);
      }
    });
  }, [projectId]);

  const exportToExcelByProject = useCallback(async () => {
    const workbook = new ExcelJs.Workbook();

    // get List textQuestions and answers to fill allDataExcel1QA2ZW
    await api
      .post(`/results/project/exportResultsTextQuestions/${projectId}`, {
        accessNameFilter: '',
      })
      .then(response => {
        response.data.forEach((dataMock: any) => {
          const sheet = workbook.addWorksheet(dataMock.nameSheet);

          sheet.columns = [
            // {
            //   header: 'SurveyID',
            //   key: 'surveyId',
            //   width: 30,
            // },
            {
              header: 'Gruppe',
              key: 'subgroup',
              width: 30,
            },
            // {
            //   header: 'ResultID',
            //   key: 'resultId',
            //   width: 30,
            // },
            // {
            //   header: 'QuestionID',
            //   key: 'questionId',
            //   width: 30,
            // },
            {
              header: 'Antwort',
              key: 'answer',
              width: 60,
            },
            {
              header: 'Category',
              key: 'category',
              width: 60,
            },
            {
              header: 'Category_2',
              key: 'category_2',
              width: 60,
            },
          ];

          dataMock.data.forEach((answer: any) => {
            sheet.addRow({
              // surveyId: answer.surveyId,
              subgroup: answer.subgroup,
              // resultId: answer.resultId,
              // questionId: answer.questionId,
              answer: answer.answer,
              category: answer.category,
              category_2: answer.category_2,
            });
          });

          // Insert a row by sparse Array (assign to columns A, E & I)
          const rowValues = [];
          rowValues[1] = dataMock.textQuestion;

          // insert new row and return as row object
          sheet.insertRow(1, rowValues);
          sheet.mergeCells(1, 1, 1, sheet.columns.length);

          sheet.getCell('A1').font = {
            // name: 'Comic Sans MS',
            // family: 4,
            // size: ,
            // underline: true,
            bold: true,
          };

          sheet.getCell('A1').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          sheet.getCell('A2').font = {
            bold: true,
          };
          sheet.getCell('A2').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
          sheet.getCell('B2').font = {
            bold: true,
          };
          sheet.getCell('B2').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
          sheet.getCell('C2').font = {
            bold: true,
          };
          sheet.getCell('C2').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
          sheet.getCell('D2').font = {
            bold: true,
          };
          sheet.getCell('D2').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
        });
      });

    await workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `Answers_project_${projectId}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }, [projectId]);

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'download',
      label: '',
      onClick: handleClickDownload,
      icon: <DownloadIcon />,
    },
  ]);

  return (
    <>
      <Header>
        <h1>Open Answers</h1>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={exportToExcelByProject}
        >
          Download ALL
        </Button>
      </Header>

      {projectId !== '' && (
        <>
          <Content>
            <Table
              columnsProp={[
                { name: 'accessName', title: 'Access' },
                { name: 'download', title: '' },
              ]}
              dataProp={listAccessProject}
              checkboxSelection={false}
              tableColumnExtensions={tableColumnExtensions}
              actionColumns={actionColumns}
              // hasFilterRow={false}
              idName={'accessName'}
            ></Table>
          </Content>
        </>
      )}
    </>
  );
};

export default ProjectAnalytics;
