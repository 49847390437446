import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { EditOutlined } from '@material-ui/icons';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';
import { Container, Content, Button } from './styles';
import api from '../../services/api';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';

interface Apillar {
  apillarId: number;
  name: string;
}

const Apillars: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [apillars, setApillars] = useState<Apillar[]>([]);
  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'apillarId', width: 200 },
  ]);

  const toLowerCase = (value: string): string => String(value).toLowerCase();

  const customFilterPredicateCategories = useCallback((value, filter, row) => {
    return row.categories?.some((a: string) =>
      toLowerCase(a).includes(toLowerCase(filter.value)),
    );
  }, []);

  const customFilterPredicateQuestions = useCallback((value, filter, row) => {
    return row.questions?.some((a: number) => {
      return `${a}` === filter.value;
    });
  }, []);

  const integratedFilteringColumnExtensions = [
    { columnName: 'categories', predicate: customFilterPredicateCategories },
    { columnName: 'questions', predicate: customFilterPredicateQuestions },
  ];

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/apillars/edit/${row.apillarId}`);
    },
    [history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditOutlined />,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('apillars').then(response => {
      setApillars(response.data);
    });
    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>A-Pillars</h1>

      <Link to="/apillars/new">
        <Button variant="contained" type="button">
          New Apillar
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={apillars}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          integratedFilteringColumnExtensions={
            integratedFilteringColumnExtensions
          }
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'apillarId'}
        ></Table>
      </Content>
    </Container>
  );
};
export default Apillars;
