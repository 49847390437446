import styled from 'styled-components';
import '@nosferatu500/react-sortable-tree/style.css';
import SortableTree from '@nosferatu500/react-sortable-tree';

export const Container = styled.div`
  height: 100%;
`;

export const ContainerStructure = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 5px 5px 5px 5px;
  margin: 5px;

  font-size: 0.9em;
  h2 {
    margin-top: 10px;
  }
`;

export const ContainerSortableTree = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 400px;
  padding: 5px 5px 20px 5px;
  margin: 5px;
  font-size: 1em;
  h2 {
    margin-top: 10px;
  }
  box-shadow: inset 0.01em 0 0.02em gray, 2px 0 0.2em gray;
`;

export const ContainerSortableList = styled.div`
  flex: 1;
  padding: 5px 10px 85px 10px;
  margin: 5px;
  height: 315px !important;

  margin: 5px;
  box-shadow: inset 0.01em 0 0.02em gray, 2px 0 0.2em gray;
  input {
    margin-bottom: 15px;
    width: 480px;
    padding: 3px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
`;

export const ContainerAddButton = styled.div`
  align-self: flex-end;
  margin-left: 30px;
  margin-bottom: 15px;
`;

export const AddButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const SortableTreeStyled = styled(SortableTree)`
  .ReactVirtualized__Grid .ReactVirtualized__List .rst__virtualScrollOverride {
    direction: ltr;
    flex: 1 !important;
    padding: 10px 0 5px 5px;
    height: 300px !important;
    box-shadow: inset 0.03em 0 0.02em gray, 2px 0 0.2em gray;
    border-radius: 5px;

    will-change: transform;
    div {
      height: 100% !important;
      width: 100% !important;
    }
  }
  .rst__rowTitle {
    font-weight: normal !important;
    font-size: 80%;
  }

  .rst__placeholder,
  .rst__placeholder > * {
    box-sizing: border-box;
  }

  .rst__placeholder {
    position: relative;
    height: 68px;
    max-width: 300px;
    padding: 10px;
  }

  .rst__placeholder::before {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    padding: 3px;
    left: 5px;
    content-visibility: visible !important;
    content: 'Set 2 levels of categories at least to enable drag & drop questions.' !important;
    color: gray;
    font-size: small;
    z-index: 0;
  }
  .rstcustom__nodeContent {
    position: absolute;
    top: 0;
    bottom: 0;
    width: auto;
  }
`;

export const Loader = styled.div`
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #999 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SortableList = styled(SortableTree)`
  .rst__rowTitle {
    font-size: 0.88em;
    font-weight: normal;
  }

  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    width: 0;
    top: 0;
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    position: absolute;
    content: '';
    background-color: transparent;
  }
`;

export const DivModal = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: stretch;

  transform: 'translate(-50%, -50%)';
  width: 400;
  background-color: red;
  border: '2px solid #000';
`;

export const ButtonCreateCategory = styled.button`
  background-color: #00b698;
  color: white;
  border: 0;
  border-radius: 5px;
  &:hover {
    background-color: #009179;
  }
  padding: 3px 30px;
  margin-top: 10px;
  flw
`;

export const DivButtonEditAnswer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
