/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';

import { columns } from './columns';
import {
  BoxCol,
  BoxColTitle,
  BoxRow,
  Container,
  Content,
  ContentIntegrationList,
} from './styles';
import api from '../../../../services/api';
import Table from '../../../../components/DevExpressTable/DevExpressTable';
import Button from '../../../../components/Button';
import { useToast } from '../../../../hooks/toast';
import EnumIntegrationType from '../../../../utils/enums/EnumIntegrationType';
import getIntegrationStatusDescription from '../../../../utils/getIntegrationStatusDescription';
import EnumIntegrationStatusDataWarehouse from '../../../../utils/enums/EnumIntegrationStatusDataWarehouse';
import { useAuth } from '../../../../hooks/auth';

interface Survey {
  surveyId: number;
  title: string;
  name: string;
  year: number;
}

interface DataSchema {
  surveyId: number;
}

interface Integration {
  integrationId?: number;
  groupId: number;
  groupStatus: string;
  totalRecordsInGroup: number;
  origin: string;
  destination: string;
  url: string;
  type: number;
  status: number;
  statusName?: string;
  created_by: string;
  created_at: Date;
  indexInGroup: number;
  dataSchema: DataSchema;
}

const SurveyListToExport: React.FC = () => {
  const { user } = useAuth();
  const [integrationsList, setIntegrationsList] = useState<Integration[]>([]);
  const [
    totalIntegrationsRunningOrPendingFromLoggedUser,
    setTotalIntegrationsRunningOrPendingFromLoggedUser,
  ] = useState<number>(0);
  const [lastGroupId] = useState<number | null>(null);
  const { addToast } = useToast();
  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [enableExportButton, setEnableExportButton] = useState<boolean>(true);
  const [enableCancelButton, setEnableCancelButton] = useState<boolean>(false);

  const [tableColumnExtensions] = useState([
    { columnName: 'surveyId', width: 90 },
    { columnName: 'projectCustomerName', width: 300 },
    { columnName: 'year', width: 80 },
    { columnName: 'removeFromList', width: 180 },
  ]);

  const checkTotalSelectedRows = useCallback(
    (totalRowIds: number): boolean => {
      return totalRowIds + totalIntegrationsRunningOrPendingFromLoggedUser > 5;
    },
    [totalIntegrationsRunningOrPendingFromLoggedUser],
  );

  const handleSelectRows = useCallback(
    (rowIds: any) => {
      if (!checkTotalSelectedRows(rowIds.length)) {
        setSelectedRowIds(rowIds);
      } else {
        rowIds.pop();
        setSelectedRowIds(rowIds);
        addToast({
          type: 'info',
          title: 'Warning',
          description: 'Cannot export more than 5 (five) surveys at once!',
        });
      }
    },
    [addToast, checkTotalSelectedRows],
  );

  const getIntegrationsList = useCallback(async () => {
    await api
      .post('/integrations/getPendingAndRunningIntegrations', {})
      .then(response => {
        if (response.data) {
          const integrations = response.data
            .sort((a: any, b: any) => {
              return b.created_at - a.created_at;
            })
            .sort((a: any, b: any) => {
              return a.integrationId - b.integrationId;
            })
            .map((item: Integration) => {
              return {
                ...item,

                statusName: getIntegrationStatusDescription(
                  Object.keys(EnumIntegrationStatusDataWarehouse)[
                    Object.values(EnumIntegrationStatusDataWarehouse).indexOf(
                      item.status,
                    )
                  ],
                ),
              };
            });

          const integrationsFromLoggedUser = integrations.filter(
            (item: Integration) => item.created_by === user.email,
          );

          setTotalIntegrationsRunningOrPendingFromLoggedUser(
            integrationsFromLoggedUser.length,
          );

          setIntegrationsList(integrations);
        }
      });
  }, [user.email]);

  const getSurveysCanExport = useCallback(() => {
    api.post('/surveys/allCanBeExported').then(response => {
      const surveysResponse = response.data.map((x: Survey) => {
        return {
          ...x,
        };
      });
      surveysResponse.sort((a: any, b: any) => {
        return b.surveyId - a.surveyId;
      });
      setSurveys(surveysResponse);
    });
  }, []);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const fetchData = async (): Promise<void> => {
      if (mounted) {
        setEnableExportButton(selectedRowIds.length > 0);
        setEnableCancelButton(
          totalIntegrationsRunningOrPendingFromLoggedUser > 0,
        );
        getSurveysCanExport();
        await getIntegrationsList();
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [
    getIntegrationsList,
    getSurveysCanExport,
    selectedRowIds.length,
    totalIntegrationsRunningOrPendingFromLoggedUser,
  ]);

  const handleCancelIntegrations = useCallback(async () => {
    const integrationsToBeCanceled = integrationsList.filter(
      (integration: Integration) => integration.created_by === user.email,
    );
    if (integrationsToBeCanceled.length > 0) {
      integrationsToBeCanceled.forEach((integration: Integration) => {
        api
          .put(`/integrations/cancelPendingIntegrationById`, {
            integrationId: integration.integrationId,
          })
          .then(() => {
            getIntegrationsList();
          });
      });
      addToast({
        type: 'success',
        title: 'Success',
        description: 'Pending Integrations canceled!',
      });
    }
  }, [addToast, getIntegrationsList, integrationsList, user.email]);

  const handleExportToDataWarehouse = useCallback(async () => {
    if (!selectedRowIds && selectedRowIds.length === 0) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Select at least one survey to export!',
      });
      return;
    }

    const groupId = !lastGroupId ? 1 : lastGroupId + 1;

    selectedRowIds.sort((a: any, b: any) => {
      return a - b;
    });

    const integrationListFromLoggedUser = integrationsList
      .filter(
        (integration: Integration) => integration.created_by === user.email,
      )
      .sort((a: any, b: any) => {
        return a.indexInGroup - b.indexInGroup;
      });

    const indexToBeCreated =
      integrationListFromLoggedUser.length > 0
        ? integrationListFromLoggedUser[
            integrationListFromLoggedUser.length - 1
          ].indexInGroup + 1
        : 0;

    await Promise.all(
      await selectedRowIds.map(async (item: any, index: number) => {
        const integrationToBeCreated: Integration = {
          url: `/results/survey/export/exportDataWareHouse`,
          groupId,
          groupStatus: 'pending',
          indexInGroup:
            integrationListFromLoggedUser.length === 0
              ? index
              : indexToBeCreated + index,
          totalRecordsInGroup: selectedRowIds.length,
          origin: 'user',
          destination: 'datawarehouse',
          status: 0, // pending
          created_by: 'fake-email@emotion-banking.at', // email is getting set by backend
          created_at: new Date(),
          type: EnumIntegrationType.DataWarehouse,
          dataSchema: {
            surveyId: Number(item),
          },
        };

        await api.post(
          '/integrations/groupedIntegrations',
          integrationToBeCreated,
        );
      }),
    );
    getIntegrationsList();
    setSelectedRowIds([]);
    addToast({
      type: 'success',
      title: 'Success',
      description:
        'The data was prepared to be exported. Please wait until the transaction finish!',
    });
  }, [
    addToast,
    getIntegrationsList,
    integrationsList,
    lastGroupId,
    selectedRowIds,
    user.email,
  ]);

  useEffect(() => {
    const id = setInterval(getIntegrationsList, 5000);
    setEnableCancelButton(totalIntegrationsRunningOrPendingFromLoggedUser > 0);
    return () => clearInterval(id);
  }, [getIntegrationsList, totalIntegrationsRunningOrPendingFromLoggedUser]);

  return (
    <Container className={loading ? 'loading' : ''}>
      {<h1>Select surveys to export to datawarehouse</h1>}

      <Content>
        <div style={{ marginBottom: 45 }}>
          <Button
            disabled={!enableExportButton}
            marginRight="35px"
            width="210px"
            height="40px"
            onClick={handleExportToDataWarehouse}
          >
            Export DataWarehouse
          </Button>

          <Button
            disabled={!enableCancelButton}
            marginRight="35px"
            width="250px"
            height="40px"
            onClick={handleCancelIntegrations}
          >
            Cancel Pending Integrations
          </Button>
        </div>
        <Table
          columnsProp={columns}
          dataProp={surveys}
          defaultPageSize={10}
          selectionProp={selectedRowIds}
          multiSelection={true}
          checkboxSelection={true}
          setSelectedRowId={(rowId: any) => handleSelectRows(rowId)}
          tableColumnExtensions={tableColumnExtensions}
          hasFilterRow={true}
          idName={'surveyId'}
        ></Table>
      </Content>
      <Content>
        <ContentIntegrationList>
          {integrationsList ? (
            <>
              {integrationsList.map(
                (integration: Integration, index: number) => (
                  <BoxRow key={index}>
                    <BoxColTitle>
                      <strong>IntegrationId:</strong>
                    </BoxColTitle>
                    <BoxCol>
                      <span>{integration?.integrationId ?? index + 12}</span>
                    </BoxCol>

                    <BoxColTitle>
                      <strong>Status:</strong>
                    </BoxColTitle>
                    <BoxCol>
                      <span>{integration?.statusName ?? 'Pending'}</span>
                    </BoxCol>

                    <BoxColTitle>
                      <strong>SurveyId:</strong>
                    </BoxColTitle>
                    <BoxCol>
                      <span>{integration?.dataSchema.surveyId ?? ''}</span>
                    </BoxCol>

                    <BoxColTitle>
                      <strong>Type:</strong>
                    </BoxColTitle>
                    <BoxCol>
                      <span>{EnumIntegrationType[integration?.type]}</span>
                    </BoxCol>

                    <BoxColTitle>
                      <strong>Created By:</strong>
                    </BoxColTitle>
                    <BoxCol>
                      <span>{integration?.created_by}</span>
                    </BoxCol>
                  </BoxRow>
                ),
              )}
            </>
          ) : (
            <></>
          )}
        </ContentIntegrationList>
      </Content>
    </Container>
  );
};

export default SurveyListToExport;
