import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div``;

export const DivCombobox = styled.div`
  margin-top: 20px;
  line-height: 40px;
  font-size: 20px;

  #labelHideProgressText {
    margin-left: 10px;
    vertical-align: super;
  }

  #cbProgressText {
    width: 30px;
    height: 30px;
  }
`;
