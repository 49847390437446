import React, { createContext, useCallback, useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';

import BoxMessageContainer from '../components/BoxMessageContainer';

export interface BoxMessage {
  id: string;
  type?: 'success' | 'error' | 'info' | 'warning';
  title: string;
  description?: string;
}

interface BoxMessageContextData {
  addBoxMessage(message: Omit<BoxMessage, 'id'>): void;
  removeBoxMessage(id: string): void;
}

const BoxMessageContext = createContext<BoxMessageContextData>(
  {} as BoxMessageContextData,
);

const BoxMessageProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<BoxMessage[]>([]);

  const addBoxMessage = useCallback(
    ({ type, title, description }: Omit<BoxMessage, 'id'>) => {
      const id = uuid();

      const toast = {
        id,
        type,
        title,
        description,
      };

      setMessages(state => [...state, toast]);
    },
    [],
  );

  const removeBoxMessage = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <BoxMessageContext.Provider value={{ addBoxMessage, removeBoxMessage }}>
      {children}
      <BoxMessageContainer messages={messages} />
    </BoxMessageContext.Provider>
  );
};

function useBoxMessage(): BoxMessageContextData {
  const context = useContext(BoxMessageContext);

  if (!context)
    throw new Error('useBoxMessage must be used within an BoxMessageProvider');

  return context;
}

export { BoxMessageProvider, useBoxMessage };
