import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import api from '../../../services/api';

interface SurveyParams {
  accessId: string;
  code: string;
}

// TODO: REMOVE THIS COMPONENT. IT IS TEMPORARY
const listAllowedAccessRedirection = ['2092', '2093', '2421'];

const RedirectionOldLinks: React.FC = () => {
  const { params } = useRouteMatch<SurveyParams>();

  const history = useHistory();

  useEffect(() => {
    const { accessId } = params;

    if (listAllowedAccessRedirection.includes(accessId)) {
      api.get(`/accesses/${params.accessId}/full`).then(response => {
        // eslint-disable-next-line no-underscore-dangle
        const objectId = response.data._id;
        const parameters = window.location.href.split('?')[1];

        if (objectId) {
          const newUrl = `https://meine-sicht.com/questionnaire/${objectId}/${params.accessId}?${parameters}`;
          window.location.replace(newUrl);
        }
      });
    }
  }, [history, params]);

  return <></>;
};

export default RedirectionOldLinks;
