import styled from 'styled-components';

import DownloadIconMui from '@material-ui/icons/GetApp';

export const Container = styled.div``;

export const Content = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DownloadIcon = styled(DownloadIconMui)`
  display: table-cell;
  vertical-align: middle;
`;
