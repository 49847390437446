import styled from 'styled-components';

export const Subtitle = styled.p`
  font-size: 20px;
  margin-left: 5px;
  margin-top: 40px;
`;

export const ContainerDragDrop = styled.div`
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;

  h4 {
    margin: 20px 0 10px;
  }
`;

export const BoxQuestionsPodium = styled.div`
  background-color: #edebe2;
`;

export const box = styled.div`
  border: 3px solid #666;
  background-color: #ddd;
  border-radius: 0.5em;
  padding: 10px;
  cursor: move;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: top;

  width: 100%;
  max-width: 350px;

  span {
    font-size: 26px;
  }
`;
