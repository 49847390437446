import React, { useCallback, useEffect, useState } from 'react';

import { read, utils } from 'xlsx';

import Select from 'react-select';

import {
  ColumnFile,
  Container,
  Content,
  ContentFile,
  DivButtonSubmit,
} from './styles';
import api from '../../../services/api';

import Button from '../../../components/Button';

interface QuestionTranslation {
  ID: string;
  Question_Text: string;
  Question_Text_Translation: string;
  Answer_Options: string;
  Answer_Options_Translation: string;
}

interface Language {
  languageId: number;
  name: string;
}

interface DefaultLanguage {
  value: string;
  label: string;
}

const ImportFile: React.FC = () => {
  const [translations, setTranslations] = useState<QuestionTranslation[]>();
  const [isSeelectedFile, setIsSelectedFile] = useState(false);
  const [showButtonSubmit, setShowButtonSubmit] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSelectedLanguage, setIsSelectedLanguage] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState({
    value: '0',
    label: 'Select...',
  } as DefaultLanguage);
  const [questionDefaultLanguages, setQuestionDefaultLanguages] = useState(
    [] as DefaultLanguage[],
  );

  const [responseImport, setResponseImport] = useState([]);

  useEffect(() => {
    api.get('languages').then(response => {
      const languageList: DefaultLanguage[] = [];
      if (response.data) {
        response.data.forEach((language: Language) => {
          languageList.push({
            value: `${language.languageId}`,
            label: language.name,
          });
        });
      }
      setQuestionDefaultLanguages(languageList);
    });
  }, []);

  async function ValidationDataFile(
    data: QuestionTranslation[],
  ): Promise<string[]> {
    const errorsFromFile: string[] = [];
    const idQuestionsWithoutOptions: string[] = [];
    data.forEach((element: QuestionTranslation) => {
      if (!element.ID) {
        errorsFromFile.push('ID is required');
      }
      if (!element.Question_Text) {
        errorsFromFile.push('Question_Text is required');
      }
      if (!element.Answer_Options && element.ID) {
        // if it's not text question, then it is required
        idQuestionsWithoutOptions.push(element.ID);
      }
    });

    if (idQuestionsWithoutOptions.length > 0) {
      await api
        .get(
          `/questions/getQuestionsByMultipleIds/${idQuestionsWithoutOptions}`,
        )
        .then(response => {
          if (response.data) {
            const nonTextQuestionIds: number[] = response.data
              ?.filter((x: any) => x.type !== 'Text')
              ?.map((y: any) => {
                return y.questionId;
              });

            if (nonTextQuestionIds.length === 1) {
              errorsFromFile.push(
                `Answer_Options is required for question: ${nonTextQuestionIds?.join(
                  ', ',
                )}`,
              );
            }

            if (nonTextQuestionIds.length > 1) {
              errorsFromFile.push(
                `Answer_Options is required for questions: ${nonTextQuestionIds?.join(
                  ', ',
                )}`,
              );
            }
          }
        });
    }

    return errorsFromFile;
  }

  const onChange = async (e: any): Promise<void> => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();

    const wb = read(f);
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const data = utils.sheet_to_json<QuestionTranslation>(ws); // generate objects
    setIsSelectedFile(true);

    const errorsFromFile = await ValidationDataFile(data);
    setErrors(errorsFromFile);
    setTranslations(data);
    setShowButtonSubmit(false);

    if (errorsFromFile.length === 0) {
      setShowButtonSubmit(true);
    }
  };

  const handleLanguageDefaultChange = useCallback(language => {
    setDefaultLanguage(language);
    setIsSelectedLanguage(true);
  }, []);

  const handleImportSubmit = useCallback(() => {
    setShowButtonSubmit(!showButtonSubmit);
    try {
      api
        .post('questions/importTranslationFile', {
          languageId: +defaultLanguage.value,
          data: translations,
        })
        .then(response => {
          setResponseImport(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, [defaultLanguage.value, showButtonSubmit, translations]);

  return (
    <>
      <h1>Import Translations</h1> <br />
      <Container>
        <Content>
          <Select
            name="defaultLanguageId"
            value={defaultLanguage}
            options={questionDefaultLanguages}
            onChange={e => handleLanguageDefaultChange(e)}
          />
        </Content>
        <ContentFile>
          <div className="App" hidden={!isSelectedLanguage}>
            <div className="upload-btn-wrapper">
              <input type="file" name="myfile" onChange={onChange} />
            </div>
            <br />
          </div>
        </ContentFile>
      </Container>
      <table hidden={!isSeelectedFile}>
        <thead>
          <th>ID</th>
          <th>Question Text</th>
          <th>Question Text Translation</th>
          <th>Answer Options</th>
          <th>Answer Options Translation</th>
        </thead>
        <tbody>
          {translations?.map((t: QuestionTranslation) => (
            <tr key={t.ID}>
              <ColumnFile>{t.ID}</ColumnFile>
              <ColumnFile>{t.Question_Text}</ColumnFile>
              <ColumnFile>{t.Question_Text_Translation}</ColumnFile>
              <ColumnFile>{t.Answer_Options}</ColumnFile>
              <ColumnFile>{t.Answer_Options_Translation}</ColumnFile>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        hidden={errors.length === 0}
        style={{
          margin: '20px 10px 10px 10px',
          padding: '10px 10px 15px 10px',
          borderRadius: '8px',
          backgroundColor: '#fddede',
          color: '#c53030',
        }}
      >
        <>
          <h3>Errors:</h3>
          <ul>
            {errors.length > 0 &&
              errors.map(error => {
                return (
                  <li style={{ marginLeft: '40px' }} key={error}>
                    {error}
                  </li>
                );
              })}
          </ul>
        </>
      </div>
      <DivButtonSubmit>
        <Button
          hidden={!showButtonSubmit}
          type="button"
          onClick={handleImportSubmit}
          marginRight="30px"
          width="200px"
          height="40px"
        >
          Submit
        </Button>
      </DivButtonSubmit>
      <div hidden={showButtonSubmit || !isSeelectedFile || errors.length > 0}>
        <h2>Import result:</h2>
        {responseImport.map((questionFb: string) => {
          return <p key={questionFb}>{questionFb}</p>;
        })}
      </div>
    </>
  );
};

export default ImportFile;
