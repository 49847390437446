import React, { useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';
import FormLanguage from '../Form';

import { languagesISO6391 } from '../languagesISO6391';

interface Language {
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  accessLockedMessage: string;
  codeNotExistsMessage: string;
  surveyCompletedMessage: string;
  languageCode: string;
}
interface ParamTypes {
  id: string;
}

const EditLanguage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    api.get(`/languages/${id}`).then(response => {
      formRef.current?.setFieldValue('name', response.data.name);
      formRef.current?.setFieldValue(
        'pagePrevText',
        response.data.pagePrevText,
      );
      formRef.current?.setFieldValue(
        'pageNextText',
        response.data.pageNextText,
      );
      formRef.current?.setFieldValue(
        'completeText',
        response.data.completeText,
      );
      formRef.current?.setFieldValue(
        'nameButtonStartText',
        response.data.nameButtonStartText,
      );
      formRef.current?.setFieldValue(
        'progressText',
        response.data.progressText,
      );
      formRef.current?.setFieldValue(
        'requiredErrorText',
        response.data.requiredErrorText,
      );
      formRef.current?.setFieldValue(
        'placeholderTextQuestion',
        response.data.placeholderTextQuestion,
      );
      formRef.current?.setFieldValue(
        'requiredAllrowsErrorText',
        response.data.requiredAllrowsErrorText,
      );
      formRef.current?.setFieldValue(
        'textFootnote',
        response.data.textFootnote,
      );
      formRef.current?.setFieldValue(
        'textLinkFootnote',
        response.data.textLinkFootnote,
      );

      formRef.current?.setFieldValue(
        'accessLockedMessage',
        response.data?.accessLockedMessage,
      );
      formRef.current?.setFieldValue(
        'codeNotExistsMessage',
        response.data?.codeNotExistsMessage,
      );
      formRef.current?.setFieldValue(
        'surveyCompletedMessage',
        response.data?.surveyCompletedMessage,
      );
      formRef.current?.setFieldValue(
        'contactUsMessage',
        response.data?.contactUsMessage,
      );

      if (response.data?.languageCode) {
        formRef.current?.setFieldValue(
          'languageCode',
          languagesISO6391.find(x => x.value === response.data?.languageCode),
        );
      }
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: Language) => {
      try {
        const editLanguage = { ...data };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Customer name is required'),
          pagePrevText: Yup.string().required('pagePrevText is required'),
          pageNextText: Yup.string().required('pageNextText is required'),
          completeText: Yup.string().required('completeText is required'),
          nameButtonStartText: Yup.string().required(
            'nameButtonStartText is required',
          ),
          progressText: Yup.string().required('progressText is required'),
          requiredErrorText: Yup.string().required(
            'requiredErrorText is required',
          ),
          placeholderTextQuestion: Yup.string().required(
            'placeholderTextQuestion is required',
          ),
          requiredAllrowsErrorText: Yup.string().required(
            'requiredAllrowsErrorText is required',
          ),

          accessLockedMessage: Yup.string().required(
            'accessLockedMessage is required',
          ),
          codeNotExistsMessage: Yup.string().required(
            'codeNotExistsMessage is required',
          ),
          surveyCompletedMessage: Yup.string().required(
            'surveyCompletedMessage is required',
          ),
          contactUsMessage: Yup.string().required(
            'contactUsMessage is required',
          ),
        });

        await schema.validate(editLanguage, {
          abortEarly: false,
        });

        await api.put(`/languages/${id}`, editLanguage);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The language was saved successfully!',
        });

        history.push('/languages');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while updating languages, please try again.',
        });
      }
    },
    [addToast, history, id],
  );

  return (
    <Container>
      <h1>Edit Language</h1>
      <FormLanguage
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Save"
      />
    </Container>
  );
};
export default EditLanguage;
