import { EditOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import Input from '../../../components/FormInput';

export const Container = styled.div``;

export const Content = styled.div``;

export const DivTranslation = styled.div`
  margin-left: 40px;
`;

export const TranslationDiv = styled.div`
  width: 50%;
`;

export const LanguageDiv = styled.div`
  display: flex;
`;

export const OriginalField = styled.div`
  width: 50%;
`;

export const TranslationArrow = styled.span`
  color: #00b698;
  padding: 5px;
  border-radius: 50%;

  font-size: 30px;
`;

export const TranslationArrowDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 6px;
  margin: 0 10px 0 10px;
`;

export const EditIcon = styled(EditOutlined)`
  display: table-cell;
  vertical-align: middle;
`;

export const DivEditLanguageButton = styled.span`
  margin: 0 10px;
  position: absolute;
  @media (max-width: 1195px) {
    position: relative;
  }
`;

export const EditLanguageButton = styled.button`
  background-color: #00b698;
  color: white;
  border: 0;
  &:hover {
    background-color: #009179;
  }
  padding: 0px 20px;
`;

export const H2Relative = styled.h2`
  position: relative;
`;

export const InputNumber = styled(Input)`
  /* Hide the up and down arrows */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Disable scroll event */
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-user-select: none;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const DivAddNewTag = styled.span`
  margin: 10px 10px 0 0;
  @media (max-width: 1195px) {
    position: relative;
  }
`;

export const AddNewTagButton = styled.button`
  background-color: #00b698;
  margin: 10px 0 0 0;
  color: white;
  border: 0;
  &:hover {
    background-color: #009179;
  }
  padding: 0px 20px;
`;
