/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';

import { Container, BoxCol, BoxColTitle, BoxRow } from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import EnumIntegrationType from '../../../utils/enums/EnumIntegrationType';
import getIntegrationStatusDescription from '../../../utils/getIntegrationStatusDescription';

interface DataSchema {
  surveyId: number;
  projectId: number;
  userGroup: string;
  totalCount: number;
  totalProcessed: number;
}

interface Integration {
  integrationId?: number;
  origin: string;
  destination: string;
  url: string;
  type: number;
  status: number;
  statusName?: string;
  dataSchema: DataSchema;
}

enum EnumStatusAnalyser {
  Pending = 0,
  Started = 1,
  Finished = 2,
}

interface State {
  surveyName: string;
  idSurvey: number;
  projectId: number;
  userGroup: string;
  projectName: string;
}

const RunAnalyser: React.FC = () => {
  const { state } = useLocation<State>();
  const { addToast } = useToast();

  const [integrationsList, setIntegrationsList] = useState<Integration[]>([]);

  // get integrations with status pending 0
  const getIntegrations = useCallback(() => {
    api.get(`integrations/type/3`).then(response => {
      const pendingIntegrations = response.data
        .sort((a: any, b: any) => {
          return b.integrationId - a.integrationId;
        })
        .map((item: Integration) => {
          return {
            ...item,

            statusName:
              Object.keys(EnumStatusAnalyser)[
                Object.values(EnumStatusAnalyser).indexOf(item.status)
              ],
          };
        });

      setIntegrationsList([pendingIntegrations[0]]);
    });
  }, []);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  useEffect(() => {
    const id = setInterval(getIntegrations, 5000);

    return () => clearInterval(id);
  }, [getIntegrations]);

  const handleRunAnalyser = useCallback(() => {
    // const totalCount = 0;
    api
      .get(`results/survey/countTextQuestions/${state?.idSurvey}`)
      .then(response => {
        const totalCount = response.data;

        let userGroupLocal = state?.userGroup;

        if (state?.projectName.includes('TSÖ')) {
          userGroupLocal = 'tso';
        } else if (
          userGroupLocal === 'Employees' ||
          userGroupLocal === 'Management'
        ) {
          userGroupLocal = 'managers';
        } else {
          userGroupLocal = 'customers';
        }

        const filterToExport: Integration = {
          url: `/results/survey/export/runAnalyser`,
          origin: 'user',
          destination: 'runAnalyser',
          status: 0, // pending
          type: EnumIntegrationType.RunAnalyser,
          dataSchema: {
            surveyId: state?.idSurvey,
            projectId: state?.projectId,
            userGroup: userGroupLocal,
            totalCount,
            totalProcessed: 0,
          },
        };

        api.post('/integrations', filterToExport).then(response => {
          addToast({
            type: 'success',
            title: 'Success',
            description: 'Please wait until the analyser finish!',
          });
          setIntegrationsList(oldValue => [...oldValue, response.data]);
        });
      });
  }, [
    addToast,
    state?.idSurvey,
    state?.projectId,
    state?.projectName,
    state?.userGroup,
  ]);

  return (
    <Container>
      <BackButton />

      <h3>Run Answer-Analyser:</h3>
      <h2>Survey Id: {state?.idSurvey}</h2>
      <h2>Survey Name: {state?.surveyName}</h2>

      {integrationsList && integrationsList[0] ? (
        <>
          {integrationsList.map((integration: Integration, index: number) => (
            <BoxRow key={index}>
              <BoxColTitle>IntegrationId:</BoxColTitle>
              <BoxCol>
                <span>{integration?.integrationId ?? ''}</span>
              </BoxCol>
              <BoxColTitle>SurveyId:</BoxColTitle>
              <BoxCol>
                <span>{integration?.dataSchema.surveyId}</span>
              </BoxCol>

              <BoxColTitle>Status:</BoxColTitle>
              <BoxCol>
                <span>{integration?.statusName ?? ''}</span>
              </BoxCol>
              <BoxColTitle>Type:</BoxColTitle>
              <BoxCol>
                <span>{EnumIntegrationType[integration?.type]}</span>
              </BoxCol>
            </BoxRow>
          ))}
        </>
      ) : (
        <></>
      )}

      <>
        <Button
          onClick={handleRunAnalyser}
          marginRight="35px"
          width="280px"
          height="40px"
          disabled={!!integrationsList?.find(x => x?.status !== 2)}
        >
          Run Analyser
        </Button>
      </>
    </Container>
  );
};

export default RunAnalyser;
