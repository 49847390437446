import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import ParticipationLinkForm from '../Form';

interface ParticipationLink {
  name: string;
  customerId: number;
  projectId: number;
  surveyId: number;
  segmentId: number;
  accesses: string[];
  accessIds?: number[];
}

const CreatePartcipationLink: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [selectedAccess, setSelectedAccess] = useState([0]);

  const handleSubmit = useCallback(
    async (data: ParticipationLink) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required'),
          customerId: Yup.number().required('Customer is required'),
          projectId: Yup.number().required('Project required'),
          surveyId: Yup.number().required('Survey is required'),
          // accesses: Yup.array().min(1, 'Access is required'),
          segmentId: Yup.number().required('Segment is required'),
        });

        await schema
          .validate(data, {
            abortEarly: false,
          })
          .then(async () => {
            const body = {
              name: data.name,
              customerId: data.customerId,
              projectId: data.projectId,
              surveyId: data.surveyId,
              segmentId: data.segmentId,
              accessIds: selectedAccess.length > 0 ? selectedAccess : [0],
            };

            await api.post('/participationLinks', body);

            const redirectUrl = '/participationLink';

            history.push(redirectUrl);

            addToast({
              type: 'success',
              title: 'Success',
              description: 'The link was created successfully!',
            });
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Access is required',
              description: 'Please select at least one access before save it.',
            });
          });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating the text, please try again.',
        });
      }
    },
    [addToast, history, selectedAccess],
  );

  return (
    <>
      <h1>New customer link</h1>

      <ParticipationLinkForm
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
        setSelectedAccess={setSelectedAccess}
      />
    </>
  );
};

export default CreatePartcipationLink;
