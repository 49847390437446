import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiCopy } from 'react-icons/fi';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns } from './columns';

import { Container, Content, Button, EditIcon, DeleteIcon } from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface ParticipationLink {
  customerId: number;
  projectId: number;
  surveyId: number;
  accessId: number;
  segmentId: number;
  participationLinkId: number;
  code: string;
}

const ParticipationLink: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [parcipationLinks, setParticipationLinks] = useState<
    ParticipationLink[]
  >([]);

  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'participationLinkId', width: 120 },
  ]);

  const handleClickUrlCopy = useCallback((row: any) => {
    let host = `${window.location.hostname}`;

    if (host === 'localhost') {
      host = `http://${host}:3000`;
    } else {
      host = `https://${host}`;
    }
    const server = `${host}/participation`;

    navigator.clipboard.writeText(
      `${server}/${row.participationLinkId}/${row.code}`,
    );
  }, []);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/participationLink/edit/${row.participationLinkId}`);
    },
    [history],
  );

  const handleDelete = useCallback(
    (row: any) => {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const userWantDelete = confirm(
        `Are you sure you want to delete the link ${row.participationLinkId} - ${row.name}?`,
      );

      if (userWantDelete) {
        api
          .delete(`/participationLinks/${row.participationLinkId}`)
          .then(response => {
            if (response.data) {
              addToast({
                type: 'success',
                title: 'Success',
                description: 'The link was deleted successfully!',
              });

              history.push('/projects');
              history.push('/participationLink');
            } else {
              addToast({
                type: 'error',
                title: 'Deleting Error',
                description: `An error occurred while deleting this link, please try again.`,
              });
            }
          });
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'copyUrl',
      label: 'Copy Link',
      onClick: handleClickUrlCopy,
      icon: <FiCopy />,
      width: 120,
    },

    {
      columnName: 'edit',
      label: 'Edit',
      onClick: handleClickEdit,
      icon: <EditIcon />,
      width: 120,
    },

    {
      columnName: 'delete',
      label: 'Delete',
      onClick: handleDelete,
      icon: <DeleteIcon />,
      width: 120,
    },
  ]);

  useEffect(() => {
    setLoading(true);

    api.get('participationLinks').then(response => {
      setParticipationLinks(
        response.data.map((participation: any) => {
          return {
            ...participation,
            code: participation.code,
          };
        }),
      );
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1> Customer Links </h1>

      <Link
        to={{
          pathname: `/participationLink/new`,
        }}
      >
        <Button variant="contained" type="button">
          New Customer Link
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={parcipationLinks}
          selectionProp={selectedRowIds?.map(String)}
          multiSelection={false}
          setSelectedRowId={setSelectedRowIds}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'participationLinkId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default ParticipationLink;
