import React from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import Select from '../../../components/SelectForm';

import Input from '../../../components/FormInput';
import Button from '../../../components/Button';

import { Container } from './styles';

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: Category;
  handleSubmit: SubmitHandler;
}

interface Category {
  categoryId: number;
  title: string;
  type: string;
}
interface SelectOptions {
  value: string;
  label: string;
}

const types: SelectOptions[] = [
  { value: 'A-pillar', label: 'A-pillar' },
  { value: 'Topic', label: 'Topic' },
  { value: 'Subtopic', label: 'Subtopic' },
];

const FormCategory: React.FC<Props> = props => {
  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Title</h2>
        <Input type="text" name="title" placeholder="Enter the title" />

        <h2>Type</h2>
        <Select name="type" options={types} defaultValue={types[0]}></Select>

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormCategory;
