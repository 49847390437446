import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import {
  Container,
  SelectMultiple,
  VButton,
  VisualizationsButtons,
  CheckShowAnswers,
} from './styles';

import api from '../../../services/api';
import { InputNumber } from '../../Questions/Form/styles';
import Input from '../../../components/FormInput';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

interface QuestionOption {
  label: string;
  value: string | number;
}

// interface Filter {
//   customerSector: any;
//   customerRegion: any;
//   customerNumber_employees: any;
//   customerRevenues: any;
//   customerName: any;
//   projectName: any;
//   projectType: any;
//   surveyYear: any;
//   surveyName: any;
//   // usergroup: any;
//   questionText: any;
//   questionId: any;
// }

const AnalyticsPage: React.FC = () => {
  const [urlVisualization, setUrlVisualization] = useState<string>();
  const formRef = useRef<FormHandles>(null);
  const switchShowAnswersRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToast();
  const [values, setValues] = useState<any>([]);
  const [showAnswers, setShowAnswers] = useState<boolean>(false);
  const [selectedCustomerSectorOptions, setSelectedCustomerSectorOptions] =
    useState<string[]>();

  const [customerSectorOptions, setCustomerSectorOptions] =
    useState<string[]>();

  const [selectedCustomerRegionOptions, setSelectedCustomerRegionOptions] =
    useState<string[]>();

  const [customerRegionOptions, setCustomerRegionOptions] = useState<string[]>(
    [],
  );

  const [
    selectedCustomerNemployeesOptions,
    setSelectedCustomerNemployeesOptions,
  ] = useState<string[]>([]);
  const [customerNemployeesOptions, setCustomerNemployeesOptions] = useState<
    string[]
  >([]);
  const [selectedCustomerRevenueOptions, setSelectedCustomerRevenueOptions] =
    useState<string[]>([]);
  const [customerRevenueOptions, setCustomerRevenueOptions] = useState<
    string[]
  >([]);
  const [selectedCustomerNameOptions, setSelectedCustomerNameOptions] =
    useState<string[]>([]);
  const [customerNameOptions, setCustomerNameOptions] = useState<string[]>([]);
  const [selectedProjectNameOptions, setSelectedProjectNameOptions] = useState<
    string[]
  >([]);
  const [projectNameOptions, setProjectNameOptions] = useState<string[]>([]);
  const [selectedProjectTypeOptions, setSelectedProjectTypeOptions] = useState<
    string[]
  >([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState<string[]>([]);
  const [selectedSurveyYearOptions, setSelectedSurveyYearOptions] = useState<
    string[]
  >([]);
  const [surveyYearOptions, setSurveyYearOptions] = useState<string[]>([]);
  const [selectedSurveyNameOptions, setSelectedSurveyNameOptions] = useState<
    string[]
  >([]);
  const [selectedQuestionsOptions, setSelectedQuestionsOptions] = useState<
    string[]
  >([]);

  const [selectedSegmentsOptions, setSelectedSegmentsOptions] = useState<
    string[]
  >([]);

  const [surveyNameOptions, setSurveyNameOptions] = useState<string[]>([]);
  const [questionsOptions, setQuestionsOptions] = useState<QuestionOption[]>(
    [],
  );
  const [selectedSurveyUserGroupOptions, setSelectedSurveyUserGroupOptions] =
    useState<string[]>([]);

  const [selectedAccessNameOptions, setSelectedAccessNameOptions] = useState<
    string[]
  >([]);
  const [surveyUserGroupOptions, setSurveyUserGroupOptions] = useState<
    string[]
  >([]);

  const [accessNameOptions, setAccessNameOptions] = useState<string[]>([]);

  const [segmentOptions, setSegmentOptions] = useState<string[]>([]);
  const [visualizationChoice, setVisualizationChoice] = useState<string>('');
  const [filters, setFilters] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);

  const [submitToSave, setSubmitToSave] = useState<boolean>(true);

  const urlBase = '/cube/openAnswers';

  const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const host = `${window.location.hostname}`;
    if (host === 'localhost') {
      setUrlVisualization(`http://localhost:3001`);
    } else {
      setUrlVisualization(process.env.REACT_APP_VISUALIZATION_URL);
    }
  }, []);

  useEffect(() => {
    setFilters({
      customerSector: selectedCustomerSectorOptions,
      customerRegion: selectedCustomerRegionOptions,
      customerNumberEmployees: selectedCustomerNemployeesOptions,
      customerRevenues: selectedCustomerRevenueOptions,
      customerName: selectedCustomerNameOptions,
      projectName: selectedProjectNameOptions,
      projectType: selectedProjectTypeOptions,
      surveyYear: selectedSurveyYearOptions,
      surveyName: selectedSurveyNameOptions,
      accessName: selectedAccessNameOptions,
      usergroup: selectedSurveyUserGroupOptions,
      segment: selectedSegmentsOptions,
    });
  }, [
    selectedAccessNameOptions,
    selectedCustomerNameOptions,
    selectedCustomerNemployeesOptions,
    selectedCustomerRegionOptions,
    selectedCustomerRevenueOptions,
    selectedCustomerSectorOptions,
    selectedProjectNameOptions,
    selectedProjectTypeOptions,
    selectedSegmentsOptions,
    selectedSurveyNameOptions,
    selectedSurveyUserGroupOptions,
    selectedSurveyYearOptions,
  ]);

  // ALL FILTERS
  useEffect(() => {
    let mounted = true;
    let response: any = {};
    const getFilteredOptions = async (): Promise<any> => {
      if (mounted) {
        setLoading(true);
        response = await api.post(`${urlBase}/filter`, filters);

        setCustomerSectorOptions(response.data.customerSectorOptions);
        setCustomerRegionOptions(response.data.customerRegionOptions);
        setCustomerNemployeesOptions(response.data.customerNemployeesOptions);
        setCustomerRevenueOptions(response.data.CustomerRevenueOptions);
        setCustomerNameOptions(response.data.CustomerNameOptions);
        setProjectNameOptions(response.data.ProjectNameOptions);
        setProjectTypeOptions(response.data.ProjectTypeOptions);
        setSurveyYearOptions(response.data.SurveyYearOptions);
        setSurveyNameOptions(response.data.SurveyNameOptions);
        setQuestionsOptions(response.data.QuestionsOptions);
        setSurveyUserGroupOptions(response.data.SurveyUserGroupOptions);
        setAccessNameOptions(response.data.AccessNameOptions);
        setSegmentOptions(response.data.SegmentsOptions);

        setLoading(false);
      }
    };
    getFilteredOptions();
    return () => {
      mounted = false;
    };
  }, [filters]);

  const handleVisualizationButton = useCallback((button: any) => {
    setVisualizationChoice(button);
    setSubmitToSave(false);
  }, []);

  const handleSaveButton = useCallback(() => {
    setSubmitToSave(true);
  }, []);

  const handleSubmit = useCallback(
    async (valuesFromForm: any) => {
      formRef.current?.setErrors({});

      if (!submitToSave) {
        const valuesForm = {
          ...valuesFromForm,
          nTopic: valuesFromForm.nTopic !== '' ? [valuesFromForm.nTopic] : [],
          showAnswers: [showAnswers],
          title: [valuesFromForm.title],
        };

        let reducestring = Object.keys(valuesForm).reduce(
          (accumulator, currentValue) => {
            const value: string[] = valuesForm[currentValue];

            if (Array.isArray(value) && value.length > 0) {
              let value3 = '';
              value?.forEach(x => {
                value3 = `${value3}${currentValue}=${x}&`;
              });

              return `${accumulator}${value3}`;
            }

            return `${accumulator}`;
          },
          '?',
        );

        if (reducestring[reducestring.length - 1] === '&') {
          reducestring = reducestring.slice(0, reducestring.length - 1);
        }

        const uri = encodeURI(
          `${urlVisualization}/${visualizationChoice}/internal${reducestring}`,
        );

        window.open(uri, '_blank');
      } else {
        try {
          const schema = Yup.object().shape({
            name: Yup.string().required('Link name is required'),
          });

          await schema.validate(valuesFromForm, {
            abortEarly: false,
          });

          const newValues = { ...valuesFromForm, showAnswers };

          const newLink = {
            name: newValues.name,
            type: 'analyticsLink',
            linkData: newValues,
            code: 'code ',
          };

          const response = await api.post('/externalLinks', newLink);

          addToast({
            type: 'success',
            title: 'Success',
            description: 'The link was created successfully!',
          });

          history.push('/analytics', {
            customerId: response.data.customerId,
            name: response.data.name,
          });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);

            formRef.current?.setErrors(errors);

            return;
          }

          addToast({
            type: 'error',
            title: 'Registration Error',
            description:
              'An error occurred while creating link, please try again.',
          });
        }
      }
    },
    [
      addToast,
      history,
      showAnswers,
      submitToSave,
      urlVisualization,
      visualizationChoice,
    ],
  );

  const handleCustomerSector = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];

      setSelectedCustomerSectorOptions(arr);
    } else {
      setSelectedCustomerSectorOptions([]);
    }
  }, []);

  const handleCustomerRegion = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedCustomerRegionOptions(arr);
    } else {
      setSelectedCustomerRegionOptions([]);
    }
  }, []);

  const handleCustomerNemployees = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedCustomerNemployeesOptions(arr);
    } else {
      setSelectedCustomerNemployeesOptions([]);
    }
  }, []);

  const handleCustomerRevenue = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedCustomerRevenueOptions(arr);
    } else {
      setSelectedCustomerRevenueOptions([]);
    }
  }, []);

  const handleCustomerName = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedCustomerNameOptions(arr);
    } else {
      setSelectedCustomerNameOptions([]);
    }
  }, []);

  const handleProjectName = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedProjectNameOptions(arr);
    } else {
      setSelectedProjectNameOptions([]);
    }
  }, []);

  const handleProjectType = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedProjectTypeOptions(arr);
    } else {
      setSelectedProjectTypeOptions([]);
    }
  }, []);

  const handleSurveyYear = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedSurveyYearOptions(arr);
    } else {
      setSelectedSurveyYearOptions([]);
    }
  }, []);

  const handleSurveyName = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedSurveyNameOptions(arr);
    } else {
      setSelectedSurveyNameOptions([]);
    }
  }, []);

  const handleQuestions = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedQuestionsOptions(arr);
    } else {
      setSelectedQuestionsOptions([]);
    }
  }, []);

  const handleSurveyUserGroup = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedSurveyUserGroupOptions(arr);
    } else {
      setSelectedSurveyUserGroupOptions([]);
    }
  }, []);

  const handleAccessName = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedAccessNameOptions(arr);
    } else {
      setSelectedAccessNameOptions([]);
    }
  }, []);

  const handleSegments = useCallback((selectedValue: any) => {
    if (selectedValue && selectedValue.length > 0) {
      const arr = [...selectedValue.map((sv: any) => sv.value)];
      setSelectedSegmentsOptions(arr);
    } else {
      setSelectedSegmentsOptions([]);
    }
  }, []);

  const handleSwichShowAnswers = useCallback(value => {
    setShowAnswers(value.target.checked);
  }, []);

  const numberInputOnWheelPreventChange = (e: any): any => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <p>Customer Sector</p>
        <SelectMultiple
          name="customerSector"
          setSelectedTags={handleCustomerSector}
          tags={values}
          tagsSearch={customerSectorOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Customer Region</p>
        <SelectMultiple
          name="customerRegion"
          setSelectedTags={handleCustomerRegion}
          tags={values}
          tagsSearch={customerRegionOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Customer - number of employees</p>
        <SelectMultiple
          name="customerNemployees"
          setSelectedTags={handleCustomerNemployees}
          tags={values}
          tagsSearch={customerNemployeesOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Customer Revenues</p>
        <SelectMultiple
          name="customerRevenues"
          setSelectedTags={handleCustomerRevenue}
          tags={values}
          tagsSearch={customerRevenueOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Customer Name</p>
        <SelectMultiple
          name="customerName"
          setSelectedTags={handleCustomerName}
          tags={values}
          tagsSearch={customerNameOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Project Name</p>
        <SelectMultiple
          name="projectName"
          setSelectedTags={handleProjectName}
          tags={values}
          tagsSearch={projectNameOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Project Type</p>
        <SelectMultiple
          name="projectType"
          setSelectedTags={handleProjectType}
          tags={values}
          tagsSearch={projectTypeOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Year</p>
        <SelectMultiple
          name="surveyYear"
          setSelectedTags={handleSurveyYear}
          tags={values}
          tagsSearch={surveyYearOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Survey Name</p>
        <SelectMultiple
          name="surveyName"
          setSelectedTags={handleSurveyName}
          tags={values}
          tagsSearch={surveyNameOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Questions</p>
        <SelectMultiple
          name="questions"
          setSelectedTags={handleQuestions}
          tags={values}
          tagsSearch={questionsOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>AccessName</p>
        <SelectMultiple
          name="accessName"
          setSelectedTags={handleAccessName}
          tags={values}
          tagsSearch={accessNameOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Usergroup</p>
        <SelectMultiple
          name="usergroup"
          setSelectedTags={handleSurveyUserGroup}
          tags={values}
          tagsSearch={surveyUserGroupOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>Segment</p>
        <SelectMultiple
          name="segment"
          setSelectedTags={handleSegments}
          tags={values}
          tagsSearch={segmentOptions}
          acceptNewValues={false}
        ></SelectMultiple>
        <br />

        <p>n-topics</p>
        <InputNumber
          type="text"
          onWheel={numberInputOnWheelPreventChange}
          name="nTopic"
          placeholder="Enter how many categories you want to see in the chart at the same time"
        />
        <br />

        <p>Link Name</p>
        <Input type="text" name="name" placeholder="Enter link name" />
        <br />

        <p>Title</p>
        <Input type="text" name="title" placeholder="Enter title here" />

        <CheckShowAnswers>
          <Switch
            ref={switchShowAnswersRef}
            checked={showAnswers}
            onChange={value => handleSwichShowAnswers(value)}
            color="primary"
          />
          {'Show answers?'}
        </CheckShowAnswers>
        <VisualizationsButtons>
          <VButton
            onClick={() => handleVisualizationButton('bubblechart')}
            type="submit"
            width="200px"
          >
            BubbleChart
          </VButton>

          <VButton
            onClick={() => handleSaveButton()}
            type="submit"
            width="200px"
          >
            Save link
          </VButton>
        </VisualizationsButtons>
      </Form>
    </Container>
  );
};

export default AnalyticsPage;
