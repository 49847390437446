import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormProject from '../Form';

interface Project {
  name: string;
  customerId: number;
  customerName: string;
  urlFavicon: string;
  browserTitle: string;
  buttonsColor: string;
  overlayBoxColor: string;
  urlLogo: string;
  urlRadarChart: string;
  logoId?: number;
  headerColor: string;
  heightLogo: string;
  headerImagePath: string;
  headerImageId?: number;

  backgroundImagePath: string;
  backgroundImageId?: number;
  hideProgressText: boolean;
  showConfettiRain: boolean;
}

interface ParamTypes {
  projectId: string;
}

const CreateProject: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { projectId } = useParams<ParamTypes>();
  const [project, setProject] = useState<Project>();
  const history = useHistory();

  useEffect(() => {
    api.get(`/projects/${projectId}`).then(response => {
      const proj = response.data;

      if (response.data.style) {
        proj.urlFavicon = response.data.style.urlFavicon;
        proj.browserTitle = response.data.style.browserTitle;
        proj.buttonsColor = response.data.style.buttonsColor;
        proj.overlayBoxColor = response.data.style.overlayBoxColor;
        proj.urlLogo = response.data.style.urlLogo;
        proj.urlRadarChart = response.data.style.urlRadarChart;
        proj.headerColor = response.data.style.headerColor;
        proj.heightLogo = response.data.style.heightLogo;
        proj.hideProgressText = response.data.style.hideProgressText ?? false;
        proj.showConfettiRain = response.data.style.showConfettiRain ?? false;

        if (response.data.style.header) {
          proj.headerImagePath = response.data.style?.header.filePath;
          proj.headerImageId = response.data.style?.header.id;
        }

        if (response.data.style.background) {
          proj.backgroundImagePath = response.data.style?.background.filePath;
          proj.backgroundImageId = response.data.style?.background.id;
        }
      }

      setProject(proj);

      formRef.current?.setFieldValue('customerId', {
        value: response.data.customerId,
        label: response.data.customerName,
      });

      formRef.current?.setFieldValue('type', {
        value: response.data?.type?.value,
        label: response.data?.type?.label,
      });

      if (response.data && response.data.style) {
        formRef.current?.setFieldValue(
          'browserTitle',
          response.data?.style.browserTitle,
        );

        formRef.current?.setFieldValue(
          'buttonsColor',
          response.data?.buttonsColor,
        );

        formRef.current?.setFieldValue(
          'overlayBoxColor',
          response.data?.overlayBoxColor,
        );

        formRef.current?.setFieldValue(
          'hideProgressText',
          response.data?.hideProgressText ?? false,
        );

        formRef.current?.setFieldValue(
          'showConfettiRain',
          response.data?.showConfettiRain ?? false,
        );

        formRef.current?.setFieldValue('urlFavicon', response.data?.urlFavicon);

        formRef.current?.setFieldValue(
          'urlLogo',
          response.data?.style?.logo?.filePath,
        );

        formRef.current?.setFieldValue(
          'headerImagePath',
          response.data?.style?.header?.filePath,
        );

        formRef.current?.setFieldValue(
          'backgroundImagePath',
          response.data?.style?.background?.filePath,
        );

        formRef.current?.setFieldValue(
          'headerColor',
          response.data?.style?.headerColor,
        );

        formRef.current?.setFieldValue(
          'heightLogo',
          response.data?.style?.heightLogo,
        );

        const logoFilePath: string = response.data?.style?.logo?.filePath;
        const logoFileLabel: string = logoFilePath?.substring(
          logoFilePath.lastIndexOf('/') + 1,
        );

        formRef.current?.setFieldValue('logoId', {
          value: response.data?.style?.logo?.id,
          filePath: logoFilePath,
          label: logoFileLabel,
        });

        const headerFilePath: string = response.data?.style?.header?.filePath;
        const headerFieldLabel: string = headerFilePath?.substring(
          headerFilePath.lastIndexOf('/') + 1,
        );

        const backgroundFilePath: string =
          response.data?.style?.background?.filePath;
        const backgroundFieldLabel: string = backgroundFilePath?.substring(
          headerFilePath.lastIndexOf('/') + 1,
        );

        formRef.current?.setFieldValue('headerImageId', {
          value: response.data?.style?.header?.id,
          filePath: headerFilePath,
          label: headerFieldLabel,
        });

        formRef.current?.setFieldValue('backgroundImageId', {
          value: response.data?.style?.background?.id,
          filePath: backgroundFilePath,
          label: backgroundFieldLabel,
        });

        formRef.current?.setFieldValue(
          'urlRadarChart',
          response.data?.urlRadarChart,
        );
      }
    });
  }, [projectId]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const type =
          formRef.current?.getFieldRef('type').select?.state?.selectValue[0];

        const projectData = {
          name: data.name,
          startYear: data.startYear,
          type,
          logoId: data.logoId,
          urlLogo: data.urlLogo,
          urlFavicon: data?.urlFavicon,
          browserTitle: data?.browserTitle,
          buttonsColor: data?.buttonsColor,
          overlayBoxColor: data?.overlayBoxColor,
          urlRadarChart: data?.urlRadarChart,
          hideProgressText: data?.hideProgressText === 'true',
          showConfettiRain: data?.showConfettiRain === 'true',
          headerColor: data?.headerColor,
          heightLogo: data?.heightLogo,
          headerImageId: data?.headerImageId,
          headerImagePath: data?.headerImagePath,
          backgroundImageId: data?.backgroundImageId,
          backgroundImagePath: data?.backgroundImagePath,
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('Project name is required'),
        });

        await schema.validate(projectData, {
          abortEarly: false,
        });

        await api.put(`/projects/${projectId}`, projectData);

        history.push('/projects');

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The project was saved successfully!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        console.log(err);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing project, please try again.',
        });
      }
    },
    [addToast, history, projectId],
  );

  return (
    <>
      <h1>Edit Project</h1>

      {/*
        When a project is edited all surveys will be refreshed their styles
     */}

      <FormProject
        formRef={formRef}
        handleSubmit={handleSubmit}
        initialData={project}
        buttonText="Save"
      />
    </>
  );
};

export default CreateProject;
