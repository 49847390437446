import styled, { keyframes } from 'styled-components';

interface BoxProps {
  color: string;
  position: 'left' | 'right' | 'center';
}

interface ContentProps {
  out: boolean;
}

export const Container = styled.div`
  margin: 50px;
`;

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

/* export const ContentFadeIn = styled.div<ContentProps>`
  display: flex;
  margin: 25px 0;
  animation: ${fadeIn} 0.5s ease-in-out;
`; */

const fadeInLeft = keyframes`
  from {
    transform: translateX(-50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeInRight = keyframes`
from {
    transform: translateX(50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeInCenter = keyframes`
from {
    transform: translateY(-50px);
    opacity: 0;
  }

  to {
    transform: translatey(0);
    opacity: 1;
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  margin: 25px 0;
`;

export const Header = styled.div`
  display: flex;
  margin: 25px 0;
  justify-content: center;
`;

export const BoxCard = styled.div<BoxProps>`
  border-radius: 24px;
  margin: 10px 34px;
  padding: 20px 36px;
  flex: 1;
  text-align: center;
  background-color: ${props => props.color};

  animation: ${props =>
      props.position === 'left'
        ? fadeInLeft
        : props.position === 'right'
        ? fadeInRight
        : fadeInCenter}
    0.5s linear;
  transition: visibility 1s linear;

  h3 {
    font-size: 42px;
    font-weight: bold;
    color: #000;
    margin: 18px 0;
    text-align: center;
  }

  span {
    color: #737476;
  }
`;
