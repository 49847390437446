import React, { useState, useCallback, useEffect } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { RiTranslate } from 'react-icons/ri';
import api from '../../../services/api';

import Input from '../../../components/FormInput';

import Select from '../../../components/SelectForm';
import MultiInput from '../../../components/MultiInput';
import Button from '../../../components/Button';

import EnumQuestionType from '../../../utils/enums/EnumQuestionType';

import {
  Container,
  DivEditLanguageButton,
  EditLanguageButton,
  H2Relative,
  LanguageDiv,
  OriginalField,
  TranslationArrow,
  TranslationArrowDiv,
  TranslationDiv,
  InputNumber,
  DivAddNewTag,
  AddNewTagButton,
} from './styles';
import TagsSelect from '../../../components/TagsSelect';
import TagsInput from '../../../components/TagsInput';
import { EnumCombinationValues } from '../../../utils/enums/EnumCombinationTypes';

interface Question {
  text: string;
  type: string;
  alternatives: Alternative[];
  options: Option[];
  combinationType: string;
  tags: string[];
  defaultLanguage?: string;
  translations?: Translation[];
  externalIdBefragung?: number;
  externalIdUmfrage?: number;
  externalIdTSO?: number;
}

interface Translation {
  alternatives: Alternative[];
  options: Option[];
  languageId: number;
  text: string;
}
interface Type {
  value: string;
  label: string;
}

interface CombinationType {
  value: string;
  label: string;
}

interface Alternative {
  value: number;
  text: string;
  textTranslated?: string;
}

interface Option {
  value: number;
  text: string;
  textTranslated?: string;
  combinationValue?: string;
}

interface Combination {
  value: number;
  label: string;
}

interface DefaultLanguage {
  value: string;
  label: string;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: Question;
  handleSubmit: SubmitHandler;
  questionId?: string;
  isEdit: boolean;
  disableType: boolean;
  setTags: any;
  tags: any[];
  tagsSearch: string[];
}

const defaultOptions: Option[] = [
  { value: 1, text: 'trifft vollständig zu' },
  { value: 2, text: 'trifft weitgehend zu' },
  { value: 3, text: 'trifft bedingt zu' },
  { value: 4, text: 'trifft kaum zu' },
  { value: 5, text: 'trifft gar nicht zu' },
];

const allCombinationTypes: Combination[] = [
  { value: 1, label: EnumCombinationValues.Top_Middle_Flop },
  { value: 2, label: EnumCombinationValues.Promoter_Passive_Detractor },
  { value: 3, label: EnumCombinationValues.Others },
  { value: 4, label: EnumCombinationValues.No_combinations },
];

const FormQuestion: React.FC<Props> = props => {
  const [hasCombinationType, setHasCombinationType] = useState(true);
  const [defaultCombinationType, setDefaultCombinationType] =
    useState<CombinationType>({
      value: '4',
      label: EnumCombinationValues.No_combinations,
    } as CombinationType);
  const [combinationTypes, setCombinationTypes] =
    useState<Combination[]>(allCombinationTypes);
  const [type, setType] = useState<Type>({
    value: '',
    label: 'Select...',
  } as Type);
  const [selectedTags, setSelectedTags] = useState(props?.tags ?? []);
  const [newTags, setNewTags] = useState<any[]>([]);

  const [questionDefaultLanguages, setQuestionDefaultLanguages] = useState(
    [] as DefaultLanguage[],
  );
  const [questionTypes, setQuestionTypes] = useState([]);
  const [editLanguageBool, setEditLanguageBool] = useState(false);
  const [alternativesFilled, setAlternativesFilled] = useState<Alternative[]>(
    [],
  );

  const [optionsFilled, setOptionsFilled] = useState<Option[]>(defaultOptions);
  const [defaultOptionsFilled, setDefaultOptionsFilled] =
    useState<Option[]>(defaultOptions);

  const [textTitleQuestionTranslated, setTextTitleQuestionTranslated] =
    useState('');

  const [defaultLanguage, setDefaultLanguage] = useState({
    value: '1',
    label: 'German',
  } as DefaultLanguage);

  const [translateSelectLanguage, setTranslateSelectLanguage] = useState({
    value: '',
    label: 'Select...',
  } as DefaultLanguage);

  // const [newTags, setNewTags] = useState<any[]>([]);
  const [isNewTagsFieldVisible, setIsNewTagsFieldVisible] =
    useState<boolean>(false);

  useEffect(() => {
    api.get('questionTypes').then(response => {
      setQuestionTypes(response.data);
    });

    api.get('languages').then(response => {
      const languageList: DefaultLanguage[] = [];

      if (response.data) {
        response.data.forEach((language: any) => {
          languageList.push({
            value: language.languageId,
            label: language.name,
          });
        });
      }
      setQuestionDefaultLanguages(languageList);

      if (props.initialData?.alternatives) {
        setAlternativesFilled(props.initialData?.alternatives);
      }

      if (props.initialData?.options) {
        setOptionsFilled(props.initialData?.options);
        setDefaultOptionsFilled(props.initialData?.options);
      }
    });

    if (props.isEdit) {
      const typeInit: Type = {
        value: props.initialData?.type ?? '',
        label:
          EnumQuestionType[
            props.initialData?.type as keyof typeof EnumQuestionType
          ],
      };

      setType(typeInit);
    }
  }, [
    props.initialData,
    props.initialData?.type,
    props.isEdit,
    props.questionId,
  ]);

  const handleTypeChange = useCallback(selectedType => {
    setType(selectedType);
    setOptionsFilled(defaultOptions);
  }, []);

  const handleCombinationTypeChange = useCallback(
    selectedType => {
      setDefaultCombinationType(selectedType);
      let setNewValues = true;
      const previousCombinationType = props.initialData?.combinationType
        ? props.initialData.combinationType
        : '';

      if (selectedType.label === EnumCombinationValues.No_combinations) {
        setHasCombinationType(false);
        setOptionsFilled(defaultOptionsFilled);
      } else {
        let combinationsAux: any = [];
        if (selectedType.label === EnumCombinationValues.Top_Middle_Flop) {
          if (previousCombinationType === 'Top_Middle_Flop') {
            setNewValues = false;
            setOptionsFilled(defaultOptionsFilled);
          } else {
            combinationsAux = [
              { value: 1, text: 'Top2' },
              { value: 2, text: 'Top2' },
              { value: 3, text: 'Middle' },
              { value: 4, text: 'Flop2' },
              { value: 5, text: 'Flop2' },
            ];
          }
        }
        if (
          selectedType.label ===
          EnumCombinationValues.Promoter_Passive_Detractor
        ) {
          if (previousCombinationType === 'Promoter_Passive_Detractor') {
            setNewValues = false;
            setOptionsFilled(defaultOptionsFilled);
          } else {
            combinationsAux = [
              { value: 1, text: 'Promoter' },
              { value: 2, text: 'Promoter' },
              { value: 3, text: 'Passive' },
              { value: 4, text: 'Passive' },
              { value: 5, text: 'Detractor' },
              { value: 6, text: 'Detractor' },
              { value: 7, text: 'Detractor' },
              { value: 8, text: 'Detractor' },
              { value: 9, text: 'Detractor' },
              { value: 10, text: 'Detractor' },
              { value: 11, text: 'Detractor' },
            ];
          }
        }
        if (selectedType.label === EnumCombinationValues.Others) {
          if (previousCombinationType === 'Others') {
            setNewValues = false;
            setOptionsFilled(defaultOptionsFilled);
          } else {
            defaultOptionsFilled.forEach((d: any) => {
              combinationsAux.push({
                value: d.value,
                text: 'new combination...',
              });
            });
          }
        }

        if (setNewValues) {
          let filledOptionsAux: Option[] = [];
          if (combinationsAux.length >= optionsFilled.length) {
            let countA = optionsFilled.length;
            filledOptionsAux = combinationsAux.map((combination: any) => {
              const getOption = optionsFilled.find(
                (option: Option) => option.value === combination.value,
              );
              if (getOption) {
                return {
                  ...getOption,
                  combinationValue: combination.text,
                };
              }
              countA += 1;
              return {
                value: countA,
                text: 'new Option...',
                combinationValue: combination.text,
              };
            });
          } else {
            let countB = combinationsAux.length;
            filledOptionsAux = optionsFilled.map((option: any) => {
              const getCombination = combinationsAux.find(
                (combination: Option) => option.value === combination.value,
              );
              if (getCombination) {
                return {
                  ...option,
                  combinationValue: getCombination.text,
                };
              }
              countB += 1;
              return {
                value: countB,
                text: option.text,
                combinationValue: 'new Combination...',
              };
            });
          }

          setOptionsFilled(filledOptionsAux);
        }

        setHasCombinationType(true);
      }
    },
    [defaultOptionsFilled, optionsFilled, props.initialData?.combinationType],
  );

  const handleEditLanguage = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const userWantChangeLanguage = confirm(
      `Are you sure you want to change the default language?`,
    );
    if (userWantChangeLanguage) {
      setEditLanguageBool(true);
    }
  }, []);

  const handleLanguageDefaultChange = useCallback(language => {
    setDefaultLanguage(language);
  }, []);

  const numberInputOnWheelPreventChange = (e: any): any => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleTranslateSelectLanguageChange = useCallback(
    language => {
      setTranslateSelectLanguage(language);

      const translationAux = props.initialData?.translations?.find(
        x => x.languageId === language.value,
      );

      if (translationAux) {
        setTextTitleQuestionTranslated(translationAux?.text ?? '');
      } else {
        setTextTitleQuestionTranslated('');
      }

      const alternativesfilledAux = props.initialData?.alternatives?.map(
        (a: Alternative) => {
          return {
            ...a,
            textTranslated: translationAux
              ? translationAux.alternatives?.find(x => x.value === a.value)
                  ?.text
              : '',
          };
        },
      );

      if (alternativesfilledAux) setAlternativesFilled(alternativesfilledAux);

      const optionsfilledAux = optionsFilled.map((option: Option) => {
        const getTextTranslated = translationAux?.options?.find(
          opt => opt.value === option.value,
        );
        return {
          ...option,
          textTranslated: getTextTranslated ? getTextTranslated.text : '',
        };
      });

      if (optionsfilledAux) setOptionsFilled(optionsfilledAux);
    },
    [
      optionsFilled,
      props.initialData?.alternatives,
      props.initialData?.translations,
    ],
  );

  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>
                Default Language
                {props.isEdit && !editLanguageBool && (
                  <DivEditLanguageButton>
                    <EditLanguageButton
                      onClick={handleEditLanguage}
                      type="button"
                    >
                      Change Default Language
                    </EditLanguageButton>
                  </DivEditLanguageButton>
                )}
              </H2Relative>
            </div>
            <Select
              name="defaultLanguageId"
              value={defaultLanguage}
              options={questionDefaultLanguages}
              onChange={e => handleLanguageDefaultChange(e)}
              isDisabled={props.isEdit && !editLanguageBool}
            />
          </OriginalField>

          <>
            <TranslationArrowDiv>
              <TranslationArrow>
                <RiTranslate />
              </TranslationArrow>
            </TranslationArrowDiv>

            <TranslationDiv>
              <>
                <h2>Translation - Language</h2>
                <Select
                  name="translationLanguageId"
                  value={translateSelectLanguage}
                  options={questionDefaultLanguages.filter(
                    l => l.value !== defaultLanguage.value,
                  )}
                  onChange={e => handleTranslateSelectLanguageChange(e)}
                  // isDisabled={disableEditType}
                />
              </>
            </TranslationDiv>
          </>
        </LanguageDiv>
        <LanguageDiv>
          <OriginalField>
            <h2>Text</h2>
            <Input type="text" name="text" placeholder="Enter question text" />
          </OriginalField>
          {/* {props.isEdit && ( */}
          <>
            <TranslationArrowDiv>
              <TranslationArrow>
                <RiTranslate />
              </TranslationArrow>
            </TranslationArrowDiv>

            <TranslationDiv>
              <>
                <h2>Translation - Text</h2>
                <Input
                  type="text"
                  name="textTranslation"
                  placeholder="Enter question text translated"
                  defaultValue={textTitleQuestionTranslated}
                  disabled={!translateSelectLanguage.value}
                />
              </>
            </TranslationDiv>
          </>
          {/* )} */}
        </LanguageDiv>
        <h2>Alternatives</h2>
        <MultiInput
          name="alternatives"
          width="100%"
          translate={true}
          disableTranslations={!translateSelectLanguage.value}
          filledOptions={
            props.initialData?.alternatives &&
            props.initialData.alternatives.length > 0
              ? alternativesFilled
              : [{ value: 1, text: '' }]
          }
        />

        <h2>Type </h2>
        <Select
          name="type"
          value={type}
          options={questionTypes}
          onChange={e => handleTypeChange(e)}
          isDisabled={props.disableType}
        />

        {type.value !== '' && type.label !== 'Text' && (
          <>
            <h2>Combination Type</h2>
            <Select
              name="combinationType"
              value={defaultCombinationType}
              options={combinationTypes}
              onChange={e => handleCombinationTypeChange(e)}
              isDisabled={false}
            />
            <>
              <MultiInput
                name="options"
                width="100%"
                translate={true}
                combinationTypes={
                  defaultCombinationType.value === '4'
                    ? false
                    : hasCombinationType
                }
                title={true}
                disableTranslations={!translateSelectLanguage.value}
                filledOptions={optionsFilled}
              />
            </>
          </>
        )}

        <h2>Topics</h2>
        <TagsSelect
          name="tagsArray"
          setSelectedTags={setSelectedTags}
          tags={props.tags}
          tagsSearch={props.tagsSearch}
          acceptNewValues={false}
        ></TagsSelect>

        <DivAddNewTag>
          <AddNewTagButton
            onClick={() => {
              setIsNewTagsFieldVisible(true);
            }}
            hidden={isNewTagsFieldVisible}
            type="button"
          >
            Create New Topics
          </AddNewTagButton>
          {isNewTagsFieldVisible && (
            <>
              <h2>Create new tags</h2>
              <TagsInput
                name="tagsInputQuestions"
                setSelectedTags={setNewTags}
                tags={newTags}
                existentTagsInDatabase={props.tagsSearch}
              ></TagsInput>
              <AddNewTagButton
                onClick={() => {
                  setIsNewTagsFieldVisible(false);
                  setNewTags([]);
                }}
                type="button"
              >
                Cancel
              </AddNewTagButton>
            </>
          )}
        </DivAddNewTag>
        <Input
          type="text"
          readOnly={true}
          show={false}
          name="inputTags"
          value={
            newTags.length === 0 && selectedTags.length > 0
              ? `[${selectedTags.map(item => `"${item.value}"`)}]`
              : newTags.length > 0 && selectedTags.length === 0
              ? `[${newTags.map(item => `"${item}"`)}]`
              : newTags.length === 0 && selectedTags.length === 0
              ? `[]`
              : `[${selectedTags.map(item => `"${item.value}"`)}, ${newTags.map(
                  item => `"${item}"`,
                )} ]`
          }
        />

        <h2>External Id Befragung</h2>
        <InputNumber
          type="number"
          onWheel={numberInputOnWheelPreventChange}
          name="externalIdBefragung"
          placeholder="Enter external id for Befragung"
        />

        <h2>External Id Umfrage</h2>
        <InputNumber
          type="number"
          onWheel={numberInputOnWheelPreventChange}
          name="externalIdUmfrage"
          placeholder="Enter external id for Umfrage"
        />

        <h2>External Id TSÖ</h2>
        <InputNumber
          type="number"
          onWheel={numberInputOnWheelPreventChange}
          name="externalIdTSO"
          placeholder="Enter external id for TSÖ"
        />
        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormQuestion;
