/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-multi-str */

import { ItemValue } from 'survey-react';

// adding a custom property into an existent question type (rating type was developed by surveyjs team)
export const EbRadioGroup: any = (SurveyKo: any) => {
  const radio = {
    name: 'ebradiogroup',
    isFit(question: any) {
      return question.getType() == 'radiogroup';
    },
    init() {
      SurveyKo.Serializer.addProperty('radiogroup', {
        name: 'rateValues:itemvalues',
        category: 'RateValues',
      });

      SurveyKo.Serializer.addProperty('radiogroup', {
        name: 'choiceImages:itemvalues',
        category: 'ChoiceImages',
      });
    },
    isDefaultRender: true,
    afterRender(question: any, el: any) {
      const inputs = document.querySelectorAll(`input[id^="${question.id}"]`);
      inputs.forEach((input, index) => {
        input.parentElement?.style.setProperty('display', 'flex');
        input.parentElement?.style.setProperty('align-items', 'center');

        const choiceImage = question.choiceImages[index];
        if (choiceImage && choiceImage.text.startsWith('http')) {
          const label = input.nextSibling;
          const img = document.createElement('img');
          img.id = 'imageTest';
          img.src = choiceImage.text;
          img.style.width = '70px'; // Ajuste o tamanho da imagem conforme necessário
          img.style.marginRight = '10px';

          label?.parentNode?.insertBefore(img, label);
        }
      });

      if (!question.choices) return;

      const newChoices = question.choices.map((choice: any) => {
        choice.pureText = choice.text;
        return choice;
      });
      question.rateValues = newChoices;

      if (question.choiceImages.length === 0) {
        const newChoicesImages = question.choices.map((choice: any) => {
          return new ItemValue(choice.value, '', '');
        });
        question.choiceImages = newChoicesImages;
      }
    },
  };

  return radio;
};
