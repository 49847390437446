import styled from 'styled-components';
import { Button as Btn } from '@material-ui/core';

import { Add } from '@material-ui/icons';

export const Container = styled.div``;

export const ButtonMd = styled(Btn)`
  background-color: #00a65a !important;
  color: #fff !important;
  padding: 12px 0 !important;
  width: 10%;
  margin: 38px 0 0 0 !important;
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectContainer = styled.div`
  flex: 1;
  margin: 0 5px 0 0;

  .css-g1d714-ValueContainer {
    height: 42px;
  }
`;

export const IptContainer = styled.div`
  flex: 1;
  margin: 0 15px 0 0;
`;

export const PlaceholderContainer = styled.div`
  && button {
    background: #ffffff;
    border: 0;
    margin: 0;
    padding: 0;
  }

  && th {
    width: 205px;
  }
`;

export const AddIcon = styled(Add)`
  display: table-cell;
  vertical-align: middle;
`;

export const ContainerQRCodes = styled.div`
  position: absolute;
  z-index: -200;
  width: 87%;
  top: -25px;
  right: 3px;
  padding: 30px;
  overflow: hidden;
  z-index: -9999;
`;
