import React from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Input from '../../../components/FormInput';
import Button from '../../../components/Button';
import Select from '../../../components/SelectForm';
import { languagesISO6391 } from '../languagesISO6391';
import { Container } from './styles';

interface Language {
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  accessLockedMessage: string;
  codeNotExistsMessage: string;
  surveyCompletedMessage: string;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: Language;
  handleSubmit: SubmitHandler;
}

const FormLanguage: React.FC<Props> = props => {
  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Name</h2>
        <Input type="text" name="name" placeholder="Enter language name" />

        <h2>Language Code</h2>
        <Select
          name="languageCode"
          options={languagesISO6391}
          defaultValue={languagesISO6391[0]}
        />

        <h2>Back Button (Zurück)</h2>
        <Input
          type="text"
          name="pagePrevText"
          placeholder="Enter the text to be displayed on the 'Zurück' button"
        />

        <h2>Button Next (Weiter)</h2>
        <Input
          type="text"
          name="pageNextText"
          placeholder="Enter the text to be displayed on the ''Weiter' button"
        />
        <h2>Complete Text(Abschicken)</h2>
        <Input
          type="text"
          name="completeText"
          placeholder="Enter the text to be displayed on the 'Abschicken' button"
        />
        <h2>
          Progress Text(Seite {'{0}'} von {'{1}'})
        </h2>
        <Input
          type="text"
          name="progressText"
          placeholder="Enter the text to replace 'Seite {0} von {1}'"
        />
        <h2>Button continue in start text page (Fortfahren)</h2>
        <Input
          type="text"
          name="nameButtonStartText"
          placeholder="Enter the text to be displayed on the 'Fortfahren' button in start page"
        />
        <h2>Required Error (Bitte eine Antwort geben) </h2>
        <Input
          type="text"
          name="requiredErrorText"
          placeholder="Enter the message to be displayed when a question or matrix is required"
        />
        <h2>
          Placeholder text question (Tippen Sie Ihre Antwort bitte hier ein:)
        </h2>
        <Input
          type="text"
          name="placeholderTextQuestion"
          placeholder="Enter the text to replace 'Tippen Sie Ihre Antwort bitte hier ein:' for text questions"
        />
        <h2>Required all rows in matrix(Bitte beantworten Sie alle Fragen)</h2>
        <Input
          type="text"
          name="requiredAllrowsErrorText"
          placeholder="Enter the message to be displayed when all matrix's questions are required"
        />
        <h2>GDPR text footenote</h2>
        <span>
          (Vertrauen ist wichtig, besonders wenn es um Ihre Daten geht. Deshalb
          sehen wir es als unsere Verpflichtung, Ihre Daten mit höchster
          Sorgfalt zu verwalten und alles zu tun, um Ihre Informationen vor
          Missbrauch zu schützen. Die emotion banking GmbH verarbeitet Ihre
          Daten ausschließlich auf Grundlage der gesetzlichen Bestimmungen
          (DSGVO).)
        </span>
        <Input
          type="text"
          name="textFootnote"
          placeholder="Enter the message to be displayed above start text"
        />
        <h2>GDPR text link (Mehr Information)</h2>
        <Input
          type="text"
          name="textLinkFootnote"
          placeholder="Enter the message to be displayed in the link to see more information about GDPR"
        />

        <h2>Link Locked Message (Diese Umfrage ist nicht mehr aktiv)</h2>
        <Input
          type="text"
          name="accessLockedMessage"
          placeholder="Enter the message to be displayed when the link is locked"
        />

        <h2>
          Code does not exist Message (Dieser Teilnahmecode ist ungültig.)
        </h2>
        <Input
          type="text"
          name="codeNotExistsMessage"
          placeholder="Enter the message to be displayed when the user try a non-existential code"
        />

        <h2>
          Survey completed Message (Sie haben bereits an der Umfrage
          teilgenommen. Vielen Dank für Ihre Teilnahme.)
        </h2>
        <Input
          type="text"
          name="surveyCompletedMessage"
          placeholder="Enter the message to be displayed when the user finish answering the survey"
        />

        <h2>
          Contact Message (Es liegt ein Fehler vor? Dann melden Sie sich bitte
          bei office@emotion-banking.at)
        </h2>
        <Input
          type="text"
          name="contactUsMessage"
          placeholder="Enter the message to be displayed in warnings."
        />

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormLanguage;
