import React, { useCallback, useEffect, useState } from 'react';
import { IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { Container, Content } from '../styles';
import Table from '../../../components/DevExpressTable/DevExpressTable';
import ButtonEb from '../../../components/Button';
import { columns, columnsSelect } from './columns';
import { DivFilter, IdsSelected, SpanWarning } from './styles';
import { useToast } from '../../../hooks/toast';

interface Access {
  accessId: number;
  accessName: string;
  surveyId: number;
  segments: string[];
  subGroupSurvey: string;
  surveyName: string;
  groupNames: string[];
}

interface AccessGroup {
  accessIds: number[];
  name: string;
}

interface Props {
  select?: boolean;
  name?: string;
  selectedIds?: number[];
  isEdit?: boolean;
  idEdited?: number;
  handleSubmit?: (accessGroup: AccessGroup) => void;
}

const SearchAccess: React.FC<Props> = props => {
  const history = useHistory();
  const [accesses, setAccesses] = useState<Access[]>([]);
  const [codeSearch, setCodeSearch] = useState<string>('');
  const [msgWarning, setMsgWarning] = useState<string>('');
  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
  const { addToast } = useToast();

  // custom filter for field 'segments'
  const [filteringColumnExtensions] = useState([
    {
      columnName: 'segments',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'contains') {
          if (row.segments && row.segments.length > 0) {
            return row.segments.some((s: string) =>
              s.toLowerCase().includes(filter.value.toLowerCase()),
            );
          }
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
    {
      columnName: 'groupNames',
      predicate: (value: any, filter: any, row: any) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'contains') {
          if (row.groupNames && row.groupNames.length > 0) {
            return row.groupNames.some((group: string) =>
              group.toLowerCase().includes(filter.value.toLowerCase()),
            );
          }
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
  ]);

  useEffect(() => {
    if (props && props.selectedIds) {
      if (props.isEdit) setSelectedRowIds(props.selectedIds);
    }
  }, [props]);

  useEffect(() => {
    setMsgWarning('');
    if (codeSearch.length === 0) {
      api.get('accesses/full').then(response => {
        if (response.data) {
          setAccesses(
            response.data.sort(
              (a: Access, b: Access) => a.accessId - b.accessId,
            ),
          );
        }
      });
    } else if (codeSearch.length === 4) {
      api.get(`accesses/getByCode/${codeSearch}`).then(response => {
        if (response && response.data) {
          if (response.data.length === 0) {
            setMsgWarning('Not found.');
          } else {
            setAccesses([response.data]);
          }
        }
      });
    }
  }, [codeSearch]);

  const handleConfirm = useCallback(async () => {
    if (props && props.name) {
      const postBodyAccessGroup: AccessGroup = {
        name: props.name,
        accessIds: selectedRowIds.map((id: string) => {
          return +id;
        }),
      };

      if (postBodyAccessGroup.accessIds.length === 0) {
        addToast({
          type: 'info',
          title: 'Access is required',
          description: 'The system cannot find any access, please try again',
        });

        return;
      }
      if (props && props.handleSubmit) props.handleSubmit(postBodyAccessGroup);
    } else {
      addToast({
        type: 'info',
        title: 'Name is required',
        description: 'The system cannot find the group name, please try again',
      });
    }
  }, [addToast, selectedRowIds, props]);

  return (
    <Container>
      {!props.select && <h1>Access</h1>}

      {props.select && (
        <IdsSelected>
          {`Selected IDs: `}
          {selectedRowIds
            .map((x: string) => {
              return `${x} `;
            })
            .toString()
            .slice(0, -1)}
        </IdsSelected>
      )}

      <Content>
        {!props?.select && (
          <DivFilter>
            <span>Code: </span>
            <input
              name={'inputSearchCategories'}
              placeholder={'Search by code...'}
              onChange={event =>
                setCodeSearch(event.target.value.toUpperCase())
              }
            />

            <SpanWarning>{msgWarning}</SpanWarning>
          </DivFilter>
        )}

        <Table
          columnsProp={!props?.select ? columns : columnsSelect}
          selectionProp={selectedRowIds}
          multiSelection={true}
          dataProp={accesses}
          checkboxSelection={!!props?.select}
          idName={'accessId'}
          setSelectedRowId={setSelectedRowIds}
          hasFilterRow={true}
          integratedFilteringColumnExtensions={filteringColumnExtensions}
        ></Table>

        {!!props?.select && (
          <>
            <ButtonEb
              width="120px"
              height="40px"
              marginRight="30px"
              onClick={handleConfirm}
            >
              Confirm
            </ButtonEb>

            <Link to={''} onClick={history.goBack}>
              <ButtonEb width="120px" height="40px">
                Back
              </ButtonEb>
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};

export default SearchAccess;
