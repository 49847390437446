import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { Container, Content, Button, DeleteIcon } from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface Segment {
  segmentId: number;
  name: string;
}

const Segment: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [segments, setSegments] = useState<Segment[]>([]);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: true },
    { columnName: 'segmentId', width: 120 },
  ]);

  const handleDelete = useCallback(
    (row: any) => {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const userWantDelete = confirm(
        `Are you sure you want to delete the segment ${row.segmentId} - ${row.name}?`,
      );

      if (userWantDelete) {
        api.delete(`/segments/${row.segmentId}`).then(response => {
          if (response.data) {
            if (response.data.deleted) {
              addToast({
                type: 'success',
                title: 'Success',
                description: `The segment ${row.segmentId} was deleted successfully!`,
              });
              history.push('/projects');
              history.push('/segments');
            } else {
              addToast({
                type: 'info',
                title: 'Can not be deleted',
                description: response.data.message,
              });
            }
          } else {
            addToast({
              type: 'error',
              title: 'Deleting Error',
              description: `An error occurred while deleting this segement, please try again.`,
            });
          }
        });
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'delete',
      label: 'Delete',
      onClick: handleDelete,
      icon: <DeleteIcon />,
      width: 120,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('segments').then(response => {
      setSegments(
        response.data.map((segment: Segment) => {
          return {
            name: segment.name,
            segmentId: segment.segmentId,
          };
        }),
      );
    });

    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Segments </h1>

      <Link
        to={{
          pathname: `/segments/new`,
        }}
      >
        <Button variant="contained" type="button">
          New Segment
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={segments}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          idName={'segmentId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default Segment;
