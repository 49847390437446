import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import api from '../../../services/api';
import EnumQuestionType from '../../../utils/enums/EnumQuestionType';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import FormQuestion from '../Form';
import {
  BoxId,
  Button,
  ButtonDelete,
  DivButtonDialog,
  HeaderInformations,
  HeaderInformationsLeft,
  HeaderInformationsRight,
} from './styles';
import Tooltip from '../../../components/Tooltip';
import EnumCombinationKeys, {
  EnumCombinationValues,
} from '../../../utils/enums/EnumCombinationTypes';

interface Question {
  externalId: number;
  text: string;
  type: string;
  alternatives: Alternative[];
  options: Option[];
  combinationType: string;
  tags: any;
  inputTags: string;
  defaultLanguageId?: number;
  defaultLanguageName?: string;
  translations?: Translation[];
  textTranslation?: string;
  translationLanguageId?: number;
  externalIdBefragung?: number;
  externalIdUmfrage?: number;
  externalIdTSO?: number;
}
interface Translation {
  alternatives: Alternative[];
  options: Option[];
  languageId: number;
  text: string;
}
interface Alternative {
  value: number;
  text: string;
  textTranslated?: string;
}
interface Option {
  value: number;
  text: string;
  textTranslated?: string;
  combinationValue?: string;
}

interface Combination {
  value: number;
  text: string;
  combinationValue?: string;
  textTranslated?: string;
}

interface ParamTypes {
  questionId: string;
}
const EditQuestion: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { questionId } = useParams<ParamTypes>();
  const [question, setQuestion] = useState<Question>();
  const [belongsSurveyIds, setBelongsSurveyIds] = useState<any[]>([]);
  const [belongsApillarIds, setBelongsApillarIds] = useState<any[]>([]);
  const [disableEditType, setDisableEditType] = useState(false);
  const [tags, setTags] = useState<any[]>([]);

  const [tagsSearch, setTagsSearch] = useState([]);
  const history = useHistory();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    let topicList: any[] = [];
    const loadData = async (): Promise<void> => {
      await api.post(`/questions/listTopics`).then(response => {
        setTagsSearch(response.data);
      });

      await api.get(`/questions/${questionId}`).then(response => {
        setQuestion(response.data);
        formRef.current?.setFieldValue('text', response.data.text);

        formRef.current?.setFieldValue('type', {
          value: response.data.type,
          label:
            EnumQuestionType[
              response.data.type as keyof typeof EnumQuestionType
            ],
        });
        formRef.current?.setFieldValue('tags', response.data.topics);
        topicList = response.data?.topics?.map((x: any) => {
          return { value: x };
        });

        setTags(topicList); // topics is a list of tags linked on this question

        formRef.current?.setFieldValue('defaultLanguageId', {
          value: response.data.defaultLanguageId,
          label: response.data.defaultLanguageName,
        });

        const { combinationType } = response.data;

        if (combinationType) {
          formRef.current?.setFieldValue('combinationType', {
            value: Number(
              EnumCombinationKeys[
                combinationType as keyof typeof EnumCombinationKeys
              ],
            ),
            label:
              EnumCombinationValues[
                combinationType as keyof typeof EnumCombinationKeys
              ],
          });
        }

        formRef.current?.setFieldValue('text', response.data.text);
      });

      await api.get(`/surveys/question/${questionId}`).then((response: any) => {
        if (response.data) {
          setBelongsSurveyIds(response.data);
        }
      });

      await api
        .get(`/apillars/questions/${questionId}`)
        .then((response: any) => {
          if (response.data && response.data.length > 0) {
            setBelongsApillarIds(
              response.data.map((x: any) => {
                return x.apillarId;
              }),
            );
          }
        });

      await api
        .get(`/results/question/${questionId}/`)
        .then(responseQuestionBelongsToAnyResults => {
          if (responseQuestionBelongsToAnyResults.data === true) {
            setDisableEditType(true);
          }
        });
    };

    loadData();
  }, [questionId]);

  const handleSubmit = useCallback(
    async (data: Question) => {
      try {
        formRef.current?.setErrors({});
        const showOptions = data.type !== EnumQuestionType.Text;
        const alternativesTranslated: Alternative[] = [];
        const alternatives: Alternative[] = [];
        data.alternatives?.forEach((x: Alternative) => {
          alternativesTranslated.push({
            value: x.value,
            text: x.textTranslated ?? '',
          });
        });
        data.alternatives?.forEach((x: Alternative) => {
          alternatives.push({
            value: x.value,
            text: x.text ?? '',
          });
        });
        const optionsTranslated: Option[] = [];
        const options: Option[] = [];
        let optionFieldRequired = false;
        data.options?.forEach((x: Combination) => {
          if (x.textTranslated === '') {
            optionFieldRequired = true;
          }
          optionsTranslated.push({
            value: x.value,
            text: x.textTranslated ?? '',
          });
        });

        let typeCombination: any;
        if (data.combinationType) {
          typeCombination =
            Object.keys(EnumCombinationKeys)[
              Object.values(EnumCombinationKeys).indexOf(
                data.combinationType.toString() as EnumCombinationKeys,
              )
            ];
        } else {
          typeCombination = 'No_combinations';
        }

        data?.options?.forEach((x: Option) => {
          if (typeCombination === 'No_combinations') {
            options.push({
              value: x.value,
              text: x.text ?? '',
            });
          } else {
            options.push({
              value: x.value,
              text: x.text ?? '',
              combinationValue: x.combinationValue,
            });
          }
        });

        const questionDb = await api.get(`/questions/${questionId}`);
        const questionUp = questionDb.data;
        const translationArrayOriginal = questionUp.translations?.filter(
          (x: any) =>
            x.languageId !== data.translationLanguageId &&
            x.languageId !== data.defaultLanguageId,
        );
        const translationsArray = translationArrayOriginal ?? [];
        if (
          data &&
          data.defaultLanguageId &&
          data.translationLanguageId &&
          data.defaultLanguageId !== data.translationLanguageId
        ) {
          translationsArray?.push({
            alternatives: alternativesTranslated,
            options: optionsTranslated,
            text: data.textTranslation ?? '',
            languageId: data.translationLanguageId ?? 0,
          });
        }

        const questionEdited: any = {
          externalId: data.externalId,
          text: data.text,
          type: data.type,
          alternatives,
          options,
          combinationType: typeCombination,
          topics: JSON.parse(data.inputTags),
          defaultLanguageId: data.defaultLanguageId,
          defaultLanguageName: data.defaultLanguageName,
          translations: translationsArray,
          externalIdBefragung: data.externalIdBefragung
            ? data.externalIdBefragung
            : null,
          externalIdUmfrage: data.externalIdUmfrage
            ? data.externalIdUmfrage
            : null,
          externalIdTSO: data.externalIdTSO ? data.externalIdTSO : null,
        };

        const schema = Yup.object().shape({
          text: Yup.string().required('Question text is required'),
          type: Yup.string().required('Question type is required'),
          options: showOptions
            ? data.translationLanguageId && optionFieldRequired
              ? Yup.string().required('The question options are required')
              : Yup.array()
            : Yup.array(),
          textTranslation: Yup.string(),
          externalIdBefragung: data.externalIdBefragung
            ? Yup.number().nullable().notRequired()
            : Yup.string(),
          externalIdUmfrage: data.externalIdUmfrage
            ? Yup.number().nullable().notRequired()
            : Yup.string(),
          externalIdTSO: data.externalIdTSO
            ? Yup.number().nullable().notRequired()
            : Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/questions/${questionId}`, questionEdited);

        history.push('/questions');

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The question was saved successfully!',
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing question, please try again.',
        });
      }
    },
    [questionId, history, addToast],
  );

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`/questions/${questionId}`);
      history.push('/questions');
      addToast({
        type: 'success',
        title: 'Success',
        description: `The question ${questionId} was deleted successfully!`,
      });
    } catch (err) {
      console.log(err);

      addToast({
        type: 'error',
        title: 'Delete Error',
        description:
          'An error occurred while deleting question, please try again.',
      });
    }
  }, [addToast, history, questionId]);

  const getTooltipeTitle = useCallback((): string => {
    if (belongsApillarIds.length > 0) {
      return `You cannot delete this question. This question is used by A-Pillar: ${belongsApillarIds}`;
    }
    if (belongsSurveyIds.length > 0)
      return `You cannot delete this question. This question is used by surveys: ${belongsSurveyIds}`;
    if (disableEditType)
      return 'You cannot delete this question. This question is used by some result.';
    return '';
  }, [belongsApillarIds, belongsSurveyIds, disableEditType]);

  const canDeleteQuestion = useCallback((): boolean => {
    if (
      disableEditType ||
      belongsSurveyIds.length > 0 ||
      belongsApillarIds.length > 0
    )
      return false;
    return true;
  }, [disableEditType, belongsSurveyIds, belongsApillarIds]);

  const handleDialog = useCallback(async () => {
    setOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <h1>{`Edit Question`}</h1>

      <HeaderInformations>
        <HeaderInformationsLeft>
          <BoxId>
            <h3>{`Question Id: ${questionId}`} </h3>
            <div>
              <h3>
                This question is being used by surveys:{' '}
                {belongsSurveyIds?.join(', ')}
              </h3>
            </div>
          </BoxId>
        </HeaderInformationsLeft>
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want delete this question?</DialogTitle>
          <DivButtonDialog>
            <DialogActions>
              <Button
                onClick={handleDelete}
                color="primary"
                autoFocus
                type={'button'}
              >
                YES
              </Button>
              <Button
                onClick={handleCloseDialog}
                color="primary"
                type={'button'}
              >
                NO
              </Button>
            </DialogActions>
          </DivButtonDialog>
        </Dialog>
        <HeaderInformationsRight>
          {canDeleteQuestion() ? (
            <ButtonDelete type="button" disabled={false} onClick={handleDialog}>
              Delete
            </ButtonDelete>
          ) : (
            <Tooltip title={getTooltipeTitle()} className={'big'}>
              <ButtonDelete
                type="button"
                disabled={true}
                onClick={handleDialog}
              >
                Delete
              </ButtonDelete>
            </Tooltip>
          )}
        </HeaderInformationsRight>
      </HeaderInformations>

      <FormQuestion
        formRef={formRef}
        handleSubmit={handleSubmit}
        initialData={question}
        isEdit
        buttonText="Save"
        questionId={questionId}
        disableType={disableEditType}
        setTags={setTags}
        tags={tags}
        tagsSearch={tagsSearch}
      ></FormQuestion>
    </>
  );
};
export default EditQuestion;
