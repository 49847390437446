import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 20px 0 0;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivModal = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: stretch;

  transform: 'translate(-50%, -50%)';
  width: 400;
  background-color: red;
  border: '2px solid #000';
  /* boxshadow: 24; */
  p: 4;
`;

export const DivAddNewTag = styled.span`
  margin: 10px 10px 0 0;
  @media (max-width: 1195px) {
    position: relative;
  }
`;

export const AddNewTagButton = styled.button`
  background-color: #00b698;
  margin: 10px 0 0 0;
  color: white;
  border: 0;
  &:hover {
    background-color: #009179;
  }
  padding: 0px 20px;
`;

export const CheckReviewed = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  #labelIsLocked {
    margin-left: 5px;
  }
`;

export const FilterAndReviewed = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: baseline;
  width: 720px;
`;

export const ButtonEditAnswer = styled.button`
  background-color: #00b698;
  color: white;
  border: 0;
  border-radius: 5px;
  &:hover {
    background-color: #009179;
  }
  padding: 3px 30px;
  margin-top: 10px;
`;

export const DivButtonEditAnswer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
