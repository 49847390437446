import React, { useState, useCallback, useEffect } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { RiTranslate } from 'react-icons/ri';
import Input from '../../../components/FormInput';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import Select from '../../../components/SelectForm';
import api from '../../../services/api';

import {
  Container,
  H2Relative,
  LanguageDiv,
  TranslationArrow,
  TranslationArrowDiv,
  OriginalField,
  TranslationDiv,
} from './styles';

interface Translation {
  text: string;
  languageId: number;
}

interface SurveyText {
  title: string;
  text: string;

  isStartText: boolean;
  defaultLanguageId?: number;
  translations: Translation[];
}

interface DefaultLanguage {
  value: string;
  label: string;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: SurveyText;
  handleSubmit: SubmitHandler;
}

const FormSurveyText: React.FC<Props> = props => {
  const [defaultLanguage, setDefaultLanguage] = useState({
    value: '1',
    label: 'German',
  } as DefaultLanguage);

  const [translateSelectLanguage, setTranslateSelectLanguage] = useState({
    value: '',
    label: 'Select...',
  } as DefaultLanguage);

  const [textTranslated, setTextTranslated] = useState('');

  const handleLanguageDefaultChange = useCallback(language => {
    setDefaultLanguage(language);
  }, []);

  const [textDefaultLanguages, setTextDefaultLanguages] = useState(
    [] as DefaultLanguage[],
  );

  useEffect(() => {
    api.get('languages').then(response => {
      const languageList: DefaultLanguage[] = [];

      if (response.data) {
        response.data.forEach((language: any) => {
          languageList.push({
            value: language.languageId,
            label: language.name,
          });
        });
      }
      setTextDefaultLanguages(languageList);
    });
  }, [props?.initialData]);

  useEffect(() => {
    if (props?.initialData && props.initialData.defaultLanguageId) {
      const languageObj = textDefaultLanguages.find(
        (x: any) => x.value === props?.initialData?.defaultLanguageId,
      );
      if (languageObj) setDefaultLanguage(languageObj);
    }
  }, [props.initialData, textDefaultLanguages]);

  const handleTranslateSelectLanguageChange = useCallback(
    language => {
      setTranslateSelectLanguage(language);

      const translationAux = props.initialData?.translations?.find(
        x => x.languageId === language.value,
      );
      if (translationAux) {
        setTextTranslated(translationAux?.text ?? '');
      } else {
        setTextTranslated('');
      }
    },
    [props.initialData?.translations],
  );
  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Title</h2>
        <Input type="text" name="title" placeholder="Enter the title" />

        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>Default Language</H2Relative>
            </div>
            <Select
              name="defaultLanguageId"
              value={defaultLanguage}
              options={textDefaultLanguages}
              onChange={e => handleLanguageDefaultChange(e)}
              // isDisabled={props.isEdit && !editLanguageBool}
            />
          </OriginalField>

          <TranslationArrowDiv>
            <TranslationArrow>
              <RiTranslate />
            </TranslationArrow>
          </TranslationArrowDiv>

          <TranslationDiv>
            <h2>Translation - Language</h2>
            <Select
              name="translationLanguageId"
              value={translateSelectLanguage}
              options={textDefaultLanguages.filter(
                l => l.value !== defaultLanguage.value,
              )}
              onChange={e => handleTranslateSelectLanguageChange(e)}
              // isDisabled={props.isEdit && !editLanguageBool}
            />
          </TranslationDiv>
        </LanguageDiv>

        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>Text</H2Relative>
            </div>
            <Textarea
              initialData={props.initialData ? props.initialData.text : ''}
              name="text"
              placeholder="Enter the text"
              rows={14}
            />
          </OriginalField>

          <TranslationArrowDiv>
            <TranslationArrow>
              <RiTranslate />
            </TranslationArrow>
          </TranslationArrowDiv>

          <TranslationDiv>
            <h2>Translation - Text</h2>
            <Textarea
              initialData={textTranslated}
              name="textTranslated"
              placeholder="Enter the text"
              rows={14}
            />
          </TranslationDiv>
        </LanguageDiv>

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormSurveyText;
