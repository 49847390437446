import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { BoxMessageProvider } from './boxMessage';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>
      <BoxMessageProvider>{children}</BoxMessageProvider>
    </ToastProvider>
  </AuthProvider>
);

export default AppProvider;
