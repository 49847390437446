import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';

import { Container } from './styles';

import LanguageSelector from '../../../components/SelectLanguage';

interface Props {
  survey: any;
}

interface Language {
  languageId: number;
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  value: number;
  label: string;
  languageCode: string;
}

const SelectLanguage: React.FC<Props> = ({ survey }) => {
  const history = useHistory();

  const [languages, setLanguages] = useState<Language[]>([]);

  useEffect(() => {
    const languageDropDown: Language[] = [];
    api.get('languages').then(response => {
      response.data.forEach((e: Language) => {
        const dataRowDropDown = { ...e, value: e.languageId, label: e.name };
        languageDropDown.push(dataRowDropDown);
      });

      if (survey) {
        setLanguages(
          languageDropDown.filter(
            l =>
              survey?.languages.includes(l.languageId) ||
              l.languageId === survey.defaultLanguageId,
          ),
        );
      } else {
        setLanguages(languageDropDown);
      }
    });
  }, [survey, survey?.languages]);

  const handleLanguageChange = useCallback(
    async (e: Language | null) => {
      if (e) {
        const originalUrl = window.location.href;
        let urlRedirect = `${originalUrl}?lang=${e.value}&langCode=${
          e.languageCode ?? 'de'
        }`;
        if (originalUrl.indexOf('?') > 0) {
          urlRedirect = `${originalUrl}&lang=${e.value}&langCode=${
            e.languageCode ?? 'de'
          }`;
        }

        history.push(
          /questionnaire/ +
            urlRedirect.substring(
              urlRedirect.indexOf('questionnaire/') + 14,
              urlRedirect.length,
            ),
        );
      }
    },
    [history],
  );

  return (
    <Container>
      <LanguageSelector
        languages={languages}
        handleLanguageChange={handleLanguageChange}
      />
    </Container>
  );
};

export default SelectLanguage;
