/* eslint-disable func-names */
/* eslint-disable eqeqeq */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */

import './eb-contact-form.js';

export const EbContactForm: any = (SurveyKo: any) => {
  return {
    name: 'ebcontactform',
    title: 'EB Contact Form',
    iconName: 'icon-paneldynamic',
    widgetIsLoaded() {
      return typeof $ == 'function';
    },
    defaultJSON: {
      title: 'Contact Form',
      hideNumber: true,
    },
    isFit(question: any) {
      return question.getType() == 'ebcontactform';
    },
    isDefaultRender: false,
    htmlTemplate: "<div style='height: 650px'></div>",
    activatedByChanged(activatedBy: any) {
      SurveyKo.JsonObject.metaData.addClass(
        'ebcontactform',
        [
          {
            name: 'hasOther',
            visible: false,
          },
          {
            name: 'otherText',
            visible: false,
          },
          {
            name: 'optionsCaption',
            visible: false,
          },
          {
            name: 'otherErrorText',
            visible: false,
          },
          {
            name: 'storeOthersAsComment',
            visible: false,
          },
          {
            name: 'renderAs',
            visible: false,
          },
        ],
        null,
        'dropdown',
      );
      SurveyKo.JsonObject.metaData.addProperties('ebcontactform', [
        {
          name: 'mailFrom:string',
          category: 'general',
          default: '',
        },
      ]);
      SurveyKo.JsonObject.metaData.addProperties('ebcontactform', [
        {
          name: 'mailTo:string',
          category: 'general',
          default: '',
        },
      ]);
    },
    afterRender(question: any, el: any) {
      SurveyKo.Serializer.findProperty('ebcontactform', [
        { name: 'hideNumber', default: true },
      ]);
      const options = {
        question,
        onUpdate: (value: any) => {
          question.value = value;
        },
      };

      initWidget();

      question.readOnlyChangedCallback = initWidget;

      function initWidget() {
        el.innerHTML = `<div id="boxQuestions"></div>`;
        $(el).off();

        (<any>$(el)).find('div').ebcontactform(options);
      }
    },
    willUnmount(question: any, el: any) {
      el.innerHTML = null;
      $(el).off();
      question.readOnlyChangedCallback = null;
      question.valueChangedCallback = null;
    },
    pdfQuestionType: 'dropdown',
  };
};
