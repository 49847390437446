import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

interface SelectParentCategoryItemProps {
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #232129;
  border-radius: 10px;
  border: 2px solid #232129;
  padding: 16px;
  width: 100%;
  color: #666360;

  display: flex;

  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ff9000;
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const PositionRelative = styled.div`
  position: relative;
  margin-top: 0 !important;
`;

export const SelectContainer = styled.div`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
`;

export const SelectParent = styled.div`
  // classe suggestion
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
`;

export const SelectItem = styled.div<SelectParentCategoryItemProps>`
  :hover {
    background-color: #deebff;
    color: inherit;
  }
  // li
  background-color: transparent;
  color: inherit;

  ${props =>
    props.isActive &&
    css`
      background-color: #2684ff;
      color: hsl(0, 0%, 100%);
    `}

  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
`;
