import styled from 'styled-components';

export const Container = styled.div`
  . * {
    margin: 0;
    padding: 0;
  }
  html,
  body {
    height: 100%;
  }
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
  }

  .tags-input-container {
    border: 2px solid #cccccc;
    padding: 0.5em;
    border-radius: 3px;

    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  .tag-item {
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: 0.5em 0.75em;
    border-radius: 20px;
  }
  .tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    font-size: 18px;
    cursor: pointer;
  }

  .tags-input {
    flex-grow: 1;
    padding: 0.5em 0;
    border: none;
    outline: none;
  }
`;

export const DivWarning = styled.div`
  width: 100%;

  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0 0.2);

  display: flex;

  & + div {
    margin-top: 8px;
  }

  background: #ebf8ff;
  color: #3172b7;

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  align-items: center;

  svg {
    margin-top: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DivListSimillarWords = styled.div`
  display: flex;
`;
