/* eslint-disable eqeqeq */
import React, {
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import QRCode from 'qrcode';

import ExcelJs from 'exceljs';

import { DeleteOutline } from '@material-ui/icons';

import api from '../../../services/api';

import Input from '../../../components/FormInput';
import Select from '../../../components/SelectForm';
import Switch from '../../../components/Switch';
import Button from '../../../components/Button';

import Table from '../../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../../components/DevExpressTable/ActionsColumn';

import {
  Container,
  ButtonMd,
  BoxContainer,
  IptContainer,
  PlaceholderContainer,
  AddIcon,
  SelectContainer,
} from './styles';
import TableRows from '../tableRows';

import { columns } from './columns';

interface Access {
  accessId: number;
  surveyId: number;
  name: string;
  isLocked: boolean;
  segments: string[];
  results: Result[];
  placeholders: Placeholder[];
  subgroup: string;
  surveys: any;
}

interface Segment {
  name: string;
}

interface Result {
  resultId: number;
  code: string;
  createdByUser: boolean;
  languageId: number;
  url?: string;
}

interface Placeholder {
  replaceValue: string;
  searchValue: string;
}

interface CodeLink {
  resultId: number;
  code: string;
  url: string;
  createdByUser: boolean;
  surveyId: number;
  surveyName: string;
  segmentNames: string;
  linkName: string;
  subgroup: string;
  accessId: number;
  accessName: string;
  language: string;
  languageId: number;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: Access;
  handleSubmit: SubmitHandler;
  handleRemoveCode: (row: Result) => void;
  handleCreateCode?: MouseEventHandler<HTMLButtonElement>;
  isTemplate: boolean;
  languages?: SelectProps[];
  codeLinks?: CodeLink[];
}

// interface Language {
//   languageId: number;
//   languageName: string;
// }

interface State {
  surveyName: string;
  subgroup: string;
}

interface SelectProps {
  value: number;
  label: string;
}

const FormAccess: React.FC<Props> = props => {
  const [codeLinks, setCodeLinks] = useState<CodeLink[]>([]);
  const [segments, setSegments] = useState([]);
  const [isLocked, setIsLocked] = useState(props.initialData?.isLocked);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<SelectProps>();
  const [selectedSegments, setSelectedSegments] = useState<any>();

  const [tableColumnExtensions] = useState([
    { columnName: 'resultId', width: 120 },
    { columnName: 'code', width: 90 },
    { columnName: 'language', width: 120 },
    { columnName: 'url', width: 350 },
  ]);
  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'delete',
      label: '',
      onClick: props.handleRemoveCode,
      icon: <DeleteOutline />,
    },
  ]);

  useEffect(() => {
    const localIsLocked = props.initialData?.isLocked;
    setIsLocked(localIsLocked);

    if (props.isTemplate) {
      // Links must be locked for templates
      setIsLocked(true);
    }
  }, [props.initialData?.isLocked, props.isTemplate]);

  useEffect(() => {
    api.get('segments').then(response => {
      setSegments(
        response.data.map((segment: Segment) => {
          return {
            value: segment.name,
            label: segment.name,
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (props.codeLinks) {
      setCodeLinks(props.codeLinks);
    }
  }, [props.codeLinks]);

  const addTableRows = useCallback(() => {
    const rowsInput: Placeholder = {
      searchValue: '',
      replaceValue: '',
    };

    setRowsData([...rowsData, rowsInput]);
  }, [rowsData]);

  useEffect(() => {
    if (props.initialData?.placeholders)
      setRowsData(props.initialData?.placeholders);
  }, [props.initialData?.placeholders]);

  useEffect(() => {
    if (props.initialData?.segments) {
      const segm = props.initialData?.segments;
      const segmentsSelected = segm.map((s: any) => {
        return {
          label: s,
          value: s,
        };
      });
      setSelectedSegments(segmentsSelected);
    }
  }, [props.initialData?.segments]);

  const deleteTableRows = useCallback(
    async (index: any) => {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
    },
    [rowsData],
  );

  const handleChange = useCallback(
    async (index, evnt) => {
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    },
    [rowsData],
  );

  const handleSwitchChange = useCallback(value => {
    setIsLocked(value);
  }, []);

  const handleLanguageChange = useCallback(selectedValue => {
    setSelectedLanguage(selectedValue);
  }, []);

  const handleSelectedSegments = useCallback(selectedValue => {
    setSelectedSegments(selectedValue);
  }, []);

  const generateQRCode = useCallback(async (link: string) => {
    const urlConvertedReturn = await QRCode.toDataURL(link)
      .then((urlConverted: string) => {
        return urlConverted;
      })
      .catch(err => {
        console.error(err);
      });
    return urlConvertedReturn;
  }, []);

  const exportToExcel = useCallback(async () => {
    const workbook = new ExcelJs.Workbook();
    const sheet = workbook.addWorksheet('Link Codes');
    sheet.properties.defaultRowHeight = 80;

    sheet.columns = [
      {
        header: 'ID',
        key: 'resultId',
        width: 10,
      },
      {
        header: 'Access Id',
        key: 'accessId',
      },
      {
        header: 'Access Name',
        key: 'accessName',
      },
      { header: 'Survey', key: 'surveyName', width: 32 },
      {
        header: 'Code',
        key: 'code',
        width: 20,
      },
      {
        header: 'Segments',
        key: 'segments',
        width: 20,
      },
      {
        header: 'Language',
        key: 'language',
        width: 15,
      },
      {
        header: 'Link Name',
        key: 'linkName',
        width: 10,
      },
      {
        header: 'URL',
        key: 'url',
        width: 60,
      },
      {
        header: 'User  Group',
        key: 'subgroup',
      },
      {
        header: 'QR Code',
        key: 'qrcode',
        width: 30,
      },
    ];

    if (props && props.codeLinks) {
      const promise1 = Promise.all(
        props.codeLinks.map(
          async (
            codeLink: {
              resultId: number;
              accessId: number;
              accessName: string;
              subgroup: string;
              surveyName: string;
              code: string;
              language: string;
              linkName: string;
              url: string;
              segmentNames: string;
            },
            index: number,
          ) => {
            const rowNumber = index + 1;
            sheet.addRow({
              resultId: codeLink.resultId,
              accessId: codeLink.accessId,
              accessName: codeLink.accessName,
              subgroup: codeLink.subgroup,
              surveyName: codeLink?.surveyName,
              code: codeLink?.code,
              language: codeLink?.language,
              linkName: codeLink?.linkName,
              url: codeLink?.url,
              segments: JSON.parse(codeLink.segmentNames),
            });

            sheet.getRow(rowNumber + 1).height = 100;

            const base64Str = await generateQRCode(codeLink?.url);

            const imageId2 = workbook.addImage({
              base64: base64Str ?? '',
              extension: 'png',
            });

            sheet.addImage(imageId2, {
              tl: { col: 10, row: rowNumber },
              ext: { width: 100, height: 100 },
            });
          },
        ),
      );
      promise1.then(async () => {
        await workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'download.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      });
    }
  }, [generateQRCode, props]);

  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Name</h2>
        <Input type="text" name="name" placeholder="Enter the name" />

        <h2>Participant Group</h2>
        <Select
          isMulti
          name={'segments'}
          options={segments}
          onChange={handleSelectedSegments}
          value={selectedSegments}
          className="basic-multi-select"
        ></Select>

        <h2>Placeholder</h2>
        <div
          style={{
            margin: '1rem',
          }}
        >
          <div hidden={true}>
            <Input
              type="text"
              name="placeholders"
              value={JSON.stringify(rowsData)}
              placeholder="Enter the text to replace in the survey"
            />
            <Input
              type="text"
              name="segmentsAux"
              value={JSON.stringify(
                selectedSegments?.map((x: SelectProps) => {
                  return x.value;
                }),
              )}
              placeholder="Enter the text to replace in the survey"
            />
          </div>

          <PlaceholderContainer>
            <table>
              <thead>
                <th>Text to be replaced</th>
                <th>Text to replace</th>

                <button onClick={addTableRows} type={'button'}>
                  <AddIcon></AddIcon>
                </button>
              </thead>

              <tr></tr>
              <tbody>
                <TableRows
                  rowsData={rowsData}
                  deleteTableRows={deleteTableRows}
                  handleChange={handleChange}
                ></TableRows>
              </tbody>
            </table>
          </PlaceholderContainer>
        </div>
        <h2>Unlock/Lock</h2>
        <Switch
          name="isLocked"
          initialValue={isLocked}
          handleSwitchChange={handleSwitchChange}
          disabled={props.isTemplate}
        />
        {!props.isTemplate && (
          <>
            {props.initialData?.results && (
              <>
                <h2>Codes</h2>
                <BoxContainer>
                  <IptContainer>
                    <h3>Quantity of Codes</h3>
                    <Input
                      type="number"
                      name="numberCodes"
                      placeholder="Enter the number of codes to be generated"
                      width="80%"
                    />
                  </IptContainer>

                  <SelectContainer>
                    <h3>Select the language</h3>

                    <Select
                      options={props.languages}
                      name="language"
                      onChange={e => handleLanguageChange(e)}
                      defaultValue={selectedLanguage}
                    />
                  </SelectContainer>
                  <ButtonMd
                    variant="contained"
                    type="button"
                    onClick={props.handleCreateCode}
                  >
                    Generate
                  </ButtonMd>
                </BoxContainer>

                <Button
                  marginRight="35px"
                  width="150px"
                  height="40px"
                  onClick={exportToExcel}
                >
                  Download
                </Button>

                <Table
                  columnsProp={columns}
                  dataProp={codeLinks ?? []}
                  checkboxSelection={false}
                  tableColumnExtensions={tableColumnExtensions}
                  actionColumns={actionColumns}
                  hasFilterRow={false}
                  idName={'resultId'}
                  defaultPageSize={10}
                />
              </>
            )}
          </>
        )}

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormAccess;
