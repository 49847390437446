import React, { useState, useEffect, useCallback } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import api from '../../../services/api';

import Input from '../../../components/FormInput';
import Select from '../../../components/SelectForm';
import Button from '../../../components/Button';

import { Container } from './styles';

interface Customer {
  name: string;
  customerId: number;
  companyTypes: SelectProps;
  sectors: SelectProps;
  regions: SelectProps;
  employees: SelectProps;
  revenues: SelectProps;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: Customer;
  handleSubmit: SubmitHandler;
  customerData?: Customer;
}

interface SelectProps {
  value: number;
  label: string;
}

const FormCustomers: React.FC<Props> = props => {
  const [selectedCompanyType, setSelectedCompanyType] = useState<SelectProps>();
  const [selectedSector, setSelectedSector] = useState<SelectProps>();
  const [selectedRegion, setSelectedRegion] = useState<SelectProps>();
  const [selectedNumberOfEmployees, setSelectedNumberOfEmployees] =
    useState<SelectProps>();
  const [selectedRevenue, setSelectedRevenue] = useState<SelectProps>();

  const [companies, setCompanies] = useState<SelectProps[]>(); // Unternehmen
  const [sectors, setSectors] = useState<SelectProps[]>(); // Branche
  const [regions, setRegions] = useState<SelectProps[]>(); // Region
  const [employees, setEmployees] = useState<SelectProps[]>(); // Unternehmensgröße nach Beschäftigten
  const [revenues, setRevenues] = useState<SelectProps[]>(); // Umsatz pro Jahr

  useEffect(() => {
    api.get('customers/getAllDataDropDown').then(response => {
      if (response && response.data) {
        if (response.data.companies) {
          setCompanies(response.data.companies);
        }
        if (response.data.sectors) {
          setSectors(response.data.sectors);
        }
        if (response.data.regions) {
          setRegions(response.data.regions);
        }
        if (response.data.employees) {
          setEmployees(response.data.employees);
        }
        if (response.data.revenues) {
          setRevenues(response.data.revenues);
        }
      }
    });
    if (props.initialData) {
      setSelectedCompanyType(props.initialData.companyTypes);
      setSelectedSector(props.initialData.sectors);
      setSelectedRegion(props.initialData.regions);
      setSelectedNumberOfEmployees(props.initialData.employees);
      setSelectedRevenue(props.initialData.revenues);
    }
  }, [props.initialData]);

  const handleCompanyTypeChange = useCallback(selectedValue => {
    setSelectedCompanyType(selectedValue);
  }, []);

  const handleSectorChange = useCallback(selectedValue => {
    setSelectedSector(selectedValue);
  }, []);

  const handleRegionChange = useCallback(selectedValue => {
    setSelectedRegion(selectedValue);
  }, []);

  const handleEmployeesChange = useCallback(selectedValue => {
    setSelectedNumberOfEmployees(selectedValue);
  }, []);

  const handleRevenueChange = useCallback(selectedValue => {
    setSelectedRevenue(selectedValue);
  }, []);

  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Name</h2>
        <Input type="text" name="name" placeholder="Enter customer name" />

        {/* <h2>Unternehmen (Company)</h2> */}
        <h2>Company</h2>
        <Select
          name="company"
          options={companies}
          onChange={e => handleCompanyTypeChange(e)}
          value={selectedCompanyType}
        />

        {/* <h2>Branche (sector)</h2> */}
        <h2>Sector</h2>
        <Select
          name="sector"
          options={sectors}
          onChange={e => handleSectorChange(e)}
          value={selectedSector}
        />

        <h2>Region</h2>
        <Select
          name="region"
          options={regions}
          onChange={e => handleRegionChange(e)}
          value={selectedRegion}
        />
        {/*
        <h2>
          Unternehmensgröße nach Beschäftigten (EU-Definition) (Number of
          employees)
        </h2> */}

        <h2>Number of employees</h2>
        <Select
          name="employee"
          options={employees}
          onChange={e => handleEmployeesChange(e)}
          value={selectedNumberOfEmployees}
        />

        {/* <h2>Umsatz pro Jahr (EU-Definition) (Revenues)</h2> */}
        <h2>Revenues</h2>

        <Select
          name="revenue"
          options={revenues}
          onChange={e => handleRevenueChange(e)}
          value={selectedRevenue}
        />

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormCustomers;
