import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormTag from '../Form';

interface Rule {
  schlagwort: string;
  standalone: string;
  negative_signaling_keyword: string;
  only_Q_List_Befragung: string;
  non_Q_List_Befragung: string;
}
interface Tag {
  category: string;
  category_2: string;
  type: string;
  rules: Rule[];
}

interface ParamTypes {
  tagId: string;
}

const EditTag: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { tagId } = useParams<ParamTypes>();

  const [rules, setRules] = useState<Rule[]>([]);

  useEffect(() => {
    // const tagList: any[] = [];
    const loadData = async (): Promise<void> => {
      await api.get(`/tags/${tagId}`).then(response => {
        formRef.current?.setFieldValue('category', response.data.category);

        formRef.current?.setFieldValue('category_2', response.data.category_2);

        formRef.current?.setFieldValue('type', {
          value: response.data.type,
          label: response.data.type,
        });

        if (response.data && response.data.rules) setRules(response.data.rules);
      });
    };

    loadData();
  }, [tagId]);
  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          category: Yup.string().required('The category is required'),
          category_2: Yup.string().required('The category Parent is required'),
          type: Yup.string().required('The type is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const rulesData = JSON.parse(data.rules);

        if (!rulesData || rulesData.length === 0) {
          addToast({
            type: 'error',
            title: 'Registration Error',
            description: 'The category needs at least one rule associated.',
          });
        } else {
          const InvalidRulles = rulesData.filter(
            (x: Rule) => x.schlagwort === '',
          );

          const InvalidRullesStandalone = rulesData.filter(
            (x: Rule) => x.standalone !== '' && x.standalone !== '1',
          );

          const schlagworts = rulesData.map((rule: any) => rule.schlagwort);
          const rulesLength = schlagworts.length;
          const setRulesLength = new Set(schlagworts).size;

          if (InvalidRulles.length > 0) {
            addToast({
              type: 'error',
              title: 'Registration Error',
              description:
                'Schlagwort is not optional. Please fill in this field before saving.',
            });
          } else if (InvalidRullesStandalone.length > 0) {
            addToast({
              type: 'error',
              title: 'Registration Error',
              description: 'Standalone must be 1 or empty',
            });
          } else if (rulesLength !== setRulesLength) {
            addToast({
              type: 'error',
              title: 'Registration Error',
              description: 'Schlagwort duplicated, please remove one of them',
            });
          } else {
            const tag: Tag = {
              category: data.category,
              category_2: data.category_2,
              type: data.type,
              rules: rulesData,
            };

            await api.put(`/tags/${tagId}`, tag);

            const redirectUrl = '/tags';

            history.push(redirectUrl);

            addToast({
              type: 'success',
              title: 'Success',
              description: 'The category was edited successfully!',
            });
          }
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating the segment, please try again.',
        });
      }
    },
    [addToast, history, tagId],
  );

  return (
    <>
      <h1>Edit Category</h1>

      <FormTag
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Edit"
        initialData={rules}
      />
    </>
  );
};

export default EditTag;
