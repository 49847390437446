import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Questions from '../pages/Questions/index';
import QuestionTranslations from '../pages/Questions/Translations';
import ImportFile from '../pages/Questions/ImportFile';
import ImportSample from '../pages/Accesses/ImportSample';
import CreateQuestion from '../pages/Questions/New';
import EditQuestion from '../pages/Questions/Edit';
import LanguageSettings from '../pages/LanguageSettings';
import Surveys from '../pages/Surveys';
import CreateSurvey from '../pages/Surveys/New';
import EditSurvey from '../pages/Surveys/Edit';
import Survey from '../pages/Questionnaire';
import MoreInfo from '../pages/Questionnaire/Start/InfoText/index';
import Products from '../pages/Products';
import SurveyTemplate from '../pages/Products/SurveyTemplate';
import SurveyTemplateDemo from '../pages/Products/SurveyTemplate/Demo';
import CreateProduct from '../pages/Products/New';
import CreateStructure from '../pages/Products/Structure';
import TemplateDecision from '../pages/Products/TemplateDecision';
import CreateProject from '../pages/Projects/New';
import EditProject from '../pages/Projects/Edit';
import Projects from '../pages/Projects';
import ProjectDashboard from '../pages/Projects/Dashboard';
import ProjectParticipation from '../pages/Projects/Participation';
import ProjectAnalytics from '../pages/Projects/Analytics';
import ProjectOpenAnswers from '../pages/Projects/OpenAnswers';
import Customers from '../pages/Customers';
import Languages from '../pages/Languages';
import CreateCustomer from '../pages/Customers/New';
import EditCustomer from '../pages/Customers/Edit';
import MatrixList from '../pages/MatrixQuestions';
import CreateMatrixStep1 from '../pages/MatrixQuestions/New/Step1';
import CreateMatrixStep2 from '../pages/MatrixQuestions/New/Step2';
import SurveyTexts from '../pages/SurveyTexts';
import CreateSurveyText from '../pages/SurveyTexts/New';
import EditSurveyText from '../pages/SurveyTexts/Edit';
import Logos from '../pages/Logos';
import CreateLogo from '../pages/Logos/New';

import Images from '../pages/Images';
import UploadImages from '../pages/Images/New';

import Accesses from '../pages/Accesses';
import CreateAccess from '../pages/Accesses/New';
import EditAccess from '../pages/Accesses/Edit';
import SearchAccess from '../pages/Accesses/Search';
import ExportResult from '../pages/Surveys/ExportResult';
import OpenAnswers from '../pages/Surveys/OpenAnswers';
import ExportResultGeneric from '../pages/ExportResultGeneric';
import Analytics from '../pages/Surveys/Analytics';

import Style from '../pages/Surveys/Style';
import AfterSurvey from '../pages/Questionnaire/AfterSurvey/index';

import Respondents from '../pages/Respondents';
import CreateLanguage from '../pages/Languages/New';
import EditLanguage from '../pages/Languages/Edit';

import SurveyTranslations from '../pages/Surveys/SurveyTranslations';
import EditSurveyTranslations from '../pages/Surveys/SurveyTranslations/Edit';
import CreateSurveyTranslation from '../pages/Surveys/SurveyTranslations/New';

import GDPR from '../pages/GDPR';
import CreateGDPR from '../pages/GDPR/New';
import EditGDPR from '../pages/GDPR/Edit';

import Segments from '../pages/Segments';
import CreateSegment from '../pages/Segments/New';

import Categories from '../pages/Categories';
import CreateCategories from '../pages/Categories/New';

import ExportToPdf from '../pages/Surveys/ExportToPdf';
import ExportQuestionnaireToPdf from '../pages/Questionnaire/ExportToPdf';

import Logs from '../pages/Logs';
import LogsQuestion from '../pages/Logs/Questions';
import LogsAnswers from '../pages/Logs/Answers';

import Participation from '../pages/ParticipationCustomer';

import ParticipationLink from '../pages/ParticipationLink';
import CreateParticipationLink from '../pages/ParticipationLink/New';
import EditParticipationLink from '../pages/ParticipationLink/Edit';

import ExportDataWarehouse from '../pages/Surveys/ExportDataWarehouse';
import RunAnalyser from '../pages/Surveys/RunAnalyser';

import Tags from '../pages/Tags';
import TagTranslations from '../pages/TagTranslations';

import ImportTagTranslations from '../pages/TagTranslations/new';

import CreateTag from '../pages/Tags/New';
import EditTag from '../pages/Tags/Edit';

import AnalyticsPageLinks from '../pages/AnalyticsPage';
import AnalyticsPageNew from '../pages/AnalyticsPage/New';
import AnalyticsPageEdit from '../pages/AnalyticsPage/Edit';

import Apillares from '../pages/Apillares';
import CreateApillar from '../pages/Apillares/New';
import EditApillar from '../pages/Apillares/Edit';

import AccessGroup from '../pages/AccessGroup';
import CreateAccessGroup from '../pages/AccessGroup/New';
import EditAccessGroup from '../pages/AccessGroup/Edit';
import Videos from '../pages/Videos';
import UploadVideos from '../pages/Videos/New';

import SurveyListToExport from '../pages/Surveys/ExportDataWarehouse/SurveyListToExport/index';
import RedirectionOldLinks from '../pages/Questionnaire/RedirectionOldLinks';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/questions" exact component={Questions} isPrivate />
    <Route path="/questions/new" component={CreateQuestion} isPrivate />
    <Route
      path="/questions/edit/:questionId"
      component={EditQuestion}
      isPrivate
    />
    <Route
      path="/questions/translations"
      exact
      component={QuestionTranslations}
      isPrivate
    />
    <Route
      path="/questions/importFile"
      exact
      component={ImportFile}
      isPrivate
    />

    <Route path="/languageSettings" component={LanguageSettings} isPrivate />

    <Route path="/surveys" exact component={Surveys} isPrivate />

    <Route
      path="/surveys/project/:projectId"
      exact
      component={Surveys}
      isPrivate
    />
    <Route path="/surveys/new" component={CreateSurvey} isPrivate />
    <Route path="/surveys/:surveyId" exact component={EditSurvey} isPrivate />
    <Route
      path="/surveys/:surveyId/accesses"
      exact
      component={Accesses}
      isPrivate
    />
    <Route
      path="/surveys/:surveyId/accesses/new"
      exact
      component={CreateAccess}
      isPrivate
    />
    <Route
      path="/surveys/:surveyId/accesses/edit/:accessId"
      exact
      component={EditAccess}
      isPrivate
    />

    <Route path="/accesses/search" exact component={SearchAccess} isPrivate />

    <Route
      path="/accesses/importSample"
      exact
      component={ImportSample}
      isPrivate
    />

    <Route
      path="/surveys/:surveyId/export"
      exact
      component={ExportResult}
      isPrivate
    />

    <Route
      path="/surveys/:surveyId/answers"
      exact
      component={OpenAnswers}
      isPrivate
    />

    <Route
      path="/surveys/:surveyId/runAnalyser"
      exact
      component={RunAnalyser}
      isPrivate
    />

    <Route
      path="/ExportResultGeneric"
      exact
      component={ExportResultGeneric}
      isPrivate
    />
    <Route
      path="/surveys/:surveyId/surveyTranslations"
      component={SurveyTranslations}
      isPrivate
    />
    <Route
      path="/surveys/surveyTranslations/new"
      exact
      component={CreateSurveyTranslation}
      isPrivate
    />
    <Route
      path="/surveys/surveyTranslations/edit/:surveyTranslationId"
      exact
      component={EditSurveyTranslations}
      isPrivate
    />
    <Route
      path="/surveys/:surveyId/analytics"
      exact
      component={Analytics}
      isSurvey
    />

    <Route path="/style" exact component={Style} isSurvey isPrivate />

    <Route
      path="/projects/:projectId/analytics"
      exact
      component={ProjectAnalytics}
      isPrivate
    />

    <Route
      path="/projects/:projectId/openAnswers"
      exact
      component={ProjectOpenAnswers}
      isPrivate
    />

    <Route
      path="/questionnaire/:accessObjectId/:accessId/:code?"
      component={Survey}
      isSurvey
    />

    <Route
      path="/questionnaire/:accessId/:code?"
      component={RedirectionOldLinks}
      isSurvey
    />

    <Route path="/aftersurvey" exact component={AfterSurvey} isSurvey />

    <Route path="/moreinfo/" exact component={MoreInfo} isSurvey />

    <Route path="/projects/new" component={CreateProject} isPrivate />

    <Route path="/projects/edit/:projectId" component={EditProject} isPrivate />

    <Route path="/projects" exact component={Projects} isPrivate />

    <Route
      path="/projects/:projectId/dashboard"
      exact
      component={ProjectDashboard}
      isPrivate
    />
    <Route
      path="/projects/:projectId/participation"
      exact
      component={ProjectParticipation}
      isPrivate
    />

    <Route path="/products" exact component={Products} isPrivate />
    <Route
      path="/products/surveyTemplate"
      exact
      component={SurveyTemplate}
      isPrivate
    />
    <Route
      path="/products/surveyTemplate/:surveyId"
      exact
      component={SurveyTemplateDemo}
      isSurvey
    />
    <Route
      path="/products/templateDecision"
      exact
      component={TemplateDecision}
      isPrivate
    />
    <Route path="/products/new" component={CreateProduct} isPrivate />
    <Route
      path={['/products/structure', '/products/structure/:productId']}
      exact
      component={CreateStructure}
      isPrivate
    />

    <Route path="/customers" exact component={Customers} isPrivate />
    <Route path="/customers/new" component={CreateCustomer} isPrivate />
    <Route path="/customers/edit/:id" component={EditCustomer} isPrivate />

    <Route path="/languages" exact component={Languages} isPrivate />
    <Route path="/languages/new" component={CreateLanguage} isPrivate />
    <Route path="/languages/edit/:id" component={EditLanguage} isPrivate />

    <Route path="/matrixQuestions" exact component={MatrixList} isPrivate />
    <Route
      path="/matrixQuestions/step1"
      exact
      component={CreateMatrixStep1}
      isPrivate
    />
    <Route
      path={['/matrixQuestions/step2', '/matrixQuestions/step2/:productId']}
      exact
      component={CreateMatrixStep2}
      isPrivate
    />

    <Route
      path={['/surveyTexts', '/surveyTexts/type/:textType?']}
      exact
      component={SurveyTexts}
      isPrivate
    />
    <Route path="/surveyTexts/new" component={CreateSurveyText} isPrivate />
    <Route path="/surveyTexts/edit/:id" component={EditSurveyText} isPrivate />

    <Route path="/gdpr" exact component={GDPR} isPrivate />
    <Route path="/gdpr/new" component={CreateGDPR} isPrivate />
    <Route path="/gdpr/edit/:id" component={EditGDPR} isPrivate />

    <Route path="/segments" exact component={Segments} isPrivate />
    <Route path="/segments/new" component={CreateSegment} isPrivate />

    <Route path="/categories" exact component={Categories} isPrivate />
    <Route path="/categories/new" component={CreateCategories} isPrivate />

    <Route path="/tags" exact component={Tags} isPrivate />
    <Route path="/tags/new" component={CreateTag} isPrivate />
    <Route path="/tags/edit/:tagId" component={EditTag} isPrivate />

    <Route path="/apillars" exact component={Apillares} isPrivate />
    <Route path="/apillars/new" component={CreateApillar} isPrivate />
    <Route path="/apillars/edit/:id" component={EditApillar} isPrivate />

    <Route path="/accessGroup" exact component={AccessGroup} isPrivate />
    <Route path="/accessGroup/new" component={CreateAccessGroup} isPrivate />
    <Route path="/accessGroup/edit/:id" component={EditAccessGroup} isPrivate />

    <Route
      path="/tagTranslations"
      exact
      component={TagTranslations}
      isPrivate
    />

    <Route
      path="/tagTranslations/new"
      exact
      component={ImportTagTranslations}
      isPrivate
    />

    <Route path="/logos" exact component={Logos} isPrivate />
    <Route path="/logos/new" component={CreateLogo} isPrivate />

    <Route path="/Images" exact component={Images} isPrivate />
    <Route path="/Images/new" component={UploadImages} isPrivate />

    <Route path="/Videos" exact component={Videos} isPrivate />
    <Route path="/Videos/new" component={UploadVideos} isPrivate />

    <Route path="/respondents" exact component={Respondents} isPrivate />

    <Route path="/exportToPdf" exact component={ExportToPdf} isSurvey />

    <Route
      path="/exportQuestionnaireToPdf/:accessId"
      exact
      component={ExportQuestionnaireToPdf}
      isSurvey
    />

    <Route path="/Logs" exact component={Logs} isPrivate />
    <Route path="/Logs/Questions" component={LogsQuestion} isPrivate />
    <Route path="/Logs/Answers" component={LogsAnswers} isPrivate />

    <Route path="/participation/:id/:code" component={Participation} isSurvey />

    <Route
      path="/participationLink"
      exact
      component={ParticipationLink}
      isPrivate
    />
    <Route
      path="/participationLink/new"
      component={CreateParticipationLink}
      isPrivate
    />
    <Route
      path="/participationLink/edit/:id"
      component={EditParticipationLink}
      isPrivate
    />
    <Route path="/analytics" exact component={AnalyticsPageLinks} isPrivate />

    <Route path="/analyticsLink/new" component={AnalyticsPageNew} isPrivate />

    <Route
      path="/analyticsLink/edit/:id"
      component={AnalyticsPageEdit}
      isPrivate
    />

    <Route
      path="/exportDatawarehouse"
      exact
      component={SurveyListToExport}
      isPrivate
    />
  </Switch>
);

export default Routes;
