import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormCategory from '../Form';

interface Category {
  categoryId: number;
  title: string;
  type: string;
}

const CreateCategory: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: Category) => {
      try {
        formRef.current?.setErrors({});

        const category = {
          title: data.title,
          type: data.type,
        };
        const schema = Yup.object().shape({
          title: Yup.string().required('The title is required'),
          type: Yup.string().required('The type is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/categories', category);

        const redirectUrl = '/categories';

        history.push(redirectUrl);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The category was created successfully!',
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating the category, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <h1>New Category</h1>

      <FormCategory
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
      />
    </>
  );
};

export default CreateCategory;
