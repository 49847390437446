import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Survey from 'survey-react';
import ReactToPrint from 'react-to-print';
import { Container } from './styles';
import ButtonEb from '../../../components/Button';
import 'survey-react/modern.css';

import '../../../components/SurveyComponent/SurveyCustomWidgets/styles/fontawesome-stars.css';
// import 'emotion-ratings/dist/emotion-ratings.js';

import { EbSlider } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbSlider';
import { EbSliderPolarity } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbSliderPolarity';
import { EbVerticalPolarity } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbVerticalPolarity';
import { EbRating } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbRating';
import { EbStyledRating } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbStyledRating';
import { EbEmoticons } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbEmoticons';
import { EbEmoticons2 } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbEmoticons2';

import BackButton from '../../../components/BackButton';

Survey.StylesManager.applyTheme('modern');

Survey.Serializer.addProperty('question', 'questionId:number');
Survey.Serializer.addProperty('question', 'internalType:string');
Survey.Serializer.addProperty('question', 'matrixId:number');
Survey.Serializer.addProperty('question', {
  name: 'orderChoices:dropdown',
  category: 'general',
  choices: ['asc value', 'desc value'],
});

Survey.CustomWidgetCollection.Instance.add(EbRating(Survey), 'rating');
Survey.CustomWidgetCollection.Instance.add(EbSlider(Survey), 'customtype');
Survey.CustomWidgetCollection.Instance.add(
  EbSliderPolarity(Survey),
  'customtype',
);
Survey.CustomWidgetCollection.Instance.add(
  EbVerticalPolarity(Survey),
  'customtype',
);
Survey.CustomWidgetCollection.Instance.add(EbEmoticons(Survey), 'customtype');
Survey.CustomWidgetCollection.Instance.add(EbEmoticons2(Survey), 'customtype');
Survey.CustomWidgetCollection.Instance.add(
  EbStyledRating(Survey),
  'customtype',
);

interface State {
  surveyCreatorText: string;
}

const ExportToPdf: React.FC = () => {
  const { state } = useLocation<State>();
  const pdfRef = useRef(null);
  const [model, setModel] = useState<Survey.SurveyModel>(
    new Survey.SurveyModel(state.surveyCreatorText),
  );

  useEffect(() => {
    const element1 = document.getElementsByClassName(
      'sv-action-bar sv-action-bar--default-size-mode sv-footer sv-body__footer sv-clearfix',
    );
    element1[0]?.remove();

    const element = document.getElementById('sv-nav-complete');
    element?.remove();
  }, []);

  return (
    <Container>
      {<BackButton />}

      <Survey.Survey ref={pdfRef} model={model} showProgressBar="top" />

      <ReactToPrint
        content={() => pdfRef.current}
        trigger={() => (
          <ButtonEb marginTop={'150px'} marginBottom={'100px'}>
            Print to PDF!
          </ButtonEb>
        )}
      />
    </Container>
  );
};
export default ExportToPdf;
