/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Link } from 'react-router-dom';

import { ListAltOutlined, EditOutlined } from '@material-ui/icons';

import { FiCheckCircle, FiLock, FiUnlock } from 'react-icons/fi';

import { RiFilePdfLine } from 'react-icons/ri';
import DuplicateButton from './duplicate';

import { StyledTooltip } from './styles';
import DeleteButton from './delete';

export const columns: any[] = [
  {
    name: 'accessId',
    title: 'ID',
  },
  {
    name: '_id',
    title: 'Object Id',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'subgroup',
    title: 'User Group',
  },

  {
    name: 'segments',
    title: 'Segments',
    width: 120,
    getCellValue: (row: any) =>
      row.segments && row.segments.length > 0 ? (
        <>
          <StyledTooltip
            title={row.segments.map((t: string, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <div>
              <ListAltOutlined />
            </div>
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  {
    name: 'status',
    title: 'Status',
    width: 120,
    filter: false,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          {row.isLocked ? (
            <FiLock size={22} color={'#ff0000'} />
          ) : (
            <FiUnlock size={22} color={'#007f32'} />
          )}
        </>
      );

      return iconProperties;
    },
  },
  {
    name: 'finalLink',
    title: 'Final Link',
    width: 120,
  },
  // {
  //   name: 'finalLink',
  //   title: 'Final Link',
  //   width: 200,
  //   getCellValue: (row: any) => {
  //     const iconProperties = (
  //       <>
  //         <ButtonFinalLink type="button" onClick={row.clickFinalLinkFunction}>
  //           <FiLink size={22} color={'#ff0000'} />
  //         </ButtonFinalLink>

  //         {/* <Link to={`/questionnaire/${row.accessId}`} target="_blank">
  //           <FiLink size={22} color={'#ff0000'} />
  //         </Link> */}
  //       </>
  //     );

  //     return iconProperties;
  //   },
  // },
  {
    name: 'testLink',
    title: 'Test Link',
    width: 120,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <Link
            to={`/questionnaire/${row._id}/${row.accessId}?test=true`}
            target="_blank"
          >
            <FiCheckCircle size={22} color={'#007f32'} />
          </Link>
        </>
      );

      return iconProperties;
    },
  },
  {
    name: 'exportToPDF',
    title: 'Export To PDF',
    width: 120,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <Link
            to={`/exportQuestionnaireToPdf/${row.accessId}?test=true`}
            target="_blank"
          >
            <RiFilePdfLine size={22} color={'#FF0000'} />
          </Link>
        </>
      );

      return iconProperties;
    },
  },
  {
    name: 'duplicateAccess',
    title: 'Duplicate',
    width: 120,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <DuplicateButton
            access={{
              surveyId: row.surveyId,
              name: row.name,
              segments: row.segments,
              isLocked: row.isLocked,
              placeholders: row.placeholders,
            }}
          ></DuplicateButton>
        </>
      );

      return iconProperties;
    },
  },
  {
    name: 'edit',
    title: ' ',
    width: 200,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <Link
            to={{
              pathname: `/surveys/${row.surveyId}/accesses/edit/${row.accessId}`,
              state: {
                surveyName: row.surveyName,
                subgroup: row.subgroup,
              },
            }}
          >
            <EditOutlined />
          </Link>
        </>
      );

      return iconProperties;
    },
  },
  {
    name: 'delete',
    title: ' ',
    width: 200,
    getCellValue: (row: any) => {
      const iconProperties = (
        <>
          <DeleteButton
            access={{
              accessId: row.accessId,
              surveyId: row.surveyId,
              name: row.name,
              segments: row.segments,
              isLocked: row.isLocked,
            }}
          ></DeleteButton>
        </>
      );

      return iconProperties;
    },
  },
];
