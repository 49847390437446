import { ListAltOutlined } from '@material-ui/icons';
import { StyledTooltip } from './styles';

export const columns: any[] = [
  {
    name: 'accessGroupId',
    title: 'ID',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'accessIds',
    title: 'Access ID',
    getCellValue: (row: any) =>
      row.accessIds && row.accessIds.length > 0 ? (
        <>
          <StyledTooltip
            title={row.accessIds.map((t: number, i: number) => {
              return <li key={i}>{t}</li>;
            })}
          >
            <ListAltOutlined />
          </StyledTooltip>
        </>
      ) : (
        <></>
      ),
  },
  { name: 'edit', title: '' },
  { name: 'delete', title: '' },
];
