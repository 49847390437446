import React from 'react';

import {
  CheckMark,
  MainContainer,
  StepContainer,
  StepCount,
  StepStyle,
  StepWrapper,
  StepsLabelContainer,
  StepLabel,
} from './styles';

interface Step {
  step: number;
  label: string;
}

interface TooltipProps {
  activeStep: number;
  steps: Step[];
}

const ProgressStepBar: React.FC<TooltipProps> = ({ steps, activeStep }) => {
  const totalSteps = steps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map(({ step, label }) => (
          <StepWrapper key={step}>
            <StepStyle step={activeStep >= step ? 'completed' : 'incomplete'}>
              {activeStep >= step ? (
                <CheckMark>L</CheckMark>
              ) : (
                <StepCount>{step}</StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              <StepLabel key={step}>{label}</StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
    </MainContainer>
  );
};

export default ProgressStepBar;
