import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import Input from '../FormInput';

import {
  PositionRelative,
  SelectParent,
  SelectContainer,
  SelectItem,
} from './styles';

interface AutocompleteInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  styled?: boolean;
  show?: boolean;
  suggestion: string[];
}
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, setFilteredSuggestion: any): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any): void {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilteredSuggestion([]);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setFilteredSuggestion]);
}

function elementIsVisibleOnParent(
  scrollToElementRef: React.MutableRefObject<HTMLDivElement | null>,
): boolean {
  const el = scrollToElementRef?.current;
  const rect = el?.getBoundingClientRect();
  const elemTop = rect?.top;
  const elemBottom = rect?.bottom;

  const parent = el?.parentElement;
  const parentRect = parent?.getBoundingClientRect();
  const parentTop = parentRect?.top;
  const parentBottom = parentRect?.bottom;

  if (elemTop && elemBottom && parentTop && parentBottom) {
    return elemTop >= parentTop && elemBottom <= parentBottom;
  }
  return false;
}

const AutoCompleteInput: React.FC<AutocompleteInputProps> = ({
  name,
  show,
  styled,
  suggestion,
  ...rest
}) => {
  const [filteredSuggestion, setFilteredSuggestion] = useState<string[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>('');
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setFilteredSuggestion);

  const scrollToElementRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const isVisible = elementIsVisibleOnParent(scrollToElementRef);

    if (!isVisible || activeSuggestionIndex % 6 === 0) {
      scrollToElementRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [activeSuggestionIndex]);

  const onChange = useCallback(
    async e => {
      const userInput = e.currentTarget.value;

      // Filter our suggestions that don't contain the user's input
      const filtered = suggestion.filter(
        (s: any) => s.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
      );

      setFilteredSuggestion(filtered);
      setActiveSuggestionIndex(0);
    },
    [suggestion],
  );

  const showSuggestions = useCallback(
    async e => {
      const userInput = e.target.value;
      const filteredSuggestions = suggestion.filter(
        (s: any) => s.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
      );

      setFilteredSuggestion(filteredSuggestions);
    },
    [suggestion],
  );

  const onClickSuggestionItem = useCallback(async e => {
    setFilteredSuggestion([]);
    setSelectedSuggestion(e.currentTarget.innerText);
    setActiveSuggestionIndex(0);
  }, []);

  const onKeyDown = useCallback(
    async e => {
      // User pressed the enter key
      if (e.keyCode === 13) {
        setSelectedSuggestion(filteredSuggestion[activeSuggestionIndex]);
        setActiveSuggestionIndex(0);
        setFilteredSuggestion([]);
        e.preventDefault();
      }
      // User pressed the up arrow
      else if (e.keyCode === 38) {
        if (activeSuggestionIndex === 0) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
      // User pressed the down arrow
      else if (e.keyCode === 40) {
        if (activeSuggestionIndex + 1 === filteredSuggestion.length) {
          return;
        }

        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    },
    [activeSuggestionIndex, filteredSuggestion],
  );

  return (
    <>
      <Input
        type="text"
        name={name}
        placeholder="Enter the category"
        onChange={onChange}
        suggestions={selectedSuggestion}
        onClick={showSuggestions}
        onKeyDown={onKeyDown}
      />
      {filteredSuggestion.length > 0 && (
        <>
          <PositionRelative ref={wrapperRef}>
            <SelectContainer>
              <SelectParent>
                {filteredSuggestion.map((s, index) => {
                  return (
                    <SelectItem
                      key={s}
                      onClick={onClickSuggestionItem}
                      isActive={index === activeSuggestionIndex}
                      ref={
                        index === activeSuggestionIndex
                          ? scrollToElementRef
                          : null
                      }
                    >
                      {s}
                    </SelectItem>
                  );
                })}
              </SelectParent>
            </SelectContainer>
          </PositionRelative>
        </>
      )}
    </>
  );
};

export default AutoCompleteInput;
