/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const columns: any[] = [
  {
    name: 'surveyId',
    title: 'ID',
  },
  {
    name: 'projectCustomerName',
    title: 'Customer',
  },
  {
    name: 'year',
    title: 'Year',
  },
  {
    name: 'projectName',
    title: 'Project',
  },
  {
    name: 'name',
    title: 'Name',
  },
];
