/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../services/api';

import getQuestionsWithTranslations from './getQuestionsWithTranslations';

const getSurveyTransations = async (idSurvey: number): Promise<any[]> => {
  const surveyTranslations: any[] = [];
  const response = await api.get(
    `/surveyTranslations/translations/${idSurvey}`,
  );

  response.data.forEach((surveyTranslation: any) => {
    surveyTranslations.push(surveyTranslation);
  });
  return surveyTranslations;
};

const updateSurveyTranslations = async (
  surveyEncoded: any,
  idSurvey: number,
): Promise<any> => {
  const questionWithTranslations = await getQuestionsWithTranslations(
    surveyEncoded,
  );

  const surveyTranslations = await getSurveyTransations(idSurvey);

  // loop surveyEncoded because it can have new elements
  // for each surveyTranslation we need to find by surveyId and then merge it with surveyTranslation found

  surveyTranslations.forEach(async (surveyTranslation: any) => {
    const responseLanguage = await api.get(
      `languages/${surveyTranslation.languageId}`,
    );
    const requiredErrorText =
      responseLanguage.data.requiredErrorText ?? 'Bitte eine Antwort geben';

    const placeHolderTexQuestion =
      responseLanguage.data?.placeholderTextQuestion ??
      'Tippen Sie Ihre Antwort bitte hier ein:';

    const requiredTextForAllRows =
      responseLanguage.data?.requiredAllrowsErrorText ??
      'Bitte beantworten Sie alle Fragen';

    const pagesTranslated = surveyEncoded.pages.map((page: any) => {
      let pageTranslated: any = {};
      // keep element's properties that were changed in survey creator and
      // add new elements that were added by skeleton (keeping properties that already exists)
      const elementsTranslated = page.elements.map((element: any) => {
        let questionWithTranslation: any = {};
        let elementTranslated: any = {};
        let questionTranslated: any = {};
        let rowsTranslated: any = {};
        let columnsTranslated: any = {};
        let elementsFlat: any[] = [];

        let translation: any = {};
        let questionIdToSearch = '';
        let alternativeId = '';
        let questionIdSplitted: string[];
        let alternativeText = '';
        let strQuyestionId = '';
        // create element translated for each element of the survey
        switch (element.type) {
          case 'dropdown':
          case 'ranking':
          case 'radiogroup':
          case 'barrating':
          case 'ebemotionsratings':
          case 'funnyemotionsratings':
          case 'rating':
          case 'ebslider':
          case 'ebsliderpolarity':
          case 'ebverticalpolarity':
          case 'comment':
          case 'text':
          case 'checkbox':
          case 'ebdragndrop':
            translation = {};
            questionIdToSearch = '';
            alternativeId = '';
            questionIdSplitted = [];
            alternativeText = '';
            strQuyestionId = '';
            strQuyestionId = element.questionId.toString();
            questionIdToSearch = strQuyestionId;
            const translatedOptionsToInsert: any = [];

            let translatedQuestionsFlat;
            let descriptionTranslated = '';
            if (element.description) {
              translatedQuestionsFlat =
                surveyTranslation.translation.pages.flatMap(
                  ({ elements }: any) =>
                    elements?.flatMap((elementFlat: any) => ({
                      ...elementFlat,
                    })),
                );
              // eslint-disable-next-line no-case-declarations
              const questonTranslatedAlreadySaved =
                translatedQuestionsFlat.find(
                  (elementFlat: any) =>
                    elementFlat?.questionId === element?.questionId,
                );

              descriptionTranslated =
                questonTranslatedAlreadySaved?.description;
            }

            questionWithTranslation = questionWithTranslations.find(
              (question: any) => question.questionId == questionIdToSearch,
            );

            if (strQuyestionId.includes(`.`)) {
              questionIdSplitted = strQuyestionId.split('.');
              questionIdToSearch = questionIdSplitted[0];
              alternativeId = questionIdSplitted[1];

              questionWithTranslation = questionWithTranslations.find(
                (question: any) => question.questionId == questionIdToSearch,
              );

              translation = questionWithTranslation.translations.find(
                (questionTranslation: any) =>
                  questionTranslation.languageId ===
                  surveyTranslation.languageId,
              );
              const alternativeTextFound = translation?.alternatives?.find(
                (alternative: any) => alternative.value == alternativeId,
              );

              alternativeText = alternativeTextFound
                ? alternativeTextFound.text
                : questionWithTranslation.text;
            }

            if (
              questionWithTranslation &&
              questionWithTranslation?.translations
            ) {
              translation = questionWithTranslation.translations.find(
                (questionTranslation: any) =>
                  questionTranslation.languageId ===
                  surveyTranslation.languageId,
              );
            }

            if (element.choices && element.choices.length > 0) {
              // eslint-disable-next-line array-callback-return
              element?.choices?.map((elementChoice: any) => {
                const transOption = translation?.options.find(
                  (translationOption: any) => {
                    return elementChoice.value === translationOption.value;
                  },
                );
                translatedOptionsToInsert.push(transOption);
              });
            }

            elementTranslated = {
              ...element,
              description:
                !descriptionTranslated || descriptionTranslated === ''
                  ? element.description
                  : descriptionTranslated,
              title:
                alternativeText && alternativeText != ''
                  ? alternativeText
                  : translation?.text
                  ? translation?.text
                  : questionWithTranslation?.text,
              choices:
                translatedOptionsToInsert &&
                translatedOptionsToInsert.length > 0
                  ? translatedOptionsToInsert
                  : translation?.options,
              rateValues:
                translatedOptionsToInsert &&
                translatedOptionsToInsert.length > 0
                  ? translatedOptionsToInsert
                  : translation?.options,
            };

            if (element.type === 'text' || element.type === 'comment') {
              delete elementTranslated.choices;
              delete elementTranslated.rateValues;
            }

            if (element.isRequired) {
              elementTranslated.requiredErrorText = requiredErrorText;
              elementTranslated.requiredText = ''; // remove * required text from question title (required Text)
            }

            if (element.internalType === 'Text') {
              elementTranslated = {
                ...elementTranslated,
                placeHolder: placeHolderTexQuestion,
              };
            }

            break;
          case 'matrix':
            // case 2
            // se a question ma matrix do survey german tiver mais options que o survey traduzido
            // esse caso não vai acontecer, prq lá no skeleton eu não vou deixar o cara adicionar uma question que tem options faltando na translation. se tiver option sem tradução nao deixa adicionar em matrix.
            // ------além de verificar se tá faltando option (ver se a quantidade de options é diferente das já adicionadas)
            // ------rodar todas as translations das questions já adicionadas, comparar com a question que está sendo adicionada. Se alguma já adicionada tiver mais traduções do que a que está sendo add, bloqueia
            // ------ou se a que está sendo adicionada tiver quanidade de options ou options diferentes das que já foram add, bloqueia.
            // se a question do survey german tiver mais options que o survey traduzido: pega as traduções e coloca as options a mais no survey traduzido (em german mesmo). onde o cara vai arrumar isso, lá no cadastro de question.

            // caso 3
            // a matrix já existe no survey e o usuároo remove uma option somente de uma das questions da matrix. vai dar problema porque as questions da matrix
            // ficarão com quantidade de options diferentes no survey principal (german).

            elementsFlat = surveyTranslation.translation.pages.flatMap(
              ({ elements }: any) =>
                elements?.flatMap((elementFlat: any) => ({
                  ...elementFlat,
                })),
            );
            // eslint-disable-next-line no-case-declarations
            const matrixElement = elementsFlat.find(
              (elementFlat: any) => elementFlat?.name === element?.name,
            );

            // traudzir as rows da matrix
            let strValidator = '';

            rowsTranslated = element.rows.map((row: any) => {
              translation = {};
              questionIdToSearch = '';
              alternativeId = '';
              questionIdSplitted = [];
              alternativeText = '';
              strQuyestionId = '';
              if (
                element.validators &&
                element.validators.length > 0 &&
                !element.isAllRowRequired
              ) {
                // creating the expression for validator
                strValidator += `{${element.name}.${row.value}} notempty and`;
              }

              strQuyestionId = row.questionId.toString();
              questionIdToSearch = strQuyestionId;

              questionWithTranslation = questionWithTranslations.find(
                (question: any) => question.questionId == questionIdToSearch,
              );

              // when the row is an alternative inside a matrix
              if (strQuyestionId.includes(`.`)) {
                questionIdSplitted = strQuyestionId.split('.');
                questionIdToSearch = questionIdSplitted[0];
                alternativeId = questionIdSplitted[1];

                questionWithTranslation = questionWithTranslations.find(
                  (question: any) => question.questionId == questionIdToSearch,
                );

                translation = questionWithTranslation.translations.find(
                  (questionTranslation: any) =>
                    questionTranslation.languageId ===
                    surveyTranslation.languageId,
                );

                alternativeText = translation?.alternatives?.find(
                  (alternative: any) => alternative.value == alternativeId,
                )?.text;
              }
              // end alternative

              if (questionWithTranslation?.translations) {
                questionTranslated =
                  questionWithTranslation?.translations?.find(
                    (questionTranslation: any) =>
                      questionTranslation.languageId ===
                      surveyTranslation.languageId,
                  );

                columnsTranslated = element?.columns?.map((column: any) => {
                  return (
                    questionTranslated?.options?.find(
                      (option: any) => option.value === column.value,
                    ) ?? column
                  );
                });
              }

              const rowTranslated = {
                ...row,
                text:
                  alternativeText && alternativeText != ''
                    ? alternativeText
                    : questionTranslated?.text
                    ? questionTranslated?.text
                    : questionWithTranslation.text,
              };

              return rowTranslated;
            });

            elementTranslated = {
              ...element,
              title:
                matrixElement && matrixElement.title
                  ? matrixElement.title
                  : element.title,
              rows: rowsTranslated.length > 0 ? rowsTranslated : element.rows,
              columns:
                columnsTranslated.length > 0
                  ? columnsTranslated
                  : element.columns,
            };

            if (
              element.validators &&
              element.validators.length > 0 &&
              !element.isAllRowRequired
            ) {
              // create an expression automatically to require all rows in a matrix and shows the message 'Bitte eine Antwort geben'
              const objValidator = {
                type: 'expression',
                text: requiredTextForAllRows,
                expression: strValidator.slice(0, -3),
              };

              elementTranslated.isAllRowRequired = false; // Must be false, otherwise the surveyJS shows its own message
              elementTranslated.validators = [objValidator]; // always overrides the validator
            } else {
              elementTranslated.validators = [];
            }

            break;
          case 'html':
            // flatmap em array de pages para fletar os elements
            elementsFlat = surveyTranslation.translation.pages.flatMap(
              ({ elements }: any) =>
                elements?.flatMap((elementFlat: any) => ({
                  ...elementFlat,
                })),
            );
            // eslint-disable-next-line no-case-declarations
            const htmlElement = elementsFlat.find(
              (elementFlat: any) => elementFlat?.name === element?.name,
            );

            elementTranslated = {
              ...element,
              html:
                htmlElement && htmlElement.html
                  ? htmlElement.html
                  : element.html,
            };

            /// **TODO: if dont find any translation for the current element in the loop, flag that item to be used in warnings page (elements without translationss).
            break;
          default:
            elementTranslated = element;
            break;
        }

        return elementTranslated;
      });
      pageTranslated = { ...page, elements: elementsTranslated };
      return pageTranslated;
    });

    // keep survey properties with translated pages
    const surveyTranslated = {
      ...surveyEncoded,
      title: surveyTranslation.translation.title ?? surveyEncoded.title,
      description:
        surveyTranslation.translation.description ?? surveyEncoded.description,
      pages: pagesTranslated,
    };

    // keep surveyTranslation properties and set the new translated survey
    const newSurveyTranslation = {
      ...surveyTranslation,
      translation: surveyTranslated,
    };

    /// **TODO: change the query to remove these fields  */
    delete newSurveyTranslation._id;
    delete newSurveyTranslation.__v;
    delete newSurveyTranslation.created_by;
    delete newSurveyTranslation.updated_by;
    delete newSurveyTranslation.created_at;
    delete newSurveyTranslation.updated_at;
    delete newSurveyTranslation.surveyId;
    delete newSurveyTranslation.surveyTranslationId;
    delete newSurveyTranslation.languageId;
    delete newSurveyTranslation.languageName;

    await api.put(
      `/surveyTranslations/${surveyTranslation.surveyTranslationId}`,
      newSurveyTranslation,
    );
  });
};

export default updateSurveyTranslations;
