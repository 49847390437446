import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1260px;
  margin: 35px auto;
  padding: 0 10px;
`;

export const Content = styled.div``;
