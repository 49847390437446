export const columns: any[] = [
  {
    name: 'customerId',
    title: 'ID',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'sector',
    title: 'Sector',
  },
  {
    name: 'region',
    title: 'Region',
  },
  { name: 'edit', title: '' },
  { name: 'delete', title: '' },
];
