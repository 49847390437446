import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormSurveyText from '../Form';

interface Translation {
  text: string;
  languageId: number;
  textFootnote: string;
  textLinkFootnote: string;
}

interface GDPR {
  title: string;
  text: string;
  // company: string;
  defaultLanguageId?: number;
  textFootnote: string;
  textLinkFootnote: string;

  translations: Translation[];
  translationLanguageId?: number;
  textTranslated?: string;
  textFootnoteTranslated: string;
  textLinkFootnoteTranslated: string;
}

interface ParamTypes {
  id: string;
}

const EditGDPR: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { id } = useParams<ParamTypes>();
  const [gdpr, setGDPR] = useState<GDPR>();
  const history = useHistory();

  useEffect(() => {
    api.get(`/gdpr/${id}`).then(response => {
      setGDPR(response.data);
      formRef.current?.setFieldValue('defaultLanguageId', {
        value: response.data.defaultLanguageId,
        label: response.data.defaultLanguageName,
      });
      // formRef.current?.setFieldValue('company', {
      //   value: response.data.company,
      //   label: response.data.company,
      // });
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: GDPR) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('The title is required'),
          text: Yup.string().required('The text is required'),
          // company: Yup.string().required('The company is required'),
          defaultLanguageId: Yup.number().required('The language is required'),
          textFootnote: Yup.string().required('The footenote is required'),
          textLinkFootnote: Yup.string().required(
            'The textLinkFootnote is required',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const gdprOriginal = gdpr;
        const translationArrayOriginal = gdprOriginal?.translations?.filter(
          (x: any) =>
            x.languageId !== data.translationLanguageId &&
            x.languageId !== data.defaultLanguageId,
        );

        const translationsArray = translationArrayOriginal ?? [];

        if (
          data &&
          data.defaultLanguageId &&
          data.translationLanguageId &&
          data.defaultLanguageId !== data.translationLanguageId
        ) {
          translationsArray?.push({
            text: data.textTranslated ?? '',
            languageId: data.translationLanguageId ?? 0,
            textFootnote: data.textFootnoteTranslated ?? '',
            textLinkFootnote: data.textLinkFootnoteTranslated ?? '',
          });
        }
        const gdprToEdit = {
          text: data.text,
          title: data.title,
          // company: data.company,
          defaultLanguageId: data.defaultLanguageId,
          translations: translationsArray,
          textFootnote: data.textFootnote,
          textLinkFootnote: data.textLinkFootnote,
        };

        await api.put(`/gdpr/${id}`, gdprToEdit);

        const redirectUrl = '/gdpr/';

        history.push(redirectUrl);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The text was saved successfully!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing the text, please try again.',
        });
      }
    },
    [gdpr, id, history, addToast],
  );

  return (
    <>
      <h1>Edit GDPR</h1>

      <FormSurveyText
        formRef={formRef}
        handleSubmit={handleSubmit}
        initialData={gdpr}
        buttonText="Save"
      />
    </>
  );
};

export default EditGDPR;
