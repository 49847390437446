import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import EnumQuestionType from '../../../utils/enums/EnumQuestionType';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormQuestion from '../Form';
import EnumCombinationKeys from '../../../utils/enums/EnumCombinationTypes';

interface Question {
  text: string;
  type: string;
  alternatives: Alternative[];
  combinationType: number;
  options: Option[];
  tags: Tag[];
  inputTags: string;
  defaultLanguageId?: number;
  defaultLanguageName?: string;
  translations?: Translation[];
  textTranslation?: string;
  translationLanguageId?: number;
  externalIdBefragung?: number;
  externalIdUmfrage?: number;
  externalIdTSO?: number;
}

interface Translation {
  alternatives: Alternative[];
  options: Option[];
  languageId: number;
  text: string;
}

interface Alternative {
  value: number;
  text: string;
  textTranslated?: string;
}

interface Option {
  value: number;
  text: string;
  textTranslated?: string;
  combinationValue?: string;
}

interface Combination {
  value: number;
  text: string;
  combinationValue: string;
}

interface Tag {
  value: string;
  label: string;
}

const CreateQuestion: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [tags, setTags] = useState([]);
  const [tagsSearch, setTagsSearch] = useState([]);

  useEffect(() => {
    api.post(`/questions/listTopics`).then(response => {
      setTagsSearch(response.data);
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: Question) => {
      try {
        formRef.current?.setErrors({});
        const showOptions = data.type !== EnumQuestionType.Text;
        const alternativesTranslated: Alternative[] = [];
        const alternatives: Alternative[] = [];

        data?.alternatives?.forEach((x: Alternative) => {
          alternativesTranslated.push({
            value: x.value,
            text: x.textTranslated ?? '',
          });
        });

        data?.alternatives?.forEach((x: Alternative) => {
          alternatives.push({
            value: x.value,
            text: x.text ?? '',
          });
        });

        const optionsTranslated: Option[] = [];
        const options: Option[] = [];

        let optionFieldRequired = false;
        data?.options?.forEach((x: Option) => {
          if (x.textTranslated === '') {
            optionFieldRequired = true;
          }
          if (data?.translationLanguageId && !x.textTranslated) {
            optionFieldRequired = true;
          }
          optionsTranslated.push({
            value: x.value,
            text: x.textTranslated ?? '',
          });
        });

        let typeCombination: any;
        if (data.combinationType) {
          typeCombination =
            Object.keys(EnumCombinationKeys)[
              Object.values(EnumCombinationKeys).indexOf(
                data.combinationType.toString() as EnumCombinationKeys,
              )
            ];
        } else {
          typeCombination = 'No_combinations';
        }

        data?.options?.forEach((x: Option) => {
          if (typeCombination === 'No_combinations') {
            options.push({
              value: x.value,
              text: x.text ?? '',
            });
          } else {
            options.push({
              value: x.value,
              text: x.text ?? '',
              combinationValue: x.combinationValue,
            });
          }
        });

        const translationsArray = [];

        if (
          data &&
          data.defaultLanguageId &&
          data.translationLanguageId &&
          data.defaultLanguageId !== data.translationLanguageId
        ) {
          translationsArray?.push({
            alternatives: alternativesTranslated,
            options: optionsTranslated,
            text: data.textTranslation ?? '',
            languageId: data.translationLanguageId ?? 0,
          });
        }

        const schema = Yup.object().shape({
          text: Yup.string().required('Question text is required'),
          type: Yup.string().required('Question type is required'),
          options: showOptions
            ? data.translationLanguageId && optionFieldRequired
              ? Yup.string().required('The question options are required')
              : Yup.array()
            : Yup.array(),
          defaultLanguageId: Yup.string().required(
            'Question default language is required',
          ),
          textTranslation: Yup.string(),
          externalIdBefragung: data.externalIdBefragung
            ? Yup.number().nullable().notRequired()
            : Yup.string(),
          externalIdUmfrage: data.externalIdUmfrage
            ? Yup.number().nullable().notRequired()
            : Yup.string(),
          externalIdTSO: data.externalIdTSO
            ? Yup.number().nullable().notRequired()
            : Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const questionInsert: any = {
          text: data.text,
          defaultLanguageId: data.defaultLanguageId,
          type: data.type,
          options,
          combinationType: typeCombination,
          alternatives,
          // externalId: null,
          topics: JSON.parse(data.inputTags),
          translations: translationsArray,
          externalIdBefragung: data.externalIdBefragung
            ? data.externalIdBefragung
            : null,
          externalIdUmfrage: data.externalIdUmfrage
            ? data.externalIdUmfrage
            : null,
          externalIdTSO: data.externalIdTSO ? data.externalIdTSO : null,
        };

        await api.post('/questions', questionInsert);

        history.push('/questions');

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The question was created successfully!',
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating question, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <h1>New Question</h1>

      <FormQuestion
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
        disableType={false}
        isEdit={false}
        setTags={setTags}
        tags={tags}
        tagsSearch={tagsSearch}
      ></FormQuestion>
    </>
  );
};

export default CreateQuestion;
