export const columns: any[] = [
  {
    name: 'timestamp',
    title: 'Timestamp',
  },
  {
    name: 'user',
    title: 'User',
  },
  {
    name: 'verb',
    title: 'Operation',
  },
  {
    name: 'url',
    title: 'URL',
  },
];
