export const columns: any[] = [
  {
    name: 'projectId',
    title: 'ID',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'customer',
    title: 'Customer',
  },
  {
    name: 'type',
    title: 'Type',
  },
  { name: 'edit', title: '' },
  { name: 'settings', title: '' },
  {
    name: 'delete',
    title: '',
  },
];
