import styled from 'styled-components';

export const Container = styled.div`
  . * {
    margin: 0;
    padding: 0;
  }
  html,
  body {
    height: 100%;
  }
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
  }

  .tags-input-container {
    border: 2px solid #cccccc;
    padding: 0.5em;
    border-radius: 3px;

    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  .tag-item {
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: 0.5em 0.75em;
    border-radius: 20px;
  }
  .tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    font-size: 18px;
    cursor: pointer;
  }

  .tags-input {
    flex-grow: 1;
    padding: 0.5em 0;
    border: none;
    outline: none;
  }
`;
