import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { columns } from './columns';

import {
  Container,
  Content,
  Button,
  EditIcon,
  PropertiesIcon,
  DeleteIcon,
} from './styles';
import { useToast } from '../../hooks/toast';

interface Project {
  projectId: number;
  name: string;
  type: string;
  customer: string;
}

const Projects: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'projectId', width: 100 },
    { columnName: 'edit', width: 100 },
    { columnName: 'settings', width: 100 },
    { columnName: 'delete', width: 100 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/projects/edit/${row.projectId}`);
    },
    [history],
  );

  const handleClickSettings = useCallback(
    (row: any) => {
      history.push({
        pathname: `/projects/${row.projectId}/dashboard`,
        state: { project: row },
      });
    },
    [history],
  );

  const handleClickDelete = useCallback(
    async (row: any) => {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the project ${row.projectId}?`,
        );
        if (userWantDelete) {
          const responseDelete = await api.delete(`/projects/${row.projectId}`);

          if (responseDelete.data?.deleted) {
            addToast({
              type: 'success',
              title: 'Success',
              description: `The project ${row.projectId} was deleted successfully!`,
            });
            history.push(`/customers/`);
            history.push(`/projects/`);
          } else {
            addToast({
              type: 'info',
              title: 'Project can not be deleted',
              description: responseDelete.data.message,
            });
          }
        }
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while deleting the survey, please try again.',
        });
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditIcon />,
    },
    {
      columnName: 'settings',
      label: '',
      onClick: handleClickSettings,
      icon: <PropertiesIcon />,
    },
    {
      columnName: 'delete',
      label: '',
      onClick: handleClickDelete,
      icon: <DeleteIcon />,
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api.get('projects').then(response => {
      setProjects(response.data);
    });
    setLoading(false);
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Projects</h1>

      <Link to="/projects/new">
        <Button variant="contained" type="button">
          New Project
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={projects}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'projectId'}
        ></Table>
      </Content>
    </Container>
  );
};

export default Projects;
