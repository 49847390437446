import React, { useState, useCallback, useEffect } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { RiTranslate } from 'react-icons/ri';
import Input from '../../../components/FormInput';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import Select from '../../../components/SelectForm';
import api from '../../../services/api';

import {
  Container,
  H2Relative,
  LanguageDiv,
  TranslationArrow,
  TranslationArrowDiv,
  OriginalField,
  TranslationDiv,
} from './styles';

interface Translation {
  text: string;
  languageId: number;
  textFootnote: string;
  textLinkFootnote: string;
}

interface GDPR {
  title: string;
  text: string;
  // company: string;
  defaultLanguageId?: number;
  translations: Translation[];
  textFootnote: string;
  textLinkFootnote: string;
}

interface DefaultLanguage {
  value: string;
  label: string;
}

// interface CompanyDropDown {
//   value: string;
//   label: string;
// }

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: GDPR;
  handleSubmit: SubmitHandler;
}

const FormGDPR: React.FC<Props> = props => {
  const [defaultLanguage, setDefaultLanguage] = useState({
    value: '1',
    label: 'German',
  } as DefaultLanguage);

  // const [companyDropDown, setCompanyDropDown] = useState([
  //   {
  //     value: '',
  //     label: 'Select...',
  //   },
  // ] as CompanyDropDown[]);

  // const [companyDropDownValue, setCompanyDropDownValue] = useState({
  //   value: '',
  //   label: 'Select...',
  // } as CompanyDropDown);

  const [translateSelectLanguage, setTranslateSelectLanguage] = useState({
    value: '',
    label: 'Select...',
  } as DefaultLanguage);

  const [textTranslated, setTextTranslated] = useState('');
  const [textFootnoteTranslated, setTextFootnoteTranslated] = useState('');
  const [textLinkFootnoteTranslated, setTextLinkFootnoteTranslated] =
    useState('');

  const handleLanguageDefaultChange = useCallback(language => {
    setDefaultLanguage(language);
  }, []);

  // const handleCompanyChange = useCallback(company => {
  //   setCompanyDropDownValue(company);
  // }, []);

  const [textDefaultLanguages, setTextDefaultLanguages] = useState(
    [] as DefaultLanguage[],
  );

  useEffect(() => {
    api.get('languages').then(response => {
      const languageList: DefaultLanguage[] = [];

      if (response.data) {
        response.data.forEach((language: any) => {
          languageList.push({
            value: language.languageId,
            label: language.name,
          });
        });
      }
      setTextDefaultLanguages(languageList);
    });
  }, [props?.initialData]);

  useEffect(() => {
    if (props?.initialData && props.initialData.defaultLanguageId) {
      const languageObj = textDefaultLanguages.find(
        (x: any) => x.value === props?.initialData?.defaultLanguageId,
      );
      if (languageObj) setDefaultLanguage(languageObj);
    }
  }, [props.initialData, textDefaultLanguages]);

  // useEffect(() => {
  //   const arrayCompanies: CompanyDropDown[] = [
  //     {
  //       value: 'Selbsttest TSÖ',
  //       label: 'Selbsttest TSÖ',
  //     },
  //     {
  //       value: 'Emotion Banking',
  //       label: 'Emotion Banking',
  //     },
  //     {
  //       value: 'Spirit Hoch',
  //       label: 'Spirit Hoch',
  //     },
  //   ];

  //   // setCompanyDropDown(arrayCompanies);
  // }, []);

  const handleTranslateSelectLanguageChange = useCallback(
    language => {
      setTranslateSelectLanguage(language);

      const translationAux = props.initialData?.translations?.find(
        x => x.languageId === language.value,
      );

      if (translationAux) {
        setTextTranslated(translationAux?.text ?? '');
        setTextFootnoteTranslated(translationAux?.textFootnote ?? '');
        setTextLinkFootnoteTranslated(translationAux?.textLinkFootnote ?? '');
      } else {
        setTextTranslated('');
        setTextFootnoteTranslated('');
        setTextLinkFootnoteTranslated('');
      }
    },
    [props.initialData?.translations],
  );
  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Title</h2>
        <Input type="text" name="title" placeholder="Enter the title" />

        {/* <h2>Company</h2>
        <Select
          name="company"
          value={companyDropDownValue}
          options={companyDropDown}
          onChange={e => handleCompanyChange(e)}
        /> */}

        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>Default Language</H2Relative>
            </div>
            <Select
              name="defaultLanguageId"
              value={defaultLanguage}
              options={textDefaultLanguages}
              onChange={e => handleLanguageDefaultChange(e)}
              // isDisabled={props.isEdit && !editLanguageBool}
            />
          </OriginalField>

          <TranslationArrowDiv>
            <TranslationArrow>
              <RiTranslate />
            </TranslationArrow>
          </TranslationArrowDiv>

          <TranslationDiv>
            <h2>Translation - Language</h2>
            <Select
              name="translationLanguageId"
              value={translateSelectLanguage}
              options={textDefaultLanguages.filter(
                l => l.value !== defaultLanguage.value,
              )}
              onChange={e => handleTranslateSelectLanguageChange(e)}
              // isDisabled={props.isEdit && !editLanguageBool}
            />
          </TranslationDiv>
        </LanguageDiv>

        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>Text</H2Relative>
            </div>
            <Textarea
              initialData={props.initialData ? props.initialData.text : ''}
              name="text"
              placeholder="Enter the text"
              rows={14}
            />
          </OriginalField>

          <TranslationArrowDiv>
            <TranslationArrow>
              <RiTranslate />
            </TranslationArrow>
          </TranslationArrowDiv>

          <TranslationDiv>
            <h2>Translation - Text</h2>
            <Textarea
              initialData={textTranslated}
              name="textTranslated"
              placeholder="Enter the text"
              rows={14}
            />
          </TranslationDiv>
        </LanguageDiv>

        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>GDPR text footnote </H2Relative>
              {/* <span>
                {`(Vertrauen ist wichtig, besonders wenn es um Ihre Daten geht.
                 Deshalb sehen wir es als unsere Verpflichtung, Ihre Daten mit
                 höchster Sorgfalt zu verwalten und alles zu tun, um Ihre
                 Informationen vor Missbrauch zu schützen. Die emotion banking GmbH
                 verarbeitet Ihre Daten ausschließlich auf Grundlage der
                 gesetzlichen Bestimmungen (DSGVO).)`}
              </span> */}
              {/* <br /> */}
            </div>
            <Input
              type="text"
              name="textFootnote"
              placeholder="Enter the message to be displayed above start text"
            />
          </OriginalField>

          <TranslationArrowDiv>
            <TranslationArrow>
              <RiTranslate />
            </TranslationArrow>
          </TranslationArrowDiv>

          <TranslationDiv>
            <h2>Translation - GDPR text footnote</h2>
            {/* <span>
              {`(Vertrauen ist wichtig, besonders wenn es um Ihre Daten geht.
                 Deshalb sehen wir es als unsere Verpflichtung, Ihre Daten mit
                 höchster Sorgfalt zu verwalten und alles zu tun, um Ihre
                 Informationen vor Missbrauch zu schützen. Die emotion banking GmbH
                 verarbeitet Ihre Daten ausschließlich auf Grundlage der
                 gesetzlichen Bestimmungen (DSGVO).)`}
            </span> */}
            {/* <br /> */}
            <Input
              defaultValue={textFootnoteTranslated}
              type="text"
              name="textFootnoteTranslated"
              placeholder="Enter the message to be displayed above start text"
            />
          </TranslationDiv>
        </LanguageDiv>

        <LanguageDiv>
          <OriginalField>
            <div>
              <H2Relative>GDPR text link</H2Relative>
              {/* <span>(Mehr Information)</span> <br /> */}
            </div>
            <Input
              type="text"
              name="textLinkFootnote"
              placeholder="Enter the message to be displayed in the link to see more information about GDPR"
            />
          </OriginalField>

          <TranslationArrowDiv>
            <TranslationArrow>
              <RiTranslate />
            </TranslationArrow>
          </TranslationArrowDiv>

          <TranslationDiv>
            <h2>Translation - GDPR text link</h2>
            {/* <span>(Mehr Information)</span> <br /> */}
            <Input
              defaultValue={textLinkFootnoteTranslated}
              type="text"
              name="textLinkFootnoteTranslated"
              placeholder="Enter the message to be displayed in the link to see more information about GDPR"
            />
          </TranslationDiv>
        </LanguageDiv>

        <Button type="submit" width="200px">
          {props.buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default FormGDPR;
