export const columns: any[] = [
  {
    name: 'segmentId',
    title: 'ID',
  },
  {
    name: 'name',
    title: 'Title',
  },
  {
    name: 'delete',
    title: '',
  },
];
